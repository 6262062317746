import React from "react";

import SuperSdk, {
  type AgoraInternals,
  type ConsumerInfoMap,
  ConsumerState,
} from "app-builder-super-sdk";

export default function LocalRemoteControls({
  consumerInfoMap,
  localMedia,
}: {
  consumerInfoMap: ConsumerInfoMap;
  localMedia: AgoraInternals;
}) {
  const consumersExist = (consumerInfoMap: ConsumerInfoMap) => {
    if (Object.keys(consumerInfoMap).length > 0) {
      return Object.values(consumerInfoMap).find(
        (consumerInfo: any) => consumerInfo.state === ConsumerState.Connected
      );
    } else {
      return false;
    }
  };
  if (consumersExist(consumerInfoMap))
    return (
      <div className="row --end">
        <div className="col">
          <button
            className="btn --xs"
            onClick={async () => {
              try {
                await SuperSdk.muteAllAudio(consumerInfoMap, localMedia);
              } catch (error) {
                console.error("error [muteAllAudio]: ", error);
              }
            }}
          >
            Mute all Audio
          </button>
          <button
            className="btn --xs"
            onClick={async () => {
              try {
                await SuperSdk.unmuteAllAudio(consumerInfoMap, localMedia);
              } catch (error) {
                console.error("error [unmuteAllAudio]: ", error);
              }
            }}
          >
            Unmute all Audio
          </button>
          <button
            className="btn --xs"
            onClick={async () => {
              try {
                await SuperSdk.muteAllVideo(consumerInfoMap, localMedia);
              } catch (error) {
                console.error("error [muteAllVideo]: ", error);
              }
            }}
          >
            Mute all Video
          </button>
          <button
            className="btn --xs"
            onClick={async () => {
              try {
                await SuperSdk.unmuteAllVideo(consumerInfoMap, localMedia);
              } catch (error) {
                console.error("error [unmuteAllVideo]: ", error);
              }
            }}
          >
            Unmute all Video
          </button>
        </div>
      </div>
    );
  else return <div className="row --end" style={{ height: 23 }}></div>;
}
