import React, { useEffect, useRef, useState } from "react";
import SuperSdk, { type AgoraInternals } from "app-builder-super-sdk";

export default function LocalUser({
  localUid,
  localMedia,
}: {
  localUid: string;
  localMedia: AgoraInternals;
}) {
  const [sfuStatus, registerSfuReloadListener] = useState<boolean>(false);

  const [isSFUListenerEnabled, setIsSFUListenerEnabled] =
    useState<boolean>(false);

  const localVideoTrack = useRef<MediaStreamTrack | null>(null);
  const localAudioTrack = useRef<MediaStreamTrack | null>(null);
  const localScreenVideoTrack = useRef<MediaStreamTrack | null>(null);
  const localScreenAudioTrack = useRef<MediaStreamTrack | null>(null);
  const localCanvasTrack = useRef<MediaStreamTrack | null>(null);
  const currentVideoTrackToBePlayed = useRef<MediaStreamTrack | null>(null);
  const [switchTrack, setSwitchTrack] = useState<"Canvas" | "Video" | null>(
    null
  );

  // USER VIDEO
  const startProducingUserVideo = async () => {
    if (!localVideoTrack.current) {
      const track = (
        await navigator.mediaDevices.getUserMedia({
          video: { width: 1280, height: 720 },
        })
      )
        .getTracks()
        .filter((v) => v.kind === "video");
      localVideoTrack.current = track[0];
      currentVideoTrackToBePlayed.current = track[0];
      try {
        await SuperSdk.produceStart("video", track[0], false, localMedia);
      } catch (error) {
        console.log("startProducingUserVideo error", error);
      }
    }
  };

  const endProducingUserVideo = async () => {
    try {
      if (localVideoTrack.current) {
        localVideoTrack.current?.stop();
        localVideoTrack.current = null;
        currentVideoTrackToBePlayed.current = null;
        await SuperSdk.produceEnd("video", localMedia);
      } else if (currentVideoTrackToBePlayed.current) {
        await SuperSdk.produceEnd("video", localMedia);
        currentVideoTrackToBePlayed.current = null;
      }
    } catch (error) {
      console.log("endProducingUserVideo error", error);
    }
  };

  // USER AUDIO
  const startProducingUserAudio = async () => {
    if (!localAudioTrack.current) {
      const track = (await navigator.mediaDevices.getUserMedia({ audio: true }))
        .getTracks()
        .filter((v) => v.kind === "audio");
      localAudioTrack.current = track[0];
      try {
        await SuperSdk.produceStart("audio", track[0], false, localMedia);
      } catch (error) {
        console.log("startProducingUserAudio error", error);
      }
    }
  };

  const endProducingUserAudio = async () => {
    if (localAudioTrack.current) {
      localAudioTrack.current?.stop();
      localAudioTrack.current = null;
      try {
        await SuperSdk.produceEnd("audio", localMedia);
      } catch (error) {
        console.log("endProducingUserAudio error", error);
      }
    }
  };

  // SCREEN VIDEO
  const startProducingScreenshare = async () => {
    if (!localScreenVideoTrack.current) {
      let mediaStream = null;
      /**
       * We are setting audio: false here because we don't want desktop audio,
       * we want audio from user's microphone
       * */
      try {
        mediaStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true,
        });
        const screenTracks = [
          ...mediaStream.getVideoTracks(),
          ...mediaStream.getAudioTracks(),
        ];
        screenTracks[0].addEventListener(
          "ended",
          function () {
            console.log("local screen stream track-ended");
            localScreenVideoTrack.current?.stop();
            localScreenVideoTrack.current = null;
            if (localScreenAudioTrack.current) {
              SuperSdk.produceEnd("sound", localMedia);
              localScreenAudioTrack.current?.stop();
              localScreenAudioTrack.current = null;
            }
          },
          false
        );
        console.log("screen tracks", screenTracks);
        localScreenVideoTrack.current = screenTracks[0];
        if (screenTracks.length > 0) {
          localScreenAudioTrack.current = screenTracks[1];
        }
        try {
          if (screenTracks.length > 0) {
            SuperSdk.produceStart("screen", screenTracks[0], false, localMedia);
            screenTracks[1] &&
              SuperSdk.produceStart(
                "sound",
                screenTracks[1],
                false,
                localMedia
              );
          }
        } catch (error) {
          console.log("startProducingScreenShare error", error);
        }
      } catch (ex) {
        console.log("Error occurred", ex);
      }
    }
  };

  const endProducingScreenshare = async () => {
    if (localScreenVideoTrack.current) {
      try {
        SuperSdk.produceEnd("screen", localMedia);
        localScreenVideoTrack.current?.stop();
        localScreenVideoTrack.current = null;
        if (localScreenAudioTrack.current) {
          SuperSdk.produceEnd("sound", localMedia);
          localScreenAudioTrack.current?.stop();
          localScreenAudioTrack.current = null;
        }
      } catch (error) {
        console.log("endProducingScreenVideo error", error);
      }
    }
  };

  // CANVAS TRACK TOGGLE
  const replaceTrack = () => {
    if (switchTrack === "Canvas") {
      setSwitchTrack("Video");
    } else if (switchTrack === "Video") {
      setSwitchTrack("Canvas");
    } else {
      setSwitchTrack("Canvas");
    }
  };

  const replaceVideoTrackWithCanvasTrack = async () => {
    console.log("Producing canvas track");
    if (!localCanvasTrack.current) {
      const leftVideo: any = document.getElementById(
        `local-stream-player-${localUid}`
      );
      let stream;
      if (leftVideo.captureStream) {
        stream = leftVideo.captureStream();
        const videoTracks = stream.getVideoTracks();
        if (videoTracks.length > 0) {
          currentVideoTrackToBePlayed.current = videoTracks[0];
          try {
            SuperSdk.produceStart("video", videoTracks[0], false, localMedia);
          } catch (error) {
            console.log("error [replaceTrack]: ", error);
          }
        }
      } else {
        console.error("Stream capture is not supported");
        stream = null;
      }
    }
  };

  const replaceCanvasTrackWithVideoTrack = async () => {
    console.log("Producing video track");
    const track = (await navigator.mediaDevices.getUserMedia({ video: true }))
      .getTracks()
      .filter((v) => v.kind === "video");
    currentVideoTrackToBePlayed.current = track[0];
    try {
      SuperSdk.produceStart("video", track[0], false, localMedia);
    } catch (error) {
      console.log("error [replaceTrack]: ", error);
    }
  };

  useEffect(() => {
    const produceSwitchedTrack = async (newTrack: "Canvas" | "Video") => {
      if (newTrack === "Canvas") {
        replaceVideoTrackWithCanvasTrack();
      } else if (newTrack === "Video") {
        replaceCanvasTrackWithVideoTrack();
      }
    };

    if (!switchTrack) return;
    produceSwitchedTrack(switchTrack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchTrack]);

  // Others
  useEffect(() => {
    if (!localMedia.streams?.video && localVideoTrack?.current) {
      localVideoTrack.current?.stop();
      localVideoTrack.current = null;
    }
    if (!localMedia.streams?.audio && localAudioTrack?.current) {
      localAudioTrack.current?.stop();
      localAudioTrack.current = null;
    }
    if (!localMedia.streams?.screen && localScreenVideoTrack?.current) {
      localScreenVideoTrack.current?.stop();
      localScreenVideoTrack.current = null;
      localScreenAudioTrack.current?.stop();
      localScreenAudioTrack.current = null;
    }
  }, [localMedia]);

  useEffect(() => {
    return () => {
      localScreenAudioTrack.current?.stop();
      localScreenVideoTrack.current?.stop();
      localAudioTrack.current?.stop();
      localVideoTrack.current?.stop();
    };
  }, []);

  return (
    <>
      <div className="row">
        <div className="col status-col">
          <div className="status-badge">
            <div>Local user </div>
          </div>
        </div>
        <div className="col uid-col">
          <b>
            <small>Ntwk.Status: &nbsp; </small>
          </b>
          {isSFUListenerEnabled ? (sfuStatus ? "Reloading..." : "ᯤ") : "NA"}
        </div>
        <div className="col uid-col">
          <b>
            <small>uid: &nbsp; </small>
          </b>
          {localUid}
        </div>
      </div>
      <div className="row --end">
        {localMedia?.initialized && (
          <>
            <div className="col">
              <div className="btn-group-pill">
                <div className="btn-group">
                  <button
                    className="btn --xs"
                    onClick={async () => {
                      console.log(`Disconnec ${localMedia}`);

                      if (localMedia.clients) {
                        console.log(
                          `Awaiting local client leave ${localMedia}`
                        );

                        await localMedia?.clients?.user?.leave();
                        console.log(
                          `local client leave complete ${localMedia}`
                        );
                      } else {
                        console.log(
                          "Disconnect(leave): localMedia not available",
                          {
                            localMedia,
                          }
                        );
                      }
                    }}
                  >
                    Disconnect(channel leave)
                  </button>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="btn-group-pill">
                <div className="btn-group">
                  <button
                    className="btn --xs"
                    onClick={async () => {
                      console.log(`Disconnect and token expire ${localMedia}`);
                      if (localMedia.clients) {
                        localMedia.channelInfo.expiresAt = Date.now();
                        console.log(
                          `Awaiting local client leave ${localMedia.channelInfo}`
                        );

                        await localMedia?.clients?.user?.leave();
                        console.log(
                          `local client leave completed ${localMedia}`
                        );
                      } else {
                        console.log(
                          "Disconnect(expire): localMedia not available",
                          {
                            localMedia,
                          }
                        );
                      }
                    }}
                  >
                    Disconnect and token expire()
                  </button>
                </div>
              </div>
            </div>

            {/* USER VIDEO  */}
            <div className="col">
              <div className="btn-group-pill">
                <div className="btn-group-heading">User Video</div>
                <div className="btn-group">
                  <button
                    className="btn --xs"
                    onClick={() => {
                      if (localMedia?.streams?.video) {
                        endProducingUserVideo();
                      } else {
                        startProducingUserVideo();
                      }
                    }}
                  >
                    {localMedia?.streams?.video ? "End" : "Produce"}
                  </button>
                  <button
                    className="btn --xs"
                    disabled={!localMedia?.streams?.video?.enabled}
                    onClick={async () => {
                      if (localMedia?.streams?.video?.muted) {
                        try {
                          await SuperSdk.produceResume("video", localMedia);
                        } catch (error) {
                          console.log("produceResume video error", error);
                        }
                      } else {
                        try {
                          await SuperSdk.producePause("video", localMedia);
                        } catch (error) {
                          console.log("producePause video  error", error);
                        }
                      }
                    }}
                  >
                    {localMedia?.streams?.video?.muted ? "Resume" : "Pause"}{" "}
                  </button>
                </div>
              </div>
            </div>
            {/* USER AUDIO */}
            <div className="col">
              <div className="btn-group-pill">
                <div className="btn-group-heading">User Audio</div>
                <div className="btn-group">
                  <button
                    className="btn --xs"
                    onClick={() => {
                      if (localMedia?.streams["audio"]) {
                        endProducingUserAudio();
                      } else {
                        startProducingUserAudio();
                      }
                    }}
                  >
                    {localMedia?.streams?.audio ? "End" : "Produce"}
                  </button>
                  {/* Audio pause resume */}
                  <button
                    className="btn --xs"
                    disabled={!localMedia?.streams?.audio?.enabled}
                    onClick={async () => {
                      if (localMedia?.streams?.audio?.muted) {
                        try {
                          await SuperSdk.produceResume("audio", localMedia);
                        } catch (error) {
                          console.log("producePause audio  error", error);
                        }
                      } else {
                        try {
                          await SuperSdk.producePause("audio", localMedia);
                        } catch (error) {
                          console.log("produceResume audio error", error);
                        }
                      }
                    }}
                  >
                    {localMedia?.streams?.audio?.muted ? "Resume" : "Pause"}
                  </button>
                </div>
              </div>
            </div>
            {/* SCREEN VIDEO */}
            <div className="col">
              <div className="btn-group-pill">
                <div className="btn-group-heading">Start screenshare</div>
                <div className="btn-group">
                  <button
                    className="btn --xs"
                    onClick={() => {
                      if (localMedia?.streams["screen"]) {
                        endProducingScreenshare();
                      } else {
                        startProducingScreenshare();
                      }
                    }}
                  >
                    {localMedia?.streams?.screen ? "End" : "Produce"}
                  </button>
                  {/* SCREEN VIDEO pause resume */}
                  <button
                    className="btn --xs"
                    disabled={!localMedia?.streams?.screen?.enabled}
                    onClick={async () => {
                      if (localMedia?.streams["screen"]?.muted) {
                        try {
                          await SuperSdk.produceResume("screen", localMedia);
                          await SuperSdk.produceResume("sound", localMedia);
                        } catch (error) {
                          console.log("produceResume screen error", error);
                        }
                      } else {
                        try {
                          await SuperSdk.producePause("screen", localMedia);
                          await SuperSdk.producePause("sound", localMedia);
                        } catch (error) {
                          console.log("producePause screen error", error);
                        }
                      }
                    }}
                  >
                    {localMedia?.streams?.screen?.muted ? "Resume" : "Pause"}
                  </button>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="btn-group-pill">
                <button
                  className="btn --xs"
                  disabled={!currentVideoTrackToBePlayed?.current}
                  onClick={() => {
                    replaceTrack();
                  }}
                >
                  Replace Track
                </button>
              </div>
            </div>
            <div className="col">
              <div className="btn-group-pill">
                <button
                  style={{ minWidth: 130 }}
                  className="btn --xs"
                  onClick={() => {
                    if (isSFUListenerEnabled) {
                      try {
                        SuperSdk.clearSFUReloadListener(
                          registerSfuReloadListener,
                          localMedia
                        );
                        setIsSFUListenerEnabled(false);
                      } catch (error) {
                        console.log("clearSFUReloadListener error", error);
                      }
                    } else {
                      try {
                        SuperSdk.addSFUReloadListener(
                          registerSfuReloadListener,
                          localMedia
                        );
                        setIsSFUListenerEnabled(true);
                      } catch (error) {
                        console.log("addSFUReloadListener error", error);
                      }
                    }
                  }}
                >
                  {isSFUListenerEnabled ? "Clear" : "  Add"} SFU Listener
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
