import React, { useRef, useEffect } from "react";
import SuperSdk from "app-builder-super-sdk";
import {
  type AgoraInternals,
  type ConsumerInfoMap,
  type ConsumerInfo,
} from "app-builder-super-sdk";

export default function ConsumerMediaCard({
  uid,
  consumerInfo,
  consumerInfoMap,
  localMedia,
}: {
  uid: string;
  consumerInfo: ConsumerInfo;
  consumerInfoMap: ConsumerInfoMap;
  localMedia: AgoraInternals;
}) {
  const remoteVideoPlayerRef = useRef<HTMLInputElement>(null);
  const remoteScreenPlayerRef = useRef<HTMLInputElement>(null);

  let isVideoPlayerRefInitialized = useRef(false);
  let isScreenPlayerRefInitialized = useRef(false);

  useEffect(() => {
    if (remoteVideoPlayerRef.current) {
      isVideoPlayerRefInitialized.current = true;
    }
  }, [remoteVideoPlayerRef]);

  useEffect(() => {
    if (remoteScreenPlayerRef.current) {
      isScreenPlayerRefInitialized.current = true;
    }
  }, [remoteScreenPlayerRef]);

  if (isVideoPlayerRefInitialized.current) {
    if (consumerInfo && consumerInfo.enabled?.video) {
      const list = remoteVideoPlayerRef.current! as HTMLDivElement;
      if (list?.hasChildNodes()) {
        list.removeChild(list.children[0]);
      }
      if (remoteVideoPlayerRef.current) {
        const track = consumerInfo._video?.streams["video"]?.play(
          remoteVideoPlayerRef?.current
        );
        console.log("[DEMO_APP]", "remote track video enabled", track);
      }
    }
    if (consumerInfo && consumerInfo.enabled?.audio) {
      const track = consumerInfo._video?.streams["audio"]?.play();
      console.log("[DEMO_APP]", "remote track audio enabled", track);
    }
  }
  if (isScreenPlayerRefInitialized.current) {
    if (consumerInfo && consumerInfo.enabled?.sound) {
      const track = consumerInfo._screenVideo?.streams["sound"]?.play();
      console.log("[DEMO_APP]", "remote screen track audio enabled", track);
    }
    if (consumerInfo && consumerInfo.enabled?.screen) {
      const list = remoteScreenPlayerRef.current! as HTMLDivElement;
      if (list?.hasChildNodes()) {
        list.removeChild(list.children[0]);
      }
      if (remoteScreenPlayerRef.current) {
        const track = consumerInfo._screenVideo?.streams["screen"]?.play(
          remoteScreenPlayerRef?.current
        );
        console.log("[DEMO_APP]", "remote screen track video enabled", track);
      }
    }
  }
  return (
    <div className="media-body" key={uid}>
      <div className="--video">
        {/* user video */}
        <div className="remote-video-container">
          <div
            style={{ height: "100%", width: "100%" }}
            ref={remoteVideoPlayerRef}
            id={`remote-video-player-${uid}`}
          />
        </div>
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>{uid}</div>

      <div className="--actions">
        <div>
          <div className="slidecontainer">
            <input
              disabled={
                !(
                  consumerInfo?.enabled["audio"] &&
                  !consumerInfo?.muted["audio"]
                )
              }
              type="range"
              min="0"
              max="1"
              step="0.1"
              onChange={(e) => {
                const vol = parseFloat(e.target.value);
                try {
                  SuperSdk.setVolume(uid, vol, consumerInfoMap, localMedia);
                } catch (error) {
                  console.error("error [setVolume] user audio ", error);
                }
              }}
              value={consumerInfo?.volume}
              id="myRange-user-sound"
              className="--slider"
            />
          </div>
        </div>
        <button
          style={!consumerInfo?.enabled?.video ? { color: "red" } : {}}
          className="btn  --xs"
          // sample app button to not be disabled
          //
          onClick={async () => {
            console.log("Attempting to mute user video ");
            try {
              await SuperSdk.setMute(
                uid,
                "video",
                consumerInfo.muted["video"] ? false : true,
                consumerInfoMap,
                localMedia
              );
            } catch (error) {
              console.error("error [setMute] video: ", error);
            }
          }}
        >
          {!consumerInfo?.muted["video"] ? "Mute " : "Unmute "} Video
        </button>
        <button
          style={!consumerInfo?.enabled?.audio ? { color: "red" } : {}}
          className="btn  --xs"
          onClick={async () => {
            console.log("Attempting to mute user audio ");
            try {
              await SuperSdk.setMute(
                uid,
                "audio",
                consumerInfo.muted["audio"] ? false : true,
                consumerInfoMap,
                localMedia
              );
            } catch (error) {
              console.error("error [setMute] audio : ", error);
            }
          }}
        >
          {!consumerInfo?.muted["audio"] ? "Mute " : "Unmute "} Audio
        </button>
      </div>

      {/* screen video */}
      <div className="--video">
        <div className="remote-video-container">
          <div
            style={{ height: "100%", width: "100%" }}
            ref={remoteScreenPlayerRef}
            id={`remote-screenvideo-player-${uid}`}
          />
        </div>
      </div>

      <div className="--actions">
        <div className="slidecontainer">
          <input
            // disabled={
            //   !(consumerInfo?.enabled["sound"] && !consumerInfo?.muted["sound"])
            // }
            type="range"
            min="0"
            max="1"
            step="0.1"
            onChange={(e) => {
              const vol = parseFloat(e.target.value);
              try {
                SuperSdk.setScreenVolume(uid, vol, consumerInfoMap, localMedia);
              } catch (error) {
                console.error("error [setScreenVolume] screen audio ", error);
              }
            }}
            value={consumerInfo?.screenVolume}
            id="myRange-screen-sound"
            className="--slider"
          />
        </div>
        <button
          style={!consumerInfo?.enabled?.screen ? { color: "red" } : {}}
          className="btn --xs"
          onClick={async () => {
            console.log("Attempting to mute screenVideo");
            try {
              await SuperSdk.setMute(
                uid,
                "screen",
                consumerInfo.muted["screen"] ? false : true,
                consumerInfoMap,
                localMedia
              );
            } catch (error) {
              console.error("error [setMute] screen: ", error);
            }
          }}
        >
          {!consumerInfo?.muted["screen"] ? "Mute " : "Unmute "} Video
        </button>
        <button
          style={!consumerInfo?.enabled?.sound ? { color: "red" } : {}}
          className="btn --xs"
          disabled={!consumerInfo?.enabled["sound"]}
          onClick={async () => {
            console.log("Attempting to mute screen sound ");
            try {
              await SuperSdk.setMute(
                uid,
                "sound",
                consumerInfo.muted["sound"] ? false : true,
                consumerInfoMap,
                localMedia
              );
            } catch (error) {
              console.error("error [setMute] sound (screen sound): ", error);
            }
          }}
        >
          {!consumerInfo?.muted["sound"] ? "Mute " : "Unmute "} Audio
        </button>
      </div>
    </div>
  );
}
