import React from "react";
import SuperSdk, {
  type AgoraInternals,
  type ConsumerInfoMap,
  type ConsumerInfo,
  ConsumerState,
} from "app-builder-super-sdk";

export default function RemoteUser({
  el,
  consumerInfoMap,
  localMedia,
}: {
  el: string;
  consumerInfoMap: ConsumerInfoMap;
  localMedia: AgoraInternals;
}) {
  const computeSubscribedState = (infoMap: ConsumerInfo) => {
    if (infoMap) {
      const state: ConsumerState = infoMap.state;
      switch (state) {
        case ConsumerState.WaitingForSFUAddress:
          return [ConsumerState.WaitingForSFUAddress, 1];
        case ConsumerState.ConnectingToSFU:
          return [ConsumerState.ConnectingToSFU, 2];
        case ConsumerState.Connected:
          return [ConsumerState.Connected, 3];
        case ConsumerState.Consuming:
          return [ConsumerState.Consuming, 4];
        case ConsumerState.NotAllowed:
          return [ConsumerState.NotAllowed, 5];
        case ConsumerState.ProducerDisconnected:
          return [ConsumerState.ProducerDisconnected, 6];
        default:
          return ["Available", 0];
      }
    } else {
      return ["Available", 0];
    }
  };

  return (
    <div style={{ paddingTop: 10 }}>
      <div className="row">
        <div className="col status-col">
          <div
            className="status-badge"
            style={{
              backgroundColor: [
                "blue",
                "#e1ad01",
                "orange",
                "green",
                "darkgreen",
                "red",
                "black",
              ][
                // @ts-ignore
                computeSubscribedState(consumerInfoMap[el])[1]
              ],
            }}
          >
            <div className="overflow-text">
              {computeSubscribedState(consumerInfoMap[el])[0]}
            </div>
          </div>
        </div>
        <div className="col uid-col">
          <small>
            <b>uid: &nbsp;</b>
          </small>
          {el}
        </div>
      </div>
      <div className="row --end">
        <div className="col">
          <div className="btn-group-pill">
            <div className="btn-group-heading">Screen Volume</div>
            <div className="btn-group-heading">
              &#x1F3A7; {consumerInfoMap[el]?.screenVolume || 0}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="btn-group-pill">
            <div className="btn-group-heading">User Volume</div>
            <div className="btn-group-heading">
              &#x1F3A7;
              {consumerInfoMap[el]?.volume || 0}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="btn-group-pill">
            <div className="btn-group-heading">Subscribe User </div>
            <div className="btn-group">
              <button
                className="btn --xs"
                onClick={async () => {
                  try {
                    await SuperSdk.subscribe(
                      el,
                      ["video"],
                      consumerInfoMap,
                      localMedia
                    );
                  } catch (error) {
                    console.log("subscribe [video]", error);
                  }
                }}
              >
                Video
              </button>
              <button
                className="btn --xs"
                onClick={async () => {
                  try {
                    await SuperSdk.subscribe(
                      el,
                      ["audio"],
                      consumerInfoMap,
                      localMedia
                    );
                  } catch (error) {
                    console.log("subscribe [audio]", error);
                  }
                }}
              >
                Audio
              </button>
              <button
                className="btn --xs"
                onClick={async () => {
                  try {
                    await SuperSdk.subscribe(
                      el,
                      ["audio", "video"],
                      consumerInfoMap,
                      localMedia
                    );
                  } catch (error) {
                    console.log("subscribe [audio, video]", error);
                  }
                }}
              >
                Both
              </button>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="btn-group-pill">
            <div className="btn-group-heading">Subscribe Screen </div>
            <div className="btn-group">
              <button
                className="btn --xs"
                onClick={async () => {
                  try {
                    await SuperSdk.subscribe(
                      el,
                      ["screen"],
                      consumerInfoMap,
                      localMedia
                    );
                  } catch (error) {
                    console.log("subscribe [screen]", error);
                  }
                }}
              >
                Video
              </button>
              <button
                className="btn --xs"
                onClick={async () => {
                  try {
                    await SuperSdk.subscribe(
                      el,
                      ["sound"],
                      consumerInfoMap,
                      localMedia
                    );
                  } catch (error) {
                    console.log("subscribe [sound]", error);
                  }
                }}
              >
                Audio
              </button>
              <button
                className="btn --xs"
                onClick={async () => {
                  try {
                    await SuperSdk.subscribe(
                      el,
                      ["screen", "sound"],
                      consumerInfoMap,
                      localMedia
                    );
                  } catch (error) {
                    console.log("subscribe [screen, sound]", error);
                  }
                }}
              >
                Both
              </button>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="btn-group-pill">
            <div className="btn-group-heading">Subscribe</div>
            <div className="btn-group">
              <button
                className="btn --xs"
                onClick={async () => {
                  try {
                    await SuperSdk.subscribe(
                      el,
                      ["audio", "video", "screen", "sound"],
                      consumerInfoMap,
                      localMedia
                    );
                  } catch (error) {
                    console.log("subscribe [screen, sound]", error);
                  }
                }}
              >
                All
              </button>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="btn-group-pill">
            <div className="btn-group">
              <button
                className="btn --xs"
                onClick={async () => {
                  try {
                    await SuperSdk.unsubscribe(el, consumerInfoMap, localMedia);
                  } catch (error) {
                    console.log("error [unsubscribe] ", error);
                  }
                }}
              >
                Unsubscribe
              </button>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="btn-group-pill">
            <div className="btn-group">
              <button
                className="btn --xs"
                onClick={() => {
                  try {
                    SuperSdk.allow(el, localMedia, consumerInfoMap);
                  } catch (error) {
                    console.log("subscribe [allow]", error);
                  }
                }}
              >
                Allow
              </button>
              <button
                className="btn --xs"
                onClick={() => {
                  try {
                    for (let i = 0; i < 110; i++) {
                      SuperSdk.allow(el, localMedia, consumerInfoMap);
                    }
                  } catch (error) {
                    console.log("subscribe [allow]", error);
                  }
                }}
              >
                Allow 110x
              </button>
              <button
                className="btn --xs"
                onClick={() => {
                  try {
                    SuperSdk.disallow(el, localMedia, consumerInfoMap);
                  } catch (error) {
                    console.log("subscribe [disallow]", error);
                  }
                }}
              >
                Disallow
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
