import React from "react";
import "./App.css";
import "./styles/ui.css";
import "./styles/Home.css";
import AppSpawn from "./components/spawn/AppSpawn";
import AppSpawnControls from "./components/spawn/AppSpawnControls";

function App() {
  const [spawnList, setSpawnCount] = React.useState<number[]>([1]);
  return (
    <div className="spawn-container">
      {spawnList.map((el) => (
        <div className="spawn-child" key={el.toString()}>
          <AppSpawnControls
            spawnName={`space ${el} `}
            setSpawnCount={setSpawnCount}
          />
          <AppSpawn key={el.toString()} />
        </div>
      ))}
    </div>
  );
}

export default App;
