import React, { useState } from "react";
import { customAlphabet } from "nanoid";
const nanoid = customAlphabet("1234567890");

export default function JoinForm({
  handleJoin,
  handleLeave,
  isLoading,
  isSdkInitialized,
}: {
  handleJoin: (spaceId: string, userId: string) => void;
  handleLeave: () => void;
  isLoading: boolean;
  isSdkInitialized: boolean;
}) {
  const [spaceId, setSpaceId] = useState<string>("333");
  const defaultid = nanoid();
  const [userId, setUserId] = useState<string>(defaultid);

  return (
    <div className="inputContainer">
      <span className="inputTitle">SpaceId:</span>
      <input
        className="input"
        id="space-id-input-field"
        placeholder="space-id"
        value={spaceId}
        disabled={isSdkInitialized || isLoading}
        onChange={(event) => setSpaceId(event.target.value)}
      ></input>
      <span className="inputTitle">UserId:</span>
      <input
        className="input"
        id="user-id-input-field"
        placeholder="user-id"
        value={userId}
        disabled={isSdkInitialized || isLoading}
        onChange={(event) => setUserId(event.target.value)}
      ></input>
      <button
        className="btn"
        disabled={isSdkInitialized || isLoading}
        style={{
          height: "2rem",
          justifySelf: "center",
          alignSelf: "center",
          minWidth: 100,
        }}
        onClick={() => {
          handleJoin(spaceId, userId);
        }}
      >
        Join
      </button>
      <button
        // disabled={!sdkInitialized || isLoading || isSdkInititalizing}
        disabled={!isSdkInitialized || isLoading}
        className="btn"
        style={{
          height: "2rem",
          justifySelf: "center",
          alignSelf: "center",
          minWidth: 100,
        }}
        onClick={() => {
          handleLeave();
        }}
      >
        Leave
      </button>
    </div>
  );
}
