import React from "react";
import {
  type AgoraInternals,
  type ConsumerInfoMap,
} from "app-builder-super-sdk";
import LocalUser from "./local/LocalUser";
import RemoteUser from "./remote/RemoteUser";
import LocalRemoteControls from "./local/LocalRemoteControls";

export default function UsersContainer({
  userList,
  localMedia,
  localUid,
  consumerInfoMap,
}: {
  userList: string[];
  localUid: string;
  localMedia: AgoraInternals;
  consumerInfoMap: ConsumerInfoMap;
}) {
  return (
    <>
      {localMedia?.initialized && (
        <>
          <LocalUser
            key={`local-user-key-${localUid}`}
            localMedia={localMedia}
            localUid={localUid}
          />
          <LocalRemoteControls
            consumerInfoMap={consumerInfoMap}
            localMedia={localMedia}
          />
        </>
      )}
      {userList.map((el) => (
        <RemoteUser
          key={`remote-user-key-${el}`}
          el={el}
          localMedia={localMedia}
          consumerInfoMap={consumerInfoMap}
        />
      ))}
    </>
  );
}
