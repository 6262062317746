import React, { useEffect, useState } from "react";
import Home from "../Home";

export default function AppSpawn() {
  // @ts-ignore
  const [consumerState] = useState({});
  const [agoraInfoState] = useState({});

  useEffect(() => {
    console.log("App mounted");
  }, []);

  return (
    // @ts-ignore
    <Home consumerInfoMap={consumerState} agoraInfo={agoraInfoState}>
      AppSpawn
    </Home>
  );
}
