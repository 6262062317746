import React, { useEffect, useState } from "react";
import JoinForm from "./Join";
import SuperSdk, {
  type AgoraInternals,
  type ConsumerInfoMap,
} from "app-builder-super-sdk";

export default function Header({
  setLocalUserId,
  agoraInfo,
  consumerInfoMap,
  presenceListener,
  reset,
}: {
  setLocalUserId: React.Dispatch<React.SetStateAction<string>>;
  agoraInfo: AgoraInternals;
  consumerInfoMap: ConsumerInfoMap;
  presenceListener: (userId: string, present: boolean) => void;
  reset: () => void;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSdkInitialized, setSdkInitialized] = useState<boolean>(false);
  const startSdk = async (userId: string, token: string) => {
    try {
      setLocalUserId(userId);
      await SuperSdk.start(token, consumerInfoMap, agoraInfo, presenceListener);
    } catch (error) {
      throw error;
    }
  };

  const getToken = async (userId: string, spaceId: string) => {
    console.log("process env", process.env);
    return await fetch(
      `${process.env["REACT_APP_BACKEND_URL"]}/space/token/generate`,
      {
        method: "POST",
        headers: new Headers({
          "X-API-KEY": process.env["REACT_APP_X_API_KEY"]!,
          "X-Project-ID": process.env["REACT_APP_X_Project_ID"]!,
        }),
        body: JSON.stringify({
          user_id: userId,
          space_id: spaceId,
        }),
      }
    )
      .then((res) => res.json())
      .catch((err) => err);
  };
  const handleJoin = async (spaceId: string, userId: string) => {
    console.log("spaceId: ", spaceId);
    console.log("userId: ", userId);
    if (isSdkInitialized) {
      console.log("already initialized");
      return;
    }
    setIsLoading(true);
    try {
      const { token } = await getToken(userId, spaceId);
      console.log("token response: ", token);
      await startSdk(userId, token);
      setSdkInitialized(true);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLeave = () => {
    setSdkInitialized(false);
    reset();
  };

  useEffect(() => {
    // Clean up
    return () => {
      console.log("unmount happenned");
      reset();
      setSdkInitialized(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="heading">
        <h2>Super SDK demo</h2>
      </div>
      <JoinForm
        handleJoin={handleJoin}
        handleLeave={handleLeave}
        isLoading={isLoading}
        isSdkInitialized={isSdkInitialized}
      ></JoinForm>
    </div>
  );
}
