import { useState, useCallback } from "react";
import Collapse from "./ui/Collapse";
import Header from "./Header";
import UsersContainer from "./UsersContainer";
import UsersMediaContainer from "./UsersMediaContainer";
import SuperSdk, {
  AgoraInternals,
  ConsumerInfoMap,
} from "app-builder-super-sdk";

export default function Home({
  consumerInfoMap,
  agoraInfo,
}: {
  consumerInfoMap: ConsumerInfoMap;
  agoraInfo: AgoraInternals;
}) {
  const [, updateState] = useState();
  // @ts-ignore
  const forceUpdate = useCallback(() => updateState({}), []);

  const [userList, setUserList] = useState<string[]>([]);
  const [localUserId, setLocalUserId] = useState<string>("");
  // @ts-ignore
  consumerInfoMap.listener = () => {
    forceUpdate();
  };
  // @ts-ignore
  agoraInfo.listener = () => {
    forceUpdate();
  };
  Object.defineProperty(consumerInfoMap, "listener", {
    enumerable: false,
  });
  const _presence = async (userId: string, present: boolean) => {
    if (present) {
      setUserList((prevState: string[]) =>
        prevState.find((el) => el === userId)
          ? [...prevState]
          : [...prevState, userId]
      );
      console.log("!!!!Added memberinfo", userId);
    } else {
      setUserList((prevState: string[]) =>
        prevState.filter((user) => user !== userId)
      );
      console.log("!!!!Remove memberinfo", userId);
    }
  };

  const reset = async () => {
    try {
      await SuperSdk.destroy(consumerInfoMap, agoraInfo);
      setUserList([]);
      setLocalUserId("");
    } catch (error) {
      console.log("error [resetApp] : ", error);
    }
  };

  return (
    <div className="App">
      <Header
        setLocalUserId={setLocalUserId}
        agoraInfo={agoraInfo}
        consumerInfoMap={consumerInfoMap}
        presenceListener={_presence}
        reset={reset}
      />
      <section className="user-list">
        <div className="user-list-table">
          <Collapse open={true} header={"Users in the space"}>
            {agoraInfo && agoraInfo?.initialized && (
              <UsersContainer
                localUid={localUserId}
                userList={userList}
                localMedia={agoraInfo}
                consumerInfoMap={consumerInfoMap}
              />
            )}
          </Collapse>
        </div>
      </section>
      <section className="user-media">
        <div className="user-media-table">
          <Collapse open={true} header={"User's media in the space"}>
            {agoraInfo && agoraInfo?.initialized && (
              <UsersMediaContainer
                localUid={localUserId}
                localMedia={agoraInfo}
                userList={userList}
                consumerInfoMap={consumerInfoMap}
              />
            )}
            <div className="row --divider" />
          </Collapse>
        </div>
      </section>
    </div>
  );
}
