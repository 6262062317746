import React from "react";

export default function AppSpawnControls({
  spawnName,
  setSpawnCount,
}: {
  spawnName: string;
  setSpawnCount: React.Dispatch<React.SetStateAction<number[]>>;
}) {
  return (
    <div className="spawn-control">
      <div className="spawn-actions">
        <div className="spawn-name">{spawnName} &nbsp;</div>
        <div
          className="spawn-add-btn"
          onClick={() =>
            setSpawnCount((prevState) => [...prevState, prevState.length + 1])
          }
        >
          ➕
        </div>
      </div>
    </div>
  );
}
