import React, { useState, useEffect } from "react";
import { type ConsumerInfo } from "app-builder-super-sdk";

export default function ConsumerQualityStats({
  consumerInfo,
}: {
  consumerInfo: ConsumerInfo;
}) {
  const [qualityStats, setQualityStats] = useState<{
    receiveResolutionHeight?: number;
    receiveResolutionWidth?: number;
    renderFrameRate?: number;
    receiveBitrate?: number;
  }>();

  useEffect(() => {
    const interval = setInterval(() => {
      const clientConstraint =
        consumerInfo._video.client?.getRemoteVideoStats();
      const remoteUserId = consumerInfo?._video?.agUser?.uid;

      if (remoteUserId && clientConstraint && clientConstraint[remoteUserId]) {
        setQualityStats(clientConstraint[remoteUserId]);
      }
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="--scores-child --qs">
      <table className="user-score-table">
        <tbody>
          <tr>
            <td>height</td>
            <td>{qualityStats?.receiveResolutionHeight || 0}</td>
            <td className="cell-right">width</td>
            <td className="cell-right">
              {qualityStats?.receiveResolutionWidth || 0}
            </td>
          </tr>
          <tr>
            <td>frameRate</td>
            <td>{qualityStats?.renderFrameRate || 0}</td>
            <td className="cell-right">bitrateRec</td>
            <td className="cell-right">{qualityStats?.receiveBitrate || 0}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
