import React from "react";
import { type ConsumerInfo } from "app-builder-super-sdk";

export default function ConsumerNetworkScores({
  consumerInfo,
}: {
  consumerInfo: ConsumerInfo;
}) {
  return (
    <>
      <div className="--scores-child --ps">
        <span>Producer score &nbsp;</span>
        <div>UA {consumerInfo?.producerScores["audio"]}</div>
        <div>UV {consumerInfo?.producerScores["video"]}</div>
        <div>SA {consumerInfo?.producerScores["sound"]} </div>
        <div>SV {consumerInfo?.producerScores["screen"]}</div>
      </div>
      <div className="--scores-child --cs">
        <span>Consumer score </span>
        <div>UA{consumerInfo?.consumerScores["audio"]}</div>
        <div>UV {consumerInfo?.consumerScores["video"]}</div>
        <div>SA {consumerInfo?.consumerScores["sound"]} </div>
        <div>SV {consumerInfo?.consumerScores["screen"]}</div>
      </div>
    </>
  );
}
