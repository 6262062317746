import React from "react";
import {
  type AgoraInternals,
  type ConsumerInfoMap,
} from "app-builder-super-sdk";

import LocalMediaContainer from "./local/LocalMediaContainer";
import RemoteMediaContainer from "./remote/RemoteMediaContainer";

export default function UsersMediaContainer({
  localUid,
  consumerInfoMap,
  localMedia,
  userList,
}: {
  localUid: string;
  consumerInfoMap: ConsumerInfoMap;
  localMedia: AgoraInternals;
  userList: string[];
}) {
  return (
    <>
      {localMedia?.initialized && (
        <>
          <LocalMediaContainer
            key={`local-media-key-${localUid}`}
            localMedia={localMedia}
            localUid={localUid}
          />
        </>
      )}
      <div className="row">
        {Object.keys(consumerInfoMap).map((el, i) => (
          <RemoteMediaContainer
            key={`remote-media-key-${el}`}
            el={el}
            localMedia={localMedia}
            consumerInfoMap={consumerInfoMap}
          />
        ))}
      </div>
    </>
  );
}
