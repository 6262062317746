import React from "react";
import SuperSdk from "app-builder-super-sdk";
import {
  type AgoraInternals,
  type ConsumerInfoMap,
} from "app-builder-super-sdk";
import ConsumerMediaCard from "../consumer/ConsumerMediaCard";
import ConsumerQualityStats from "../consumer/ConsumerQualityStats";
import ConsumerNetworkScores from "../consumer/ConsumerNetworkScores";
import ConsumerRequestQuality from "../consumer/ConsumerRequestQuality";

export default function RemoteMediaContainer({
  consumerInfoMap,
  localMedia,
  el,
}: {
  consumerInfoMap: ConsumerInfoMap;
  localMedia: AgoraInternals;
  el: any;
}) {
  const requestQuality = async (el: any, value: string) => {
    try {
      await SuperSdk.setMaxConsumerSpatialLayer(
        el,
        "video",
        parseInt(value),
        consumerInfoMap,
        localMedia
      );
    } catch (error) {
      console.error("error [setMaxConsumerSpatialLayer] : ", error);
    }
  };

  return (
    <div className="media-card">
      <div className="--header">
        <div className="--quality">
          <ConsumerRequestQuality
            handleQualityChange={(value) => requestQuality(el, value)}
            consumerInfo={consumerInfoMap[el]}
          />
          <div className="--state-desc">{consumerInfoMap[el].state} </div>
        </div>
        <div className="--scores">
          <ConsumerQualityStats consumerInfo={consumerInfoMap[el]} />
          <ConsumerNetworkScores consumerInfo={consumerInfoMap[el]} />
        </div>
      </div>
      <ConsumerMediaCard
        key={`remote-media-card-${el}`}
        uid={el}
        consumerInfo={consumerInfoMap[el]}
        consumerInfoMap={consumerInfoMap}
        localMedia={localMedia}
      />
    </div>
  );
}
