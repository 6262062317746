import React, { useEffect, useRef } from "react";
import { type AgoraInternals } from "app-builder-super-sdk";
export default function LocalMediaContainer({
  localUid,
  localMedia,
}: {
  localUid: string;
  localMedia: AgoraInternals;
}) {
  const videoPlayerRef = useRef<HTMLDivElement>(null);
  const screenVideoPlayerRef = useRef<HTMLDivElement>(null);
  const streamPlayerRef = useRef<HTMLVideoElement>(null);

  let isVideoPlayerRefInitialized = useRef<boolean>(false);
  let isScreenPlayerRefInitialized = useRef<boolean>(false);
  let isStreamPlayerRefInitialized = useRef<boolean>(false);

  useEffect(() => {
    if (videoPlayerRef.current) {
      isVideoPlayerRefInitialized.current = true;
    }
  }, [videoPlayerRef]);

  useEffect(() => {
    if (screenVideoPlayerRef.current) {
      isScreenPlayerRefInitialized.current = true;
    }
  }, [screenVideoPlayerRef]);

  useEffect(() => {
    if (streamPlayerRef.current) {
      isStreamPlayerRefInitialized.current = true;
    }
  }, [streamPlayerRef]);

  if (isVideoPlayerRefInitialized.current) {
    if (localMedia && localMedia.streams?.video) {
      // Add media player
      console.log("HTML adding local user video");
      const list = videoPlayerRef.current! as HTMLDivElement;
      if (list?.hasChildNodes()) {
        list.removeChild(list.children[0]);
      }
      if (videoPlayerRef.current) {
        const track = localMedia.streams?.video?.play(videoPlayerRef.current);
        console.log("playing video track : ", track);
      }
    } else {
      // Remove media player
      console.log("HTML removing local video");
      const list = videoPlayerRef.current! as HTMLDivElement;
      if (list?.hasChildNodes()) {
        list.removeChild(list.children[0]);
      }
    }
  }

  if (isScreenPlayerRefInitialized.current) {
    if (localMedia && localMedia.streams?.screen) {
      // Add media player
      const list = screenVideoPlayerRef.current! as HTMLDivElement;
      console.log("HTML adding local screen video");
      if (list?.hasChildNodes()) {
        list.removeChild(list.children[0]);
      }
      if (screenVideoPlayerRef.current) {
        const track = localMedia.streams?.screen?.play(
          screenVideoPlayerRef.current
        );
        console.log("playing screen video: ", track);
      }
    } else {
      // Remove media player
      console.log("HTML removing local screen video");
      const list = screenVideoPlayerRef.current! as HTMLDivElement;
      if (list?.hasChildNodes()) {
        list.removeChild(list.children[0]);
      }
    }
  }

  return (
    <div className="row">
      <div
        className="media-body --xs"
        key={`${localUid}-local-video`}
        style={{ backgroundColor: "lightpink" }}
      >
        <div className="--video">
          <div className="local-video-contianer">
            <div
              style={{ height: "100%", width: "100%" }}
              /*@ts-ignore*/
              ref={videoPlayerRef}
              id={`local-video-player-${localUid}`}
            />
          </div>
        </div>
        <div className="--actions --column">
          <div>Local User Video</div>
          <div>{localUid}</div>
        </div>
      </div>
      <div
        className="media-body --xs"
        key={`${localUid}-local-screen-video`}
        style={{ backgroundColor: "lightgrey" }}
      >
        <div className="--video">
          <div className="local-video-contianer">
            <div
              style={{ height: "100%", width: "100%" }}
              ref={screenVideoPlayerRef}
              id={`local-screen-video-player-${localUid}`}
            />
          </div>
        </div>
        <div className="--actions --column">
          <div>Local Screen Video</div>
          <div>{localUid}</div>
        </div>
      </div>
      <div
        className="media-body --xs"
        key={`${localUid}-local-canvas-video`}
        style={{ backgroundColor: "lightgreen" }}
      >
        <div className="--video">
          <div className="local-video-contianer">
            <video
              id={`local-stream-player-${localUid}`}
              controls
              playsInline
              loop
              ref={streamPlayerRef}
              muted
              style={{ height: "100%", width: "100%" }}
            >
              <source src={"/chrome.mp4"} type="video/mp4" />
              <p>This browser does not support the video element.</p>
            </video>
          </div>
        </div>
        <div className="--actions --column">
          <div>Local User Canvas</div>
          <div>{localUid}</div>
        </div>
      </div>
    </div>
  );
}
