var xe = Object.defineProperty;
var Ue = (e, t, c) => t in e ? xe(e, t, { enumerable: !0, configurable: !0, writable: !0, value: c }) : e[t] = c;
var T = (e, t, c) => (Ue(e, typeof t != "symbol" ? t + "" : t, c), c);
import me from "jwt-decode";
import { datadogLogs as Ae } from "@datadog/browser-logs";
import Te from "fetch-retry";
import Le from "ably";
import je from "object-sizeof";
import { nanoid as P } from "nanoid";
import oe from "p-queue";
const ie = "https://gather.agoraio.dev/v1", Qe = "pubaa7197d00e2de3c7dd5a02fd9faa0e3d", Ve = () => {
  Ae.init({
    clientToken: Qe,
    service: "super-sdk",
    proxyUrl: "https://sdk-ls.agoraio.dev/",
    version: "1.5.1",
    forwardErrorsToLogs: !1,
    sessionSampleRate: 100,
    env: "prod"
  });
};
Ve();
var n = /* @__PURE__ */ ((e) => (e.AgoraSDK = "Agora-SDK", e.ApiMethod = "API-Method", e.NetworkRest = "Network-REST", e.NetworkRealTime = "Network-RealTime", e.InfoMap = "InfoMap", e.Internals = "Internals", e.Health = "Health", e.Queue = "Queue", e.Alert = "Alert", e))(n || {});
const Oe = (e, t, c) => {
  const s = (i) => (o, l, d, ...r) => {
    if (i === "debug" && !c)
      return;
    const u = {
      session_id: e,
      timestamp: Date.now(),
      source: o,
      type: l,
      data: r
    };
    if (Ae.logger.log(
      d,
      u,
      i,
      i === "error" ? r[0] : void 0
    ), o === "Health")
      return;
    const h = o === "Agora-SDK" ? `%c${o}:[${l}] ` : `%cAgora-Wrapper: ${o}:[${l}] `, b = o === "Agora-SDK" ? "color: rgb(9, 157, 253); font-weight: bold" : "color: violet; font-weight: bold";
    t ? t(d, u, i) : console[i](
      h,
      b,
      d,
      u,
      i
    );
  };
  return {
    log: s("info"),
    info: s("info"),
    debug: s("debug"),
    warn: s("warn"),
    error: s("error")
  };
}, ye = Te(fetch, {
  retries: 500,
  retryDelay: 1e3
});
async function ze(e, t, c, s, i, o) {
  try {
    const l = {
      Authorization: `Bearer ${e}`,
      "X-Project-ID": s || "",
      "X-Request-ID": t || ""
    }, d = {
      URL: ie + `/user/details?ets=${Date.now()}&use_ably=true&space_id=${c}`,
      headers: l
    };
    i.log(
      n.NetworkRest,
      "userDetails",
      "Trying to fetch user Details",
      {
        requestId: o,
        requestData: d
      }
    );
    const r = Date.now();
    return await ye(
      ie + `/user/details?ets=${Date.now()}&use_ably=true&space_id=${c}`,
      {
        headers: new Headers(l)
      }
    ).then(async (u) => {
      if (u.status === 200) {
        const h = Date.now(), b = await u.json();
        return i.log(
          n.NetworkRest,
          "userDetails",
          "Fetched user details",
          {
            requestId: o,
            start: r,
            end: h,
            latency: h - r,
            responseData: b
          }
        ), b;
      }
      throw u;
    }).catch((u) => {
      throw u;
    });
  } catch (l) {
    throw i.error(n.NetworkRest, "userDetails", "request failed", l), l;
  }
}
async function ae(e, t) {
  try {
    t.log(
      n.NetworkRest,
      "refreshToken",
      "Trying to fetch refresh token",
      {
        token: e
      }
    );
    const c = Date.now();
    return await ye(`${ie}/token/refresh`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${e}`,
        "X-Platform-ID": "sdk_web"
      }
    }).then(async (s) => {
      if (s.status === 200) {
        const i = Date.now(), o = await s.json();
        return t.log(
          n.NetworkRest,
          "refreshToken",
          "Fetched refresh token",
          {
            start: c,
            end: i,
            latency: i - c,
            responseData: o
          }
        ), o;
      }
      throw s;
    }).catch((s) => {
      throw s;
    });
  } catch (c) {
    throw t.error(n.NetworkRest, "refreshToken", "request failed", c), c;
  }
}
function W(e) {
  return e === 0 || e === 6 ? 10 : 10 - (e - 1) * 2;
}
function Ee(e) {
  return e <= 0 ? 0 : e > 100 ? 1 : e * 100;
}
function M(e) {
  return e === "screen" || e === "sound";
}
function Y(e) {
  return e === "video" || e === "audio";
}
function $e(e, t) {
  return !!(e === "video" && t.videoEnabled || e === "audio" && t._audioEnabled || e === "screen" && t._screenVideoEnabled || e === "sound" && t._screenAudioEnabled);
}
function be(e, t) {
  Object.keys(e._subscribe).map(
    (c) => e._subscribe[c] = !1
  ), Array.isArray(t) && t.map((c) => {
    e._subscribe[c] = !0;
  });
}
function Ke(e, t) {
  let c = 0, s = [];
  for (let i = 0; i < e.length; i++) {
    let o = e[i];
    const l = je(o);
    if (c + l > t)
      return s;
    c += l, s.push(o);
  }
  return s;
}
function y(e, t) {
  try {
    e == null || e.listener();
  } catch (c) {
    t(
      n.Internals,
      "listener",
      "error occured when emitting listener",
      c
    );
  }
}
function O(e, t) {
  var c, s, i, o;
  try {
    let l = "";
    return Y(e) ? l = (s = (c = t.clients) == null ? void 0 : c.user) == null ? void 0 : s._clientId : l = (o = (i = t.clients) == null ? void 0 : i.screen) == null ? void 0 : o._clientId, l;
  } catch {
    return "";
  }
}
function E(e) {
  var t, c;
  try {
    return ((c = (t = e._video) == null ? void 0 : t.client) == null ? void 0 : c._clientId) || "";
  } catch {
    return "";
  }
}
const D = (e) => {
  const { log: t, debug: c, error: s, warn: i } = (e == null ? void 0 : e.logger) || {
    log: console.log,
    debug: console.debug,
    error: console.error,
    warn: console.warn
  };
  return { log: t, debug: c, error: s, warn: i };
}, qe = Te(fetch, {
  retries: 7,
  retryDelay: 1e3
});
class Fe {
  constructor(t, c, s, i) {
    T(this, "queue");
    T(this, "isConnected", !1);
    T(this, "tokenInterval");
    T(this, "queueInterval");
    T(this, "token");
    T(this, "ablyInitialToken");
    T(this, "ably");
    T(this, "connectionStateChangeCallback");
    T(this, "privateChannel");
    T(this, "privateC2SChannel");
    T(this, "presenceChannel");
    T(this, "logger");
    T(this, "handleSocketStateChange", (t) => {
      this.logger.log(
        n.NetworkRealTime,
        "connection",
        "socket: connection state changed: ",
        t
      ), t.current === "connected" ? this.isConnected = !0 : this.isConnected = !1;
    });
    this.logger = i, this.queue = [], this.isConnected = !1, this.token = t.authToken, this.ablyInitialToken = t.ablyToken, this.queueInterval = null, this.tokenInterval = null, this.ably = new Le.Realtime({
      echoMessages: !1,
      token: this.ablyInitialToken ? this.ablyInitialToken : null,
      log: {
        level: 4,
        handler: (l) => {
          i.log(
            n.NetworkRealTime,
            "ably",
            "log message from ably",
            l
          );
        }
      },
      authCallback: async (l, d) => {
        try {
          i.log(
            n.NetworkRest,
            "refreshToken",
            "token refresh initiate",
            this.token
          );
          const r = Date.now(), u = await ae(this.token, i), h = Date.now();
          i.log(
            n.NetworkRest,
            "refreshToken",
            "token refresh response",
            {
              start: r,
              end: h,
              latency: h - r,
              ...u
            }
          ), u != null && u.token && (this.token = u.token);
        } catch (r) {
          throw i.error(
            n.NetworkRest,
            "refreshToken",
            "SocketAuth error: token refresh failure",
            {
              error: r,
              token: this.token
            }
          ), Error(r);
        }
        try {
          i.log(n.NetworkRest, "socketAuth", "ably auth", {
            token: this.token
          });
          const r = Date.now(), u = await qe(`${ie}/ably/auth`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`
            },
            body: JSON.stringify({})
          });
          if (u && (u.status === 201 || u.status === 200)) {
            const h = Date.now(), b = await u.json();
            i.log(
              n.NetworkRest,
              "socketAuth",
              "Successfully fetched socket auth",
              {
                start: r,
                end: h,
                latency: h - r,
                ...b
              }
            ), d(null, b.token);
          } else {
            const h = new Error(
              `Server Responded with ${u && u.status}`
            );
            i.error(
              n.NetworkRest,
              "socketAuth",
              "Failed to fetched socket auth",
              h
            ), d(h.message, null);
          }
        } catch (r) {
          i.error(
            n.NetworkRest,
            "socketAuth",
            "network request failed",
            r
          ), d(r == null ? void 0 : r.message, null);
        }
      }
    }), this.privateChannel = this.ably.channels.get(
      `server-${t.private}`,
      {
        params: { rewind: "45s" }
      }
    ), this.privateC2SChannel = this.ably.channels.get(t.private), this.presenceChannel = this.ably.channels.get(t.presence, {
      modes: s ? ["PRESENCE", "PRESENCE_SUBSCRIBE", "PUBLISH"] : ["PRESENCE"]
    });
    let o = this;
    this.connectionStateChangeCallback = (l) => {
      o.handleSocketStateChange.call(o, l);
    }, this.ably.connection.on(this.connectionStateChangeCallback), c(() => {
    }), this.addTokenInterval(this.token), this.addQueueInterval(), this.addConnectionStateListener();
  }
  queueEvent(t, c = !1) {
    if (c)
      this.logger.log(
        n.NetworkRealTime,
        "C2S",
        "socket: event queued",
        {
          event: t,
          reqId: JSON.stringify(t.rqid)
        }
      ), this.queue.push(t);
    else {
      const s = this;
      this.logger.log(
        n.NetworkRealTime,
        "C2S",
        "socket: event sent without queuing",
        {
          event: t,
          reqId: JSON.stringify(t.rqid)
        }
      ), this.sendEventToSocket(
        [t],
        function(o, l) {
          o ? (s.logger.log(
            n.NetworkRealTime,
            "C2S",
            "socket: was event sent to socket sucessfully ? ",
            o
          ), s.logger.log(
            n.NetworkRealTime,
            "C2S",
            "Success: Chunk sent to socket.",
            { currentQueue: [...s.queue], successChunk: [t] }
          )) : (s.logger.error(
            n.NetworkRealTime,
            "C2S",
            "Failure: sending to socket(queuing)",
            l,
            { currentQueue: [...s.queue], failedChunk: [t] }
          ), s.queueEvent(t, !0));
        }
      );
    }
  }
  processQueueInterval() {
    if (this.queue.length === 0)
      return;
    this.logger.debug(
      n.Internals,
      "NetworkManager",
      "Getting chunks of size 10kb... "
    );
    let t;
    try {
      t = Ke(this.queue, 1e4);
    } catch (c) {
      this.logger.error(
        n.Internals,
        "NetworkManager",
        "Error while splitting into chunks",
        c,
        { queueTriedToSplit: this.queue }
      ), t = this.queue;
    }
    this.processEventsChunk(t);
  }
  processEventsChunk(t) {
    this.logger.log(
      n.Internals,
      "NetworkManager",
      "processing chunk",
      t
    );
    let c = this;
    this.sendEventToSocket(
      t,
      function s(i) {
        c.logger.log(
          n.NetworkRealTime,
          "C2S",
          "socket: was event sent to socket sucessfully ? ",
          i
        ), i || (c.logger.log(
          n.NetworkRealTime,
          "C2S",
          "Failure: sending to socket(retrying)",
          { currentQueue: [...c.queue], failedChunk: t }
        ), c.sendEventToSocket(t, s)), i && c.logger.log(
          n.NetworkRealTime,
          "C2S",
          "Success: Chunk sent to socket.",
          { currentQueue: [...c.queue], successChunk: t }
        );
      }
    ), this.queue.splice(0, t.length);
  }
  sendEventToSocket(t, c) {
    const s = Date.now();
    this.privateC2SChannel.publish("client-event", t, (i) => {
      const o = t.map((d) => d == null ? void 0 : d.rqid), l = Date.now();
      this.logger.log(n.NetworkRealTime, "C2S", "Events published", {
        rqids: JSON.stringify(o),
        delay: l - s,
        success: !i
      }), c(!i, i || null);
    });
  }
  addQueueInterval() {
    this.queueInterval === null && (this.logger.log(
      n.Internals,
      "NetworkManager",
      "adding queue interval of 10ms: "
    ), this.queueInterval = setInterval(async () => {
      this.processQueueInterval();
    }, 100));
  }
  logOfflineEventCallback(t) {
    this.logger.debug(
      n.Health,
      "ConnectionState",
      `Navigator is offline, state:  ${navigator.onLine}`,
      {
        navigatorState: navigator.onLine,
        pusherIsConnected: this.isConnected,
        event: t
      }
    );
  }
  logOnlineEventCallback(t) {
    this.logger.debug(
      n.Health,
      "ConnectionState",
      `Navigator is online, state:  ${navigator.onLine}`,
      {
        navigatorState: navigator.onLine,
        pusherIsConnected: this.isConnected,
        event: t
      }
    );
  }
  addConnectionStateListener() {
    addEventListener("offline", this.logOfflineEventCallback.bind(this)), addEventListener("online", this.logOnlineEventCallback.bind(this));
  }
  clearQueueInterval() {
    clearInterval(this.queueInterval), this.queueInterval = null;
  }
  addTokenInterval(t) {
    const c = me(t);
    this.logger.log(
      n.Internals,
      "NetworkManager",
      "adding token interval which expires at: ",
      c.exp
    );
    const s = c.exp * 1e3;
    this.tokenInterval = setInterval(async () => {
      try {
        const i = Math.floor(
          Math.abs(s - Date.now()) / 1e3
        ), o = Math.floor(i / 60), l = Math.floor(i / (60 * 60));
        this.logger.log(
          n.Internals,
          "NetworkManager",
          "Time in seconds less than 59...refreshing new token"
        );
        const d = Date.now(), r = await ae(this.token, this.logger), u = Date.now();
        this.logger.log(
          n.NetworkRest,
          "refreshToken",
          "token refresh response",
          {
            start: d,
            end: u,
            latency: u - d,
            ...r
          }
        ), r != null && r.token && (this.token = r.token), this.logger.debug(
          n.Internals,
          "NetworkManager",
          "New refresh token updated: "
        );
      } catch (i) {
        this.logger.error(
          n.NetworkRest,
          "refreshToken",
          "error fetching refresh token..trying again ",
          i
        );
      }
    }, 18e5);
  }
  clearTokenInterval() {
    clearInterval(this.tokenInterval), this.tokenInterval = null;
  }
  clearNavigatorListener() {
    removeEventListener("offline", this.logOfflineEventCallback), removeEventListener("online", this.logOnlineEventCallback);
  }
  destroy() {
    this.logger.log(
      n.Internals,
      "NetworkManager",
      "pusher: destroyed instance, unbinded channel and disconnected from pusher "
    ), this.clearTokenInterval(), this.clearQueueInterval(), this.clearNavigatorListener(), this.presenceChannel.presence.leave(), this.ably.channels.release(this.privateChannel.name), this.ably.channels.release(this.presenceChannel.name), this.ably.connection.off(this.connectionStateChangeCallback), this.ably.close(), this.ably = null;
  }
}
var te = /* @__PURE__ */ ((e) => (e[e.LowQuality = 0] = "LowQuality", e[e.MidQuality = 1] = "MidQuality", e[e.HighQuality = 2] = "HighQuality", e))(te || {}), U = /* @__PURE__ */ ((e) => (e.WaitingForSFUAddress = "Waiting for SFU address", e.ConnectingToSFU = "Connecting to SFU", e.Connected = "Connected", e.Consuming = "Consuming from SFU", e.NotAllowed = "Not allowed by the other user", e.ProducerDisconnected = "Producer disconnected", e))(U || {});
class Je {
  constructor(t = "Waiting for SFU address") {
    T(this, "enabled");
    T(this, "muted");
    T(this, "state");
    T(this, "streamQuality");
    T(this, "producerScores");
    T(this, "consumerScores");
    T(this, "videoEnabled");
    T(this, "_audioEnabled");
    T(this, "_screenVideoEnabled");
    T(this, "_screenAudioEnabled");
    T(this, "volume");
    T(this, "screenVolume");
    T(this, "_timeStamp");
    T(this, "_subscribedStreamType");
    T(this, "_video");
    T(this, "_screenVideo");
    T(this, "_subscribe");
    T(this, "_timers");
    this.state = t, this.streamQuality = "HIGH", this._timeStamp = {
      disallowEventTimestamp: null,
      subscribeEventTimestamp: null
    }, this.enabled = {
      audio: !1,
      video: !1,
      screen: !1,
      sound: !1
    }, this.muted = {
      audio: !1,
      video: !1,
      screen: !1,
      sound: !1
    }, this.producerScores = {
      audio: 10,
      video: 10,
      screen: 10,
      sound: 10
    }, this.consumerScores = {
      audio: 10,
      video: 10,
      screen: 10,
      sound: 10
    }, this.volume = 1, this.screenVolume = 1, this._subscribedStreamType = null, this._video = {
      client: null,
      streams: {
        audio: null,
        video: null
      },
      agUser: null,
      _remoteUid: null
    }, this._screenVideo = {
      streams: {
        sound: null,
        screen: null
      },
      _remoteUid: null,
      agUser: null
    }, this._subscribe = {
      audio: !1,
      video: !1,
      screen: !1,
      sound: !1
    }, this.videoEnabled = !1, this._audioEnabled = !1, this._screenAudioEnabled = !1, this._screenVideoEnabled = !1, this._timers = {
      _state: {
        state: null,
        stateInitAt: null,
        t5: null,
        t10: null,
        t20: null,
        t45: null,
        t90: null
      },
      _join: {
        t5: null,
        t10: null,
        t20: null,
        t45: null,
        t90: null
      },
      _joinService: null
    };
  }
  summarize() {
    return {
      _video: this._video,
      _screenVideo: this._screenVideo,
      enabled: this.enabled,
      muted: this.muted,
      connectionState: this.state
    };
  }
}
function Ce(e) {
  return new Je(e);
}
const He = (e, t, c, s, i) => {
  var o, l, d, r;
  i.initialized = !0, i.sessionId = t, i.appId = s, i.projectId = e.project_id, i.spaceId = c, i.gId = e.user_id, i.uid = e.auid, i.sts = e.sts, i.screenUid = e.screen_auid, i.disconnectedCallbacks = {}, i.clients = {
    user: null,
    screen: null
  }, i.streams = {
    audio: null,
    video: null,
    screen: null,
    sound: null
  }, i.targetQuality = "HIGH", i.channelInfo = {
    name: (o = e.channel) == null ? void 0 : o.channel_name,
    userRtcToken: (l = e.channel) == null ? void 0 : l.rtc,
    auid: e.auid,
    screenRtcToken: (d = e.channel) == null ? void 0 : d.screen_rtc,
    screenauid: e.screen_auid,
    expiresAt: (r = e.channel) == null ? void 0 : r.exp_at
  }, i._allowed = /* @__PURE__ */ new Set(), i._joinTimer = {
    t5: null,
    t10: null,
    t20: null,
    t45: null,
    t90: null
  }, i._timers = {
    _joinServiceTimer: {
      user: null,
      screen: null
    }
  };
}, fe = (e) => {
  var t, c, s, i, o, l, d, r;
  e.initialized = !1, e.appId = "", e.projectId = "", e.spaceId = "", e.gId = "", e.uid = 0, e.sts = null, e.screenUid = 0, e.clients = {
    user: null,
    screen: null
  }, e.streams = {
    audio: null,
    video: null,
    screen: null,
    sound: null
  }, e.targetQuality = "HIGH", e.channelInfo = null, e._queue = null, e._network = null, clearInterval(e._healthTimer), e._healthTimer = null, e != null && e._joinTimer && Object.keys(e == null ? void 0 : e._joinTimer).forEach((u) => {
    clearTimeout(e == null ? void 0 : e._joinTimer[u]);
  }), e._joinTimer = {
    t5: null,
    t10: null,
    t20: null,
    t45: null,
    t90: null
  }, (c = (t = e == null ? void 0 : e._timers) == null ? void 0 : t._joinServiceTimer) != null && c.screen && clearTimeout((i = (s = e == null ? void 0 : e._timers) == null ? void 0 : s._joinServiceTimer) == null ? void 0 : i.screen), (l = (o = e == null ? void 0 : e._timers) == null ? void 0 : o._joinServiceTimer) != null && l.user && clearTimeout((r = (d = e == null ? void 0 : e._timers) == null ? void 0 : d._joinServiceTimer) == null ? void 0 : r.user), e._timers = {
    _joinServiceTimer: {
      user: null,
      screen: null
    }
  }, e._debugSender = null, e._debugVideoTrack = null, e._allowed = /* @__PURE__ */ new Set();
}, X = {
  LOW: {
    width: 320,
    height: 180,
    bitrate: 12e4,
    frameRate: 24
  },
  MID: {
    width: 640,
    height: 360,
    bitrate: 35e4,
    frameRate: 24
  },
  HIGH: {
    width: 1280,
    height: 720,
    bitrate: 15e5,
    frameRate: 24
  }
}, We = (e) => X.hasOwnProperty(e) ? X[e] : X.HIGH;
async function Re(e) {
  const { log: t, error: c } = e == null ? void 0 : e.logger, s = P(5);
  return new Promise((i) => {
    t(
      n.Internals,
      "produceStart",
      "sending socket request RTC_TOKEN",
      {
        requestId: s,
        connectionState: e.clients.user.connectionState
      }
    ), G(e, s, "RTC_TOKEN", "REQUEST", {
      channel: {
        channel_name: e.channelInfo.name
      },
      dstid: `${e.gId}`
    }), t(
      n.Internals,
      "produceStart",
      "setting up callback for new token RTC_TOKEN response",
      {
        requestId: s
      }
    ), e.disconnectedCallbacks[s] = function(o) {
      t(
        n.Internals,
        "produceStart",
        "callback Triggered for token RTC_TOKEN response",
        {
          requestId: s
        }
      ), delete e.disconnectedCallbacks[s], e.channelInfo.userRtcToken = o.rtc, e.channelInfo.screenRtcToken = o.screen_rtc, e.channelInfo.expiresAt = o.exp_at, y(e, c), i({
        rtcToken: o.rtc,
        screenToken: o.screen_rtc,
        expiresAt: o.exp_at
      });
    };
  });
}
async function Be(e) {
  const { log: t } = e == null ? void 0 : e.logger, c = P(5);
  return new Promise(async (s, i) => {
    t(n.Internals, "produceStart", "Get token for local user", {
      requestId: c,
      connectionState: e.clients.user.connectionState,
      playerID: e.gId,
      currentRTCToken: e.channelInfo.userRtcToken,
      currentRTCScreenToken: e.channelInfo.screenRtcToken,
      currentRTCExpiry: e.channelInfo.expiresAt
    });
    const o = new Date(), l = new Date(e.channelInfo.expiresAt);
    if (o.getTime() < l.getTime()) {
      t(
        n.Internals,
        "produceStart",
        "currentTime < expire time (token is still valid)",
        {
          requestId: c,
          connectionState: e.clients.user.connectionState,
          playerID: e.gId,
          currentRTCToken: e.channelInfo.userRtcToken,
          currentRTCScreenToken: e.channelInfo.screenRtcToken,
          currentRTCExpiry: e.channelInfo.expiresAt
        }
      ), s({
        rtcToken: e.channelInfo.userRtcToken,
        screenToken: e.channelInfo.screenRtcToken,
        expiresAt: e.channelInfo.expiresAt
      });
      return;
    }
    t(
      n.Internals,
      "produceStart",
      "Call for generateNewLocalRTCToken",
      {
        requestId: c,
        connectionState: e.clients.user.connectionState,
        playerID: e.gId,
        currentRTCToken: e.channelInfo.userRtcToken,
        currentRTCScreenToken: e.channelInfo.screenRtcToken,
        currentRTCExpiry: e.channelInfo.expiresAt
      }
    );
    const { rtcToken: d, screenToken: r, expiresAt: u } = await Re(
      e
    );
    t(
      n.Internals,
      "produceStart",
      "Call for generateNewLocalRTCToken complete",
      {
        requestId: c,
        connectionState: e.clients.user.connectionState,
        playerID: e.gId,
        currentRTCToken: e.channelInfo.userRtcToken,
        currentRTCScreenToken: e.channelInfo.screenRtcToken,
        currentRTCExpiry: e.channelInfo.expiresAt,
        rtcToken: d,
        screenToken: r,
        expiresAt: u
      }
    ), s({ rtcToken: d, screenToken: r, expiresAt: u });
  });
}
const Ge = (e, t) => setInterval(() => {
  let c = {
    remoteVideo: {},
    remoteAudio: {},
    remoteNtwkQuality: {},
    remoteSubscribedStream: {},
    remoteStreamQuality: {}
  };
  Object.keys(e).forEach((s) => {
    var i, o, l, d, r, u, h, b, A, w, a;
    c.remoteAudio[s] = ((l = (o = (i = e[s]) == null ? void 0 : i._video) == null ? void 0 : o.streams.audio) == null ? void 0 : l.getMediaStreamTrack().getSettings()) || null, c.remoteVideo[s] = ((u = (r = (d = e[s]) == null ? void 0 : d._video) == null ? void 0 : r.streams.video) == null ? void 0 : u.getMediaStreamTrack().getSettings()) || null, c.remoteNtwkQuality[s] = ((A = (b = (h = e[s]) == null ? void 0 : h._video) == null ? void 0 : b.client) == null ? void 0 : A.getRemoteNetworkQuality()) || null, c.remoteSubscribedStream[s] = ((w = e[s]) == null ? void 0 : w._subscribedStreamType) || null, c.remoteStreamQuality[s] = ((a = e[s]) == null ? void 0 : a.streamQuality) || null;
  }), Object.keys(c == null ? void 0 : c.remoteAudio).length > 0 && t(
    n.Health,
    "RemoteAudio",
    "remote audio mediastream stats",
    c.remoteAudio
  ), Object.keys(c == null ? void 0 : c.remoteVideo).length > 0 && t(
    n.Health,
    "RemoteVideo",
    "remote video mediastream stats",
    c.remoteVideo
  ), Object.keys(c == null ? void 0 : c.remoteNtwkQuality).length > 0 && t(
    n.Health,
    "RemoteNetworkQuality",
    "remote network stats",
    c.remoteNtwkQuality
  ), Object.keys(c == null ? void 0 : c.remoteSubscribedStream).length > 0 && t(
    n.Health,
    "RemoteSubscribedStream",
    "remote subscribed stream stats",
    c.remoteSubscribedStream
  ), Object.keys(c == null ? void 0 : c.remoteStreamQuality).length > 0 && t(
    n.Health,
    "RemoteStreamQuality",
    "remote stream quality stats",
    c.remoteStreamQuality
  );
}, 2e3);
function $(e, t, c) {
  const { log: s, warn: i } = t.logger;
  re(e);
  const o = {
    prevState: e._timers._state.state ? e._timers._state.state : "NA",
    newState: e.state,
    duration: e._timers._state.stateInitAt ? (Date.now() - e._timers._state.stateInitAt) / 1e3 : 0
  };
  s(n.Internals, "ConsumerState", "state changed", {
    ...c,
    transition: { ...o }
  }), e._timers._state.state = e.state, e._timers._state.stateInitAt = Date.now(), !(e.state === U.Connected || e.state === U.ProducerDisconnected) && (e._timers._state.t5 = setTimeout(() => {
    i(n.Alert, "ConsumerState", "5sec", {
      ...c,
      state: e.state
    });
  }, 5e3), e._timers._state.t10 = setTimeout(() => {
    i(n.Alert, "ConsumerState", "10sec", {
      ...c,
      state: e.state
    });
  }, 1e4), e._timers._state.t20 = setTimeout(() => {
    i(n.Alert, "ConsumerState", "20sec", {
      ...c,
      state: e.state
    });
  }, 2e4), e._timers._state.t45 = setTimeout(() => {
    i(n.Alert, "ConsumerState", "45sec", {
      ...c,
      state: e.state
    });
  }, 45e3), e._timers._state.t90 = setTimeout(() => {
    i(n.Alert, "ConsumerState", "90sec", {
      ...c,
      state: e.state
    });
  }, 9e4));
}
function re(e) {
  var t;
  Object.keys((t = e == null ? void 0 : e._timers) == null ? void 0 : t._state).filter((c) => ["t5", "t10", "t20", "t45", "t90"].includes(c)).forEach((c) => {
    e._timers._state[c] && (clearTimeout(e._timers._state[c]), e._timers._state[c] = null);
  });
}
function Pe(e, t, c) {
  const { log: s, warn: i } = t.logger;
  s(
    n.Internals,
    "RemoteJoin",
    "remote rtc-join started. Timer initialized",
    c
  ), e._timers._join.t5 = setTimeout(() => {
    i(n.Alert, "ChannelJoin", "5sec", c);
  }, 5e3), e._timers._join.t10 = setTimeout(() => {
    i(n.Alert, "ChannelJoin", "10sec", c);
  }, 1e4), e._timers._join.t20 = setTimeout(() => {
    i(n.Alert, "ChannelJoin", "20sec", c);
  }, 2e4), e._timers._join.t45 = setTimeout(() => {
    i(n.Alert, "ChannelJoin", "45sec", c);
  }, 45e3), e._timers._join.t90 = setTimeout(() => {
    i(n.Alert, "ChannelJoin", "90sec", c);
  }, 9e4);
}
function se(e, t, c) {
  const { log: s } = t.logger;
  s(
    n.Internals,
    "RemoteJoin",
    "remote rtc-join ended. Timer destroyed",
    c
  ), Object.keys(e._timers._join).filter((i) => ["t5", "t10", "t20", "t45", "t90"].includes(i)).forEach((i) => {
    e._timers._join[i] && (clearTimeout(e._timers._join[i]), e._timers._join[i] = null);
  });
}
const Ne = (e, t, c, s, i) => {
  const { log: o, error: l } = i.logger, d = c.getJoinChannelServiceRecords();
  o(
    n.AgoraSDK,
    "Service",
    "join channel service records (init records before join)",
    {
      records: d,
      isLocal: !1,
      clientId: c._clientId,
      channelName: c.channelName,
      requestId: e,
      playerId: t
    }
  ), s._timers._joinService = setInterval(() => {
    try {
      const r = c.getJoinChannelServiceRecords();
      o(
        n.AgoraSDK,
        "Service",
        "join channel service records (getJoinChannelServiceRecords)",
        {
          records: r,
          isLocal: !1,
          clientId: c._clientId,
          channelName: c.channelName,
          requestId: e,
          playerId: t
        }
      );
    } catch (r) {
      l(
        n.AgoraSDK,
        "Service",
        "getJoinChannelServiceRecords failed",
        r
      );
    }
  }, 1e3);
}, ce = (e) => {
  var t;
  (t = e == null ? void 0 : e._timers) != null && t._joinService && (clearInterval(e._timers._joinService), e._timers._joinService = null);
};
function Z(e, t) {
  const { log: c, warn: s } = e.logger;
  c(
    n.Internals,
    "LocalJoin",
    "local rtc-join started. Timer initialized",
    t
  ), e._joinTimer.t5 = setTimeout(() => {
    s(n.Alert, "ChannelJoin", "5sec", t);
  }, 5e3), e._joinTimer.t10 = setTimeout(() => {
    s(n.Alert, "ChannelJoin", "10sec", t);
  }, 1e4), e._joinTimer.t20 = setTimeout(() => {
    s(n.Alert, "ChannelJoin", "20sec", t);
  }, 2e4), e._joinTimer.t45 = setTimeout(() => {
    s(n.Alert, "ChannelJoin", "45sec", t);
  }, 45e3), e._joinTimer.t90 = setTimeout(() => {
    s(n.Alert, "ChannelJoin", "90sec", t);
  }, 9e4);
}
function B(e, t) {
  const { log: c } = e.logger;
  c(
    n.Internals,
    "LocalJoin",
    "local rtc-join ended. Timer destroyed",
    t
  ), Object.keys(e._joinTimer).filter((s) => ["t5", "t10", "t20", "t45", "t90"].includes(s)).forEach((s) => {
    e._joinTimer[s] && (clearTimeout(e._joinTimer[s]), e._joinTimer[s] = null);
  });
}
const g = (e, t, c) => {
  const { log: s, error: i } = c.logger, o = e.getJoinChannelServiceRecords();
  s(
    n.AgoraSDK,
    "Service",
    "join channel service records (init records before join)",
    {
      records: o,
      isLocal: !0,
      playerId: c.gId,
      clientId: e._clientId,
      channelName: e.channelName
    }
  ), c._timers._joinServiceTimer[t] = setInterval(() => {
    try {
      const l = e.getJoinChannelServiceRecords();
      s(
        n.AgoraSDK,
        "Service",
        "join channel service records (getJoinChannelServiceRecords)",
        {
          records: l,
          isLocal: !0,
          playerId: c.gId,
          clientId: e._clientId,
          channelName: e.channelName
        }
      );
    } catch (l) {
      i(
        n.AgoraSDK,
        "Service",
        "getJoinChannelServiceRecords failed",
        l
      );
    }
  }, 1e3);
}, K = (e, t) => {
  var c;
  (c = t == null ? void 0 : t._timers) != null && c._joinServiceTimer && (clearInterval(t._timers._joinServiceTimer[e]), t._timers._joinServiceTimer[e] = null);
};
function De(e, t) {
  re(e), ce(e), se(e, t, {});
}
function we(e) {
  let t = {};
  switch (e) {
    case "audio":
      t.source = "user", t.type = "audio", t.kind = e;
      break;
    case "video":
      t.source = "user", t.type = "video", t.kind = e;
      break;
    case "screen":
      t.source = "screenshare", t.type = "video", t.kind = e;
      break;
    case "sound":
      t.source = "screenshare", t.type = "audio", t.kind = e;
      break;
    default:
      throw new Error("No Media kind provided");
  }
  return t;
}
function Xe(e) {
  switch (e) {
    case te.HighQuality:
      return "HIGH";
    case te.MidQuality:
      return "MID";
    case te.LowQuality:
      return "LOW";
    default:
      return null;
  }
}
async function Ye(e) {
  return new Promise((t, c) => {
    const s = e.getVideoTracks()[0].getSettings();
    if ((s == null ? void 0 : s.width) && (s == null ? void 0 : s.height)) {
      const d = {
        width: s.width,
        height: s.height
      };
      t(d);
      return;
    }
    let i = document.createElement("video");
    i.muted = !0, i.srcObject = e;
    const o = setTimeout(() => {
      i.removeEventListener("loadedmetadata", l), t({
        width: 1280,
        height: 720
      });
    }, 1e3), l = () => {
      clearTimeout(o);
      let d = {
        width: i.videoWidth,
        height: i.videoHeight
      };
      i = null, t(d);
    };
    i.addEventListener("loadedmetadata", l);
  });
}
async function ne(e, t, c, s, i) {
  var p;
  const { log: o, error: l } = s.logger;
  o(
    n.Internals,
    "setQuality",
    "setQuality called with below config",
    {
      requestId: i,
      height: e,
      bitrate: t,
      frameRate: c
    }
  );
  const d = s.clients.user._p2pChannel.connection.peerConnection.getSenders(), r = s.streams.video.getMediaStreamTrack(), u = d.find(
    (S) => S.track && S.track.id === r.id
  ), h = s.streams.video.getMediaStreamTrack();
  s._debugSender || (s._debugSender = u), s._debugVideoTrack || (s._debugVideoTrack = h);
  let b = u.getParameters();
  const A = (p = b.encodings) == null ? void 0 : p[0];
  if (h.readyState === "ended")
    return;
  const w = new MediaStream([h]), { height: a = 0, width: k = 0 } = await Ye(w);
  o(n.Internals, "setQuality", "stream dimensions", {
    requestId: i,
    captureWidth: k,
    captureHeight: a
  }), o(n.Internals, "setQuality", "quality info before change", {
    requestId: i,
    params: Object.assign({}, b),
    encoding: Object.assign({}, A),
    captureWidth: k,
    captureHeight: a
  });
  const v = a / e, N = {
    scaleResolutionDownBy: v > 1 ? v : 1,
    maxBitrate: t,
    maxFramerate: c
  };
  o(n.Internals, "setQuality", "computed changes to encoding", {
    requestId: i,
    encoding: N
  });
  try {
    b.encodings.forEach((m) => {
      m.maxBitrate = N.maxBitrate, m.maxFramerate = N.maxFramerate, m.scaleResolutionDownBy = N.scaleResolutionDownBy;
    }), await u.setParameters(b);
    const S = u.getParameters();
    o(
      n.Internals,
      "setQuality",
      "quality set successfully for sender. New quality info:",
      {
        requestId: i,
        params: Object.assign({}, S),
        encoding: Object.assign({}, S.encodings[0]),
        captureWidth: k,
        captureHeight: a
      }
    );
  } catch (S) {
    l(n.Internals, "setQuality", "error in setQuality", S, {
      requestId: i
    });
  }
}
const Se = {
  codec: "vp8",
  mode: "live",
  role: "host"
}, ke = {
  codec: "vp8",
  mode: "live",
  role: "audience"
};
function Ze(e, t, c = !1) {
  return new Promise(async (s, i) => {
    const { log: o } = e.logger;
    try {
      e._agoraRTC.enableLogUpload();
      const l = {
        user: e._agoraRTC.createClient(Se),
        screen: e._agoraRTC.createClient(
          Se
        )
      }, d = {
        onLocalTokenExpire: async () => {
          const { rtcToken: w, screenToken: a } = await Re(
            e
          );
          o(
            n.Internals,
            "start",
            "generateNewLocalRTCToken response",
            {
              requestId: t,
              rtcToken: w,
              screenToken: a
            }
          ), o(
            n.Internals,
            "start",
            "current connection status of both user and screen clients",
            {
              requestId: t,
              userClientconnectionState: e.clients.user.connectionState,
              screenClientconnectionState: e.clients.screen.connectionState
            }
          ), e.clients.user.connectionState === "CONNECTED" ? (o(n.Internals, "start", "user client is connected", {
            requestId: t,
            connectionState: e.clients.user.connectionState
          }), await e.clients.user.renewToken(w), o(
            n.AgoraSDK,
            "API",
            `[${l.user._clientId}] renewToken (user client)`,
            {
              token: w
            }
          )) : (o(n.AgoraSDK, "API", "join attempt", {
            requestId: t,
            channel: e.channelInfo,
            sessionId: e.sessionId,
            localPlayerId: e.gId,
            localUid: e.uid,
            client: "user",
            event: "onLocalTokenExpire"
          }), Z(e, {
            requestId: t,
            channel: e.channelInfo,
            appId: e.appId,
            sessionId: e.sessionId,
            localPlayerId: e.gId,
            localUid: e.uid,
            client: "user",
            event: "onLocalTokenExpire"
          }), g(
            e.clients.user,
            "user",
            e
          ), await e.clients.user.join(
            e.appId,
            e.channelInfo.name,
            w,
            e.channelInfo.auid
          ), K("user", e), B(e, {
            requestId: t,
            channel: e.channelInfo,
            appId: e.appId,
            sessionId: e.sessionId,
            localPlayerId: e.gId,
            localUid: e.uid,
            client: "user",
            event: "onLocalTokenExpire"
          }), o(
            n.AgoraSDK,
            "API",
            `[${l.user._clientId}] join (user client)`,
            {
              requestId: t,
              playerId: e.gId,
              appId: e.appId,
              name: e.channelInfo.name,
              token: w,
              uid: e.channelInfo.auid
            }
          )), e.clients.screen.connectionState === "CONNECTED" ? (o(n.Internals, "start", "screen client is connected", {
            requestId: t,
            connectionState: e.clients.screen.connectionState
          }), await e.clients.screen.renewToken(a), o(
            n.AgoraSDK,
            "API",
            `[${l.screen._clientId}] renewToken (screen client)`,
            {
              token: w
            }
          )) : (o(n.AgoraSDK, "API", "join attempt", {
            requestId: t,
            channel: e.channelInfo,
            sessionId: e.sessionId,
            localPlayerId: e.gId,
            localUid: e.uid,
            client: "screen"
          }), Z(e, {
            requestId: t,
            channel: e.channelInfo,
            appId: e.appId,
            sessionId: e.sessionId,
            localPlayerId: e.gId,
            localUid: e.uid,
            client: "screen",
            event: "onLocalTokenExpire"
          }), g(
            e.clients.screen,
            "screen",
            e
          ), await e.clients.screen.join(
            e.appId,
            e.channelInfo.name,
            a,
            e.channelInfo.screenauid
          ), K("screen", e), B(e, {
            requestId: t,
            channel: e.channelInfo,
            appId: e.appId,
            sessionId: e.sessionId,
            localPlayerId: e.gId,
            localUid: e.uid,
            client: "screen",
            event: "onLocalTokenExpire"
          }), o(
            n.AgoraSDK,
            "API",
            `[${l.user._clientId}] join (screen client)`,
            {
              requestId: t,
              playerId: e.gId,
              appId: e.appId,
              name: e.channelInfo.name,
              token: a,
              uid: e.channelInfo.screenauid
            }
          ));
        }
      };
      if (l.user.on("token-privilege-will-expire", async function() {
        o(
          n.AgoraSDK,
          "Event",
          `[${l.user._clientId}] token-privilege-will-expire`,
          {
            requestId: t,
            playerId: e.gId,
            agoraUid: e.channelInfo.auid,
            channel: e.channelInfo.name
          }
        ), await d.onLocalTokenExpire();
      }), l.user.on("token-privilege-did-expire", async () => {
        o(
          n.AgoraSDK,
          "Event",
          `[${l.user._clientId}] token-privilege-did-expire-local`,
          {
            requestId: t,
            playerId: e.gId,
            agoraUid: e.channelInfo.auid,
            channel: e.channelInfo.name
          }
        ), await d.onLocalTokenExpire();
      }), c)
        o(
          n.AgoraSDK,
          "API",
          `[${l.user._clientId}], [${l.screen._clientId}] created clients, not joining`,
          {
            requestId: t,
            playerId: e.gId,
            appId: e.appId,
            noJoin: c
          }
        );
      else {
        const { name: w, userRtcToken: a, auid: k, screenRtcToken: v, screenauid: C } = e.channelInfo;
        o(n.AgoraSDK, "API", "join attempt", {
          requestId: t,
          channel: e.channelInfo,
          sessionId: e.sessionId,
          localPlayerId: e.gId,
          localUid: e.uid,
          client: "user",
          event: "createLocalClientAndJoin"
        }), Z(e, {
          requestId: t,
          channel: e.channelInfo,
          appId: e.appId,
          sessionId: e.sessionId,
          localPlayerId: e.gId,
          localUid: e.uid,
          client: "user",
          event: "createLocalClientAndJoin"
        }), g(
          l.user,
          "user",
          e
        ), await l.user.join(
          e.appId,
          w,
          a,
          k
        ), K("user", e), B(e, {
          requestId: t,
          channel: e.channelInfo,
          appId: e.appId,
          sessionId: e.sessionId,
          localPlayerId: e.gId,
          localUid: e.uid,
          client: "user",
          event: "createLocalClientAndJoin"
        }), o(
          n.AgoraSDK,
          "API",
          `[${l.user._clientId}] join (user client)`,
          {
            requestId: t,
            playerId: e.gId,
            appId: e.appId,
            name: w,
            token: a,
            uid: k
          }
        ), o(n.AgoraSDK, "API", "join attempt", {
          requestId: t,
          channel: e.channelInfo,
          sessionId: e.sessionId,
          localPlayerId: e.gId,
          localUid: e.uid,
          client: "screen",
          event: "createLocalClientAndJoin"
        }), Z(e, {
          requestId: t,
          channel: e.channelInfo,
          appId: e.appId,
          sessionId: e.sessionId,
          localPlayerId: e.gId,
          localUid: e.uid,
          client: "screen",
          event: "createLocalClientAndJoin"
        }), g(
          l.screen,
          "screen",
          e
        ), await l.screen.join(
          e.appId,
          w,
          v,
          C
        ), K("screen", e), B(e, {
          requestId: t,
          channel: e.channelInfo,
          appId: e.appId,
          sessionId: e.sessionId,
          localPlayerId: e.gId,
          localUid: e.uid,
          client: "screen",
          event: "createLocalClientAndJoin"
        }), o(
          n.AgoraSDK,
          "API",
          `[${l.user._clientId}] join (screen client)`,
          {
            requestId: t,
            playerId: e.gId,
            appId: e.appId,
            name: w,
            token: v,
            uid: C
          }
        );
      }
      await l.user.enableDualStream(), o(
        n.AgoraSDK,
        "API",
        `[${l.user._clientId}] enableDualStream (user client)`
      );
      const { frameRate: r, height: u, width: h, bitrate: b } = X.LOW, A = {
        width: h,
        height: u,
        bitrate: 120,
        framerate: r
      };
      l.user.setLowStreamParameter(A), o(
        n.AgoraSDK,
        "API",
        `[${l.user._clientId}] setLowStreamParameter`,
        {
          requestId: t,
          config: A
        }
      ), s(l);
    } catch (l) {
      K("user", e), K("screen", e), B(e, {
        error: l,
        requestId: t,
        channel: e == null ? void 0 : e.channelInfo,
        appId: e == null ? void 0 : e.appId,
        sessionId: e == null ? void 0 : e.sessionId,
        localPlayerId: e == null ? void 0 : e.gId,
        localUid: e == null ? void 0 : e.uid
      }), i(l);
    }
  });
}
async function ge(e, t, c, s) {
  const { log: i } = c.logger;
  if (e === "video" || e === "screen") {
    const o = c._agoraRTC.createCustomVideoTrack({
      mediaStreamTrack: t
    });
    return i(n.AgoraSDK, "API", "createCustomVideoTrack called", {
      requestId: s
    }), o;
  } else {
    const o = c._agoraRTC.createCustomAudioTrack({
      mediaStreamTrack: t
    });
    return i(n.AgoraSDK, "API", "createCustomAudioTrack called", {
      requestId: s
    }), o;
  }
}
async function Me(e, t, c, s) {
  var l, d;
  const { log: i } = c.logger;
  let o;
  M(e) ? o = (l = c.clients) == null ? void 0 : l.screen : o = (d = c.clients) == null ? void 0 : d.user;
  try {
    await (o == null ? void 0 : o.publish(t)), i(
      n.AgoraSDK,
      "API",
      `[${O(e, c)}] local publish track`,
      { requestId: s, mediaType: e }
    );
  } catch (r) {
    throw r;
  }
}
async function Ie(e, t, c) {
  var i, o, l, d, r;
  const { log: s } = t.logger;
  try {
    if (!((i = t.clients) != null && i.user))
      throw new Error("Local user client not found");
    M(e) ? (await ((l = (o = t.clients) == null ? void 0 : o.screen) == null ? void 0 : l.unpublish(
      t.streams[e]
    )), s(
      n.AgoraSDK,
      "API",
      `[${O(
        "screen",
        t
      )}] local unpublish track`,
      { requestId: c, mediaType: e }
    )) : (await ((r = (d = t.clients) == null ? void 0 : d.user) == null ? void 0 : r.unpublish(
      t.streams[e]
    )), s(
      n.AgoraSDK,
      "API",
      `[${O("video", t)}] local unpublish track`,
      { requestId: c, mediaType: e }
    ));
  } catch (u) {
    throw u;
  }
}
async function _e(e, t, c, s, i, o) {
  const { log: l, error: d } = i.logger;
  let r;
  if (s._video.client ? r = s._video.client : (r = i._agoraRTC.createClient(
    ke
  ), l(n.AgoraSDK, "API", "createClient", {
    requestId: o,
    defaultRemoteClientConfig: ke
  })), r.connectionState === "CONNECTED" || r.connectionState === "CONNECTING" || r.connectionState === "RECONNECTING") {
    l(n.Internals, "subscribe", "Channel has been joined already", {
      requestId: o
    });
    return;
  }
  r.on(
    "connection-state-change",
    (u, h, b) => {
      l(
        n.AgoraSDK,
        "Event",
        `[${E(s)}] connection-state-change`,
        {
          requestId: o,
          currState: u,
          prevState: h,
          reason: b,
          playerId: c
        }
      ), u === "CONNECTED" && t.onRemoteChannelJoin(r);
    }
  ), r.on("user-joined", (u) => {
    const h = (e == null ? void 0 : e.suid) === u.uid ? "screenshare" : "user";
    l(
      n.AgoraSDK,
      "Event",
      `[${E(s)}] user-joined`,
      {
        requestId: o,
        playerId: c,
        remoteUser: u,
        channel: s._video.client.channelName
      }
    ), t.onUserJoined(u, h);
  }), r.on("network-quality", (u) => {
    const h = r.getRemoteNetworkQuality();
    h && t.onNetworkQualityChanged(h);
  }), r.on(
    "user-published",
    async (u, h) => {
      const b = (e == null ? void 0 : e.suid) === u.uid ? "screenshare" : "user";
      switch (l(
        n.AgoraSDK,
        "Event",
        `[${E(s)}] user-published`,
        {
          requestId: o,
          playerId: c,
          remoteUser: u,
          mediaType: h,
          userType: b,
          channel: s._video.client.channelName
        }
      ), h) {
        case "audio":
          b === "user" && t.onPublish(
            u,
            "audio",
            b,
            u.audioTrack
          ), b === "screenshare" && t.onPublish(
            u,
            "sound",
            b,
            u.audioTrack
          );
          break;
        case "video":
          b === "user" && t.onPublish(
            u,
            "video",
            b,
            u.videoTrack
          ), b === "screenshare" && t.onPublish(
            u,
            "screen",
            b,
            u.videoTrack
          );
          break;
      }
      const w = [
        ...Object.keys(s._subscribe).filter(
          (a) => s._subscribe[a]
        )
      ].map((a) => we(a)).find(
        (a) => a.source === b && a.type === h
      );
      if (w) {
        t.onPublishOfSubscribedTrack();
        const a = w;
        try {
          s.streamQuality === "LOW" ? (l(
            n.AgoraSDK,
            "API",
            `[${E(
              s
            )}] setRemoteVideoStreamType`,
            {
              requestId: o,
              playerId: c,
              remoteUser: u,
              streamType: "lowstream(1)"
            }
          ), await r.setRemoteVideoStreamType(u.uid, 1)) : (l(
            n.AgoraSDK,
            "API",
            `[${E(
              s
            )}] setRemoteVideoStreamType`,
            {
              requestId: o,
              playerId: c,
              remoteUser: u,
              streamType: "highstream(0)"
            }
          ), await r.setRemoteVideoStreamType(u.uid, 0)), l(
            n.AgoraSDK,
            "API",
            `[${E(s)}] subscribe`,
            {
              requestId: o,
              playerId: c,
              remoteUser: u,
              mediaType: h,
              userType: b,
              channel: s._video.client.channelName
            }
          ), await r.subscribe(u, h);
          const k = h === "audio" ? u.audioTrack : u.videoTrack;
          t.onSubscribe(k, a);
        } catch (k) {
          d(
            n.AgoraSDK,
            "API",
            `[${E(s)}] subscribe failed`,
            k,
            { requestId: o }
          );
        }
      }
    }
  ), r.on("user-unpublished", (u, h) => {
    const b = (e == null ? void 0 : e.suid) === u.uid ? "screenshare" : "user";
    switch (l(
      n.AgoraSDK,
      "Event",
      `[${E(s)}] user-unpublished`,
      {
        requestId: o,
        playerId: c,
        remoteUser: u,
        mediaType: h,
        userType: b,
        channel: s._video.client.channelName
      }
    ), h) {
      case "audio":
        b === "user" && t.onUnpublish(u, "audio", b), b === "screenshare" && t.onUnpublish(u, "sound", b);
        break;
      case "video":
        b === "user" && t.onUnpublish(u, "video", b), b === "screenshare" && t.onUnpublish(u, "screen", b);
        break;
    }
    let w = [
      ...Object.keys(s._subscribe).filter(
        (a) => s._subscribe[a]
      )
    ].map((a) => we(a)).find(
      (a) => a.source === b && a.type === h
    );
    if (w) {
      const a = w, k = h === "audio" ? u.audioTrack : u.videoTrack;
      t.onUnpublishOfSubscribedTrack(
        k,
        a
      );
    }
  }), r.on("user-left", (u, h) => {
    const b = (e == null ? void 0 : e.suid) === u.uid ? "screenshare" : "user";
    l(
      n.AgoraSDK,
      "Event",
      `[${E(s)}] user-left`,
      {
        requestId: o,
        playerId: c,
        remoteUser: u,
        reason: h,
        userType: b,
        channel: s._video.client.channelName
      }
    ), t.onUserLeft(u, h, b);
  }), r.on("token-privilege-will-expire", async function() {
    l(
      n.AgoraSDK,
      "Event",
      `[${E(s)}] token-privilege-will-expire`,
      {
        requestId: o,
        playerId: c,
        channel: s._video.client.channelName
      }
    ), t.onTokenWillExpire();
  }), r.on("token-privilege-did-expire", async () => {
    l(
      n.AgoraSDK,
      "Event",
      `[${E(s)}] token-privilege-did-expire`,
      {
        requestId: o,
        playerId: c,
        channel: s._video.client.channelName
      }
    ), t.onTokenDidExpire();
  }), r.on(
    "stream-type-changed",
    (u, h) => {
      s._subscribedStreamType = h, l(
        n.AgoraSDK,
        "Event",
        `[${E(s)}] stream-type-changed`,
        {
          requestId: o,
          playerId: c,
          uid: u,
          streamType: h,
          channel: s._video.client.channelName
        }
      );
    }
  );
  try {
    l(n.AgoraSDK, "API", "join attempt", {
      requestId: o,
      playerId: c,
      appId: i.appId,
      channel: e.channel_name,
      token: e.rtc,
      user_Id: i.uid
    }), await r.setClientRole("audience"), Pe(s, i, {
      requestId: o,
      playerId: c,
      channel: e,
      appId: i.appId,
      sessionId: i.sessionId,
      localPlayerId: i.gId,
      localUid: i.uid
    }), Ne(
      o,
      c,
      r,
      s,
      i
    ), await r.join(
      i.appId,
      e.channel_name,
      e.rtc,
      i.uid
    ), ce(s), se(s, i, {
      requestId: o,
      playerId: c,
      channel: e,
      appId: i.appId,
      sessionId: i.sessionId,
      localPlayerId: i.gId,
      localUid: i.uid
    }), l(
      n.InfoMap,
      "subscribe",
      "updated infoMap with new client object",
      { requestId: o }
    ), s._video.client = r, l(
      n.AgoraSDK,
      "API",
      `[${E(s)}] join success`,
      {
        requestId: o,
        playerId: c,
        appId: i.appId,
        channel: e.channel_name,
        token: e.rtc,
        user_Id: i.uid
      }
    );
  } catch (u) {
    throw ce(s), se(s, i, {
      error: u,
      requestId: o,
      playerId: c,
      channel: e.channel_name,
      token: e.rtc,
      appId: i.appId,
      sessionId: i.sessionId,
      localPlayerId: i.gId,
      localUid: i.uid
    }), d(n.Internals, "subscribe", "joining remote user failed", u, {
      requestId: o
    }), u;
  }
}
async function et(e, t, c, s) {
  c.logger;
  try {
    await le(
      e,
      t,
      c.logger,
      s
    );
  } catch (i) {
    throw i;
  }
}
async function tt(e, t, c, s) {
  var i, o, l;
  try {
    const { log: d } = c.logger;
    if (!((i = t == null ? void 0 : t._video) != null && i.client))
      throw Error("Consumer client not initialized");
    await ((l = (o = t == null ? void 0 : t._video) == null ? void 0 : o.client) == null ? void 0 : l.renewToken(e)), d(
      n.AgoraSDK,
      "API",
      `[${E(t)}] renewToken`,
      { requestId: s, token: e }
    );
  } catch (d) {
    throw d;
  }
}
async function it(e, t, c) {
  var i, o, l, d, r, u;
  const { log: s } = t.logger;
  try {
    e && Object.keys(e).length > 0 && await Promise.all(
      Object.keys(e).map(async (h) => {
        await le(
          h,
          e[h],
          t.logger,
          c
        ), De(e[h], t), delete e[h], s(n.InfoMap, "destroy", "Removed player from InfoMap", {
          requestId: c,
          playerId: h
        });
      })
    ), t && (t.streams && await Promise.all(
      Object.keys(t.streams).map(
        async (h) => {
          t.streams[h] = null, s(
            n.AgoraSDK,
            "API",
            `[${O(
              h,
              t
            )}] setting local tracks to null`,
            {
              requestId: c,
              mediaKind: h,
              isLocalTrack: !0
            }
          );
        }
      )
    ), (i = t == null ? void 0 : t.clients) != null && i.user && (await ((l = (o = t == null ? void 0 : t.clients) == null ? void 0 : o.user) == null ? void 0 : l.leave()), s(
      n.AgoraSDK,
      "API",
      `[${O("video", t)}] leave`,
      {
        requestId: c,
        userType: "user",
        isLocalTrack: !0
      }
    ), t.clients.user = null), (d = t == null ? void 0 : t.clients) != null && d.screen && (await ((u = (r = t == null ? void 0 : t.clients) == null ? void 0 : r.screen) == null ? void 0 : u.leave()), s(
      n.AgoraSDK,
      "API",
      `${O("screen", t)}] leave`,
      {
        requestId: c,
        userType: "screen",
        isLocalTrack: !0
      }
    ), t.clients.screen = null), s(
      n.Internals,
      "destroy",
      "removed user and screen clients from internals",
      {
        requestId: c
      }
    ));
  } catch (h) {
    throw h;
  }
}
async function st(e, t, c, s, i) {
  const { log: o, error: l } = t;
  return new Promise(async function(d, r) {
    try {
      if (!s._video.client)
        throw Error("Consumer Client not found");
      if (!$e(e, s)) {
        o(
          n.Internals,
          "subscribe",
          `${e} is not published by remote user, cannot subscribe. Returning false`,
          { requestId: i, playerId: c }
        ), d(!1);
        return;
      }
      if (Y(e)) {
        const u = e === "video" ? "video" : "audio", h = await s._video.client.subscribe(
          s._video.agUser,
          u
        );
        if (s._video.streams[e] = h, s.enabled[e] = !0, o(
          n.AgoraSDK,
          "API",
          `${E(s)}] subscribe`,
          {
            requestId: i,
            playerId: c,
            remoteUser: s._video.agUser,
            mediaType: u,
            userType: "user",
            channel: s._video.client.channelName
          }
        ), u === "video") {
          d(h);
          return;
        } else {
          d(h);
          return;
        }
      }
      if (M(e)) {
        const u = e === "screen" ? "video" : "audio", h = await s._video.client.subscribe(
          s._screenVideo.agUser,
          u
        );
        if (s._screenVideo.streams[e] = h, s.enabled[e] = !0, o(
          n.AgoraSDK,
          "API",
          `${E(s)}] subscribe`,
          {
            requestId: i,
            playerId: c,
            remoteUser: s._video.agUser,
            mediaType: u,
            userType: "screen",
            channel: s._video.client.channelName
          }
        ), u === "video") {
          d(h);
          return;
        } else {
          d(h);
          return;
        }
      }
    } catch (u) {
      l(
        n.AgoraSDK,
        "API",
        `${E(s)}] subscribe failed`,
        u,
        { requestId: i }
      ), r(l);
    }
  });
}
async function ct(e, t, c, s, i) {
  const { log: o, error: l } = t;
  try {
    if (Y(e) && s._video.agUser) {
      const d = e === "video" ? "video" : "audio";
      await s._video.client.unsubscribe(
        s._video.agUser,
        d
      ), s._video.streams[e] = null, s.enabled[e] = !1, o(
        n.AgoraSDK,
        "API",
        `${E(s)}] unsubscribe`,
        {
          requestId: i,
          playerId: c,
          remoteUser: s._video.agUser,
          mediaType: d,
          userType: "user",
          channel: s._video.client.channelName
        }
      );
    }
    if (M(e) && s._screenVideo.agUser) {
      const d = e === "screen" ? "video" : "audio";
      await s._video.client.unsubscribe(
        s._screenVideo.agUser,
        d
      ), s._screenVideo.streams[e] = null, s.enabled[e] = !1, o(
        n.AgoraSDK,
        "API",
        `${E(s)}] unsubscribe`,
        {
          requestId: i,
          playerId: c,
          remoteUser: s._video.agUser,
          mediaType: d,
          userType: "screen",
          channel: s._video.client.channelName
        }
      );
    }
  } catch (d) {
    throw d;
  }
}
async function nt(e, t, c, s, i) {
  var d;
  const { log: o, error: l } = s == null ? void 0 : s.logger;
  try {
    if (!((d = t._video) != null && d.client))
      throw new Error("Remote client does not exist");
    o(
      n.AgoraSDK,
      "API",
      `${E(t)}] setRemoteVideoStreamType `,
      {
        requestId: i,
        playerId: e,
        remoteUid: t._video._remoteUid,
        streamType: c === 1 ? "lowstream(1)" : "highstream(0)"
      }
    ), await t._video.client.setRemoteVideoStreamType(
      t._video._remoteUid,
      c
    );
  } catch (r) {
    throw l(
      n.AgoraSDK,
      "API",
      `${E(
        t
      )}] error occured in setRemoteVideoStreamType`,
      r,
      {
        requestId: i
      }
    ), r;
  }
}
const le = async (e, t, c, s) => {
  var o, l, d, r, u, h, b, A, w, a, k, v, C, N, p, S, m, R, L, j, Q, x, F, V, J, _, H, de, ue, he;
  const { log: i } = c;
  try {
    await ((l = (o = t == null ? void 0 : t._video) == null ? void 0 : o.client) == null ? void 0 : l.leave()), i(
      n.AgoraSDK,
      "API",
      `${E(t)}] leave`,
      { requestId: s, playerId: e, userType: "user" }
    ), (r = (d = t == null ? void 0 : t._video) == null ? void 0 : d.client) == null || r.removeAllListeners(), i(
      n.AgoraSDK,
      "API",
      `${E(t)}] removeAllListeners`,
      {
        requestId: s,
        playerId: e,
        userType: "user"
      }
    ), (b = (h = (u = t == null ? void 0 : t._video) == null ? void 0 : u.streams) == null ? void 0 : h.audio) == null || b.stop(), (a = (w = (A = t == null ? void 0 : t._video) == null ? void 0 : A.streams) == null ? void 0 : w.audio) == null || a.removeAllListeners(), (C = (v = (k = t == null ? void 0 : t._video) == null ? void 0 : k.streams) == null ? void 0 : v.video) == null || C.stop(), (S = (p = (N = t == null ? void 0 : t._video) == null ? void 0 : N.streams) == null ? void 0 : p.video) == null || S.removeAllListeners(), i(
      n.AgoraSDK,
      "API",
      `${E(
        t
      )}] stopped remote tracks and removed listeners`,
      { requestId: s, playerId: e, userType: "user" }
    ), (L = (R = (m = t == null ? void 0 : t._screenVideo) == null ? void 0 : m.streams) == null ? void 0 : R.screen) == null || L.stop(), (x = (Q = (j = t == null ? void 0 : t._screenVideo) == null ? void 0 : j.streams) == null ? void 0 : Q.screen) == null || x.removeAllListeners(), (J = (V = (F = t == null ? void 0 : t._screenVideo) == null ? void 0 : F.streams) == null ? void 0 : V.sound) == null || J.stop(), (de = (H = (_ = t == null ? void 0 : t._screenVideo) == null ? void 0 : _.streams) == null ? void 0 : H.sound) == null || de.removeAllListeners(), i(
      n.AgoraSDK,
      "API",
      `${E(
        t
      )}] stopped remote tracks and removed listeners`,
      { requestId: s, playerId: e, userType: "screen" }
    ), Object.keys((ue = t == null ? void 0 : t._video) == null ? void 0 : ue.streams).map(async (ee) => {
      t._video.streams[ee] = null;
    }), Object.keys((he = t == null ? void 0 : t._screenVideo) == null ? void 0 : he.streams).map((ee) => {
      t._screenVideo.streams[ee] = null;
    });
  } catch (ee) {
    throw "Error while cleaning client and tracks: ".concat(ee);
  }
}, rt = async (e, t, c, s) => {
  var o, l, d, r, u, h, b, A, w, a, k, v, C, N, p, S, m, R, L, j, Q, x, F, V, J, _;
  const { log: i } = t;
  try {
    (d = (l = (o = c == null ? void 0 : c._video) == null ? void 0 : o.streams) == null ? void 0 : l.audio) == null || d.stop(), (h = (u = (r = c == null ? void 0 : c._video) == null ? void 0 : r.streams) == null ? void 0 : u.audio) == null || h.removeAllListeners(), (w = (A = (b = c == null ? void 0 : c._video) == null ? void 0 : b.streams) == null ? void 0 : A.video) == null || w.stop(), (v = (k = (a = c == null ? void 0 : c._video) == null ? void 0 : a.streams) == null ? void 0 : k.video) == null || v.removeAllListeners(), i(
      n.AgoraSDK,
      "API",
      `${E(
        c
      )}] stopped remote tracks and removed listeners`,
      { requestId: s, playerId: e, userType: "user" }
    ), (p = (N = (C = c == null ? void 0 : c._screenVideo) == null ? void 0 : C.streams) == null ? void 0 : N.screen) == null || p.stop(), (R = (m = (S = c == null ? void 0 : c._screenVideo) == null ? void 0 : S.streams) == null ? void 0 : m.screen) == null || R.removeAllListeners(), (Q = (j = (L = c == null ? void 0 : c._screenVideo) == null ? void 0 : L.streams) == null ? void 0 : j.sound) == null || Q.stop(), (V = (F = (x = c == null ? void 0 : c._screenVideo) == null ? void 0 : x.streams) == null ? void 0 : F.sound) == null || V.removeAllListeners(), i(
      n.AgoraSDK,
      "API",
      `${E(
        c
      )}] stopped remote tracks and removed listeners`,
      { requestId: s, playerId: e, userType: "screen" }
    ), Object.keys((J = c == null ? void 0 : c._video) == null ? void 0 : J.streams).map(async (H) => {
      c._video.streams[H] = null;
    }), Object.keys((_ = c == null ? void 0 : c._screenVideo) == null ? void 0 : _.streams).map((H) => {
      c._screenVideo.streams[H] = null;
    });
  } catch (H) {
    throw "Error while stopping and clearing _consumerInfo tracks: ".concat(
      H
    );
  }
}, f = {
  createLocalClientAndJoin: Ze,
  createLocalMediaTrack: ge,
  publishLocalTrack: Me,
  unpublishLocalTrack: Ie,
  switchRemoteVideoStreamType: nt,
  cleanClientAndRemoteTracks: le,
  clearRemoteTracks: rt,
  destroy: it,
  remoteRenewToken: tt,
  unsubscribeRemoteMedia: ct,
  subscribeRemoteMedia: st,
  createRemoteClientJoinAndSubscribe: _e,
  leaveRemoteClient: et
};
class ot {
  constructor() {
    T(this, "_queue");
    this._queue = [];
  }
  enqueue(t, c) {
    this._queue.push(t);
    const { name: s, requestId: i, queueId: o, logger: l, ts: d } = c;
    l.log(n.Queue, "Enqueue", "Adding to the queue", {
      requestId: i,
      queueId: o,
      name: s,
      size: this.size,
      ts: d
    });
  }
  dequeue() {
    return this._queue.shift();
  }
  get size() {
    return this._queue.length;
  }
  filter(t) {
    return this._queue;
  }
}
const lt = () => new oe({ queueClass: ot, concurrency: 1 });
class dt {
  constructor() {
    T(this, "_queue");
    this._queue = [];
  }
  enqueue(t, c) {
    this._queue.push(t);
    const { name: s, requestId: i, queueId: o, logger: l, ts: d } = c;
    l.log(n.Queue, "Enqueue", "Adding to the queue", {
      requestId: i,
      queueId: o,
      name: s,
      size: this.size,
      ts: d
    });
  }
  dequeue() {
    return this._queue.shift();
  }
  get size() {
    return this._queue.length;
  }
  filter(t) {
    return this._queue;
  }
}
const ut = (e) => new oe({
  queueClass: dt,
  concurrency: 1,
  autoStart: e.autoStart
});
class ht {
  constructor() {
    T(this, "_queue");
    T(this, "_consumerInfoMap");
    T(this, "_playerId");
    this._queue = [];
  }
  enqueue(t, c) {
    this._queue.push(t);
    const { name: s, requestId: i, queueId: o, logger: l, ts: d, playerId: r, consumerInfoMap: u } = c;
    l.log(n.Queue, "Enqueue", "Adding to the queue", {
      requestId: i,
      playerId: r,
      queueId: o,
      name: s,
      size: this.size,
      ts: d
    }), s == "subscribe" && (this._consumerInfoMap = u, this._playerId = r);
  }
  dequeue() {
    return this._queue.shift();
  }
  get size() {
    return this._queue.length;
  }
  filter(t) {
    return this._queue;
  }
}
const at = (e) => new oe({
  queueClass: ht,
  concurrency: 1,
  ...e
}), z = (e, t, c) => {
  var s;
  try {
    const i = "Internal", o = Date.now();
    let l = 0;
    const { log: d, error: r, warn: u } = c.logger;
    d(n.Queue, "Set", `${t.name}`, {
      ...t,
      queueId: i,
      size: c._queue.size
    });
    const h = () => [5, 10, 20, 45, 90].map(
      (k) => setTimeout(() => {
        u(n.Alert, "Queue", k + "sec", {
          ...t,
          queueId: i,
          size: c._queue.size
        });
      }, k * 1e3)
    );
    let b;
    const A = () => (l = Date.now(), d(n.Queue, "Dequeue", "Removing from the queue", {
      ...t,
      queueId: i,
      size: c._queue.size,
      ts: l,
      tsduration: l - o
    }), b = h(), e());
    (s = c._queue) == null || s.add(A, {
      ...t,
      queueId: i,
      ts: o,
      logger: c.logger
    }).finally(() => {
      const w = Date.now();
      d(
        n.Queue,
        "Dequeue",
        "Dequeue and processed(Finally block)",
        {
          queueId: i,
          ...t,
          tsFinally: w,
          tsdurationEnqueueProcess: w - o,
          tsdurationDequeueProcess: w - l
        }
      ), b.forEach((a) => {
        clearTimeout(a);
      });
    }).catch((w) => {
      t.name === "start" && fe(c), r(
        n.Queue,
        "Enqueue",
        `execute-${t.name}-error`,
        w,
        {
          requestId: t.requestId
        }
      );
    });
  } catch (i) {
    throw i;
  }
}, I = (e, t, c, s) => {
  var i;
  try {
    const o = "Consumer", l = Date.now();
    let d = 0;
    const { log: r, error: u, warn: h } = s.logger;
    r(n.Queue, "Set", `${t.name}`, {
      ...t,
      queueId: o,
      size: c._queue.size
    });
    const b = () => [5, 10, 20, 45, 90].map(
      (v) => setTimeout(() => {
        h(n.Alert, "Queue", v + "sec", {
          ...t,
          queueId: o,
          size: c._queue.size
        });
      }, v * 1e3)
    );
    let A;
    const w = () => (d = Date.now(), r(n.Queue, "Dequeue", "Removing from the queue", {
      ...t,
      queueId: o,
      size: c._queue.size,
      ts: d,
      tsduration: d - l
    }), A = b(), e());
    (i = c._queue) == null || i.add(w, {
      ...t,
      queueId: o,
      ts: l,
      logger: s.logger
    }).finally(() => {
      const a = Date.now();
      r(
        n.Queue,
        "Dequeue",
        "Dequeue and processed(Finally block)",
        {
          queueId: o,
          ...t,
          tsFinally: a,
          tsdurationEnqueueProcess: a - l,
          tsdurationDequeueProcess: a - d
        }
      ), A.forEach((k) => {
        clearTimeout(k);
      });
    }).catch((a) => {
      u(
        n.Queue,
        "Enqueue",
        `execute-${t.name}-error`,
        a,
        {
          requestId: t.requestId
        }
      );
    });
  } catch (o) {
    throw o;
  }
}, q = (e, t, c, s) => {
  var i;
  try {
    const o = "ConsumerPlayer", l = Date.now();
    let d = 0;
    const { log: r, error: u, warn: h } = s.logger, { playerId: b } = t;
    r(n.Queue, "Set", `${t.name}`, {
      ...t,
      queueId: o,
      size: c._playerQueues[b].size
    });
    const A = () => [5, 10, 20, 45, 90].map(
      (C) => setTimeout(() => {
        h(n.Alert, "Queue", C + "sec", {
          ...t,
          queueId: o,
          size: c._playerQueues[b].size
        });
      }, C * 1e3)
    );
    let w;
    const a = () => {
      let k = Date.now();
      return r(n.Queue, "Dequeue", "Removing from the queue", {
        ...t,
        queueId: o,
        size: c._playerQueues[b].size,
        ts: k,
        tsduration: k - l
      }), w = A(), e();
    };
    (i = c._playerQueues[b]) == null || i.add(a, {
      ...t,
      queueId: o,
      ts: l,
      logger: s.logger,
      consumerInfoMap: c
    }).finally(() => {
      const k = Date.now();
      r(
        n.Queue,
        "Dequeue",
        "Dequeue and processed(Finally block)",
        {
          queueId: o,
          ...t,
          tsFinally: k,
          tsdurationEnqueueProcess: k - l,
          tsdurationDequeueProcess: k - d
        }
      ), w.forEach((v) => {
        clearTimeout(v);
      });
    }).catch((k) => {
      u(
        n.Queue,
        "Enqueue",
        `execute-${t.name}-error`,
        k,
        {
          requestId: t.requestId
        }
      );
    });
  } catch (o) {
    throw o;
  }
}, bt = (e) => {
  e._queue.start(), Object.keys(e._playerQueues).map((t) => {
    e._playerQueues[t].isPaused && e._playerQueues[t].start();
  });
}, wt = (e) => {
  e._queue.clear(), Object.keys(e._playerQueues).map((t) => {
    e._playerQueues[t].clear(), delete e._playerQueues[t];
  });
}, St = (e, t, c) => {
  var i;
  const { log: s } = c.logger;
  if (s(n.NetworkRealTime, "S2C", "S2C socket event received ", {
    requestId: e.rqid,
    socketPayload: e
  }), c.sts > e.sts) {
    s(n.NetworkRealTime, "S2C", "userDetails sts > message.sts", {
      requestId: e.rqid,
      socketPayload: e,
      userDetailsSts: c.sts
    });
    return;
  }
  if (!((i = e == null ? void 0 : e.data) != null && i.dstid)) {
    s(n.NetworkRealTime, "S2C", "S2C socket channel dstId ", {
      requestId: e.rqid,
      socketPayload: e
    });
    return;
  }
  if (e.etyp === "RESPONSE") {
    const o = e.data.act;
    if (o === "DISALLOW") {
      if (e.data.srcid === c.gId)
        return;
      pt(
        e.data,
        e.rqid,
        e.ets,
        t,
        c
      );
    }
    if (o === "RTC_TOKEN") {
      if (!e.data.channel) {
        s(
          n.NetworkRealTime,
          "S2C",
          "S2C socket channel details missing ",
          {
            requestId: e.rqid,
            socketPayload: e
          }
        );
        return;
      }
      vt(
        e.data,
        e.rqid,
        t,
        c
      );
    }
    o === "SUBSCRIBE" && mt(
      e.data,
      e.rqid,
      e.ets,
      t,
      c
    );
  }
  if (e.etyp === "SYNC") {
    const o = e.data.act;
    o === "VIDEO:QUALITY" && At(
      e.data,
      e.rqid,
      t,
      c
    ), o === "UNSUBSCRIBE" && yt(
      e.data,
      e.rqid,
      t,
      c
    );
  }
}, kt = (e, t, c) => {
  t || Tt(e, c);
}, pt = (e, t, c, s, i) => {
  const { log: o, error: l, warn: d } = i == null ? void 0 : i.logger, r = e == null ? void 0 : e.srcid;
  if (!r)
    return;
  q(
    async () => {
      var h, b, A, w, a, k, v, C, N, p, S, m;
      o(n.NetworkRealTime, "S2C", "disallow response received", {
        requestId: t,
        receivedTimestamp: c,
        data: e
      });
      try {
        if (s[r]) {
          if (o(n.Internals, "disallow", "user found in the infomap", {
            requestId: t
          }), o(
            n.Internals,
            "disallow",
            "check if S2C disallow_response can be processed by checking the current client and consumerInfo status",
            { requestId: t }
          ), o(
            n.Internals,
            "disallow",
            `current client connection status and conusmerInfo status for playerId ${r} is`,
            {
              requestId: t,
              clientStatus: (b = (h = s[r]._video) == null ? void 0 : h.client) == null ? void 0 : b.connectionState,
              consumerStatus: s[r].state
            }
          ), ((a = (w = (A = s[r]) == null ? void 0 : A._video) == null ? void 0 : w.client) == null ? void 0 : a.connectionState) == "DISCONNECTED" || ((C = (v = (k = s[r]) == null ? void 0 : k._video) == null ? void 0 : v.client) == null ? void 0 : C.connectionState) == "DISCONNECTING") {
            o(
              n.Internals,
              "disallow",
              "do nothing as client is already disconnected/disconnecting or player is not allwowed",
              {
                requestId: t,
                playerId: r
              }
            );
            return;
          }
          if (s[r].state === U.NotAllowed) {
            o(n.Internals, "disallow", "Duplicate Event: DISALLOW", {
              requestId: t,
              srcId: r,
              received_timestamp_disallow: c,
              present_timestamp_disallow: s[r]._timeStamp.disallowEventTimestamp
            });
            return;
          }
          s[r]._timeStamp.subscribeEventTimestamp > c && o(
            n.Internals,
            "disallow",
            "Out of order event received - DISALLOW",
            {
              requestId: t,
              received_timestamp_disallow: c,
              present_timestamp_subscribe: s[r]._timeStamp.subscribeEventTimestamp,
              present_timestamp_disallow: s[r]._timeStamp.disallowEventTimestamp
            }
          ), o(n.Internals, "disallow", "processing disallow_response", {
            requestId: t
          }), o(n.Internals, "disallow", "disallow response details", {
            requestId: t,
            playerId: r,
            message: e,
            channel: (p = (N = s[r]._video) == null ? void 0 : N.client) == null ? void 0 : p.channelName,
            connectionState: (m = (S = s[r]._video) == null ? void 0 : S.client) == null ? void 0 : m.connectionState
          });
          const R = Object.assign(
            {},
            s[r]._subscribe
          );
          await f.cleanClientAndRemoteTracks(
            r,
            s[r],
            i.logger,
            t
          ), o(
            n.InfoMap,
            "disallow",
            "disallow response removed client and Remote Tracks",
            { requestId: t, playerId: r }
          ), re(s[r]), s[r] = Ce(
            U.NotAllowed
          ), $(s[r], i, {
            requestId: t,
            playerId: r,
            sessionId: i.sessionId,
            localPlayerId: i.gId,
            localUid: i.uid,
            event: {
              name: "on S2C disallow_response",
              data: e
            }
          }), o(
            n.InfoMap,
            "disallow",
            `updated ConsumerState to ${U.NotAllowed}`,
            { requestId: t, playerId: r }
          ), s[r]._subscribe = R, s[r]._timeStamp.disallowEventTimestamp = c, o(n.InfoMap, "disallow", "Restored previous subscribes", {
            requestId: t,
            playerId: r,
            previousSubscribes: R
          }), y(s, l);
        } else
          o(n.Internals, "disallow", "user NOT found in the infomap", {
            requestId: t
          });
      } catch (R) {
        throw l(
          n.Internals,
          "disallow",
          "error occured while processing S2C disallowResponse event",
          R,
          { requestId: t }
        ), R;
      }
    },
    {
      name: "S2C-disallowResponse",
      requestId: t,
      playerId: r
    },
    s,
    i
  );
}, vt = (e, t, c, s) => {
  const { log: i, error: o } = s == null ? void 0 : s.logger, l = e.dstid, d = e.srcid;
  l === d ? (async () => {
    i(n.NetworkRealTime, "S2C", "token_response received (local)", {
      requestId: t,
      message: e
    });
    try {
      s.disconnectedCallbacks[t] ? (i(
        n.Internals,
        "produceStart",
        `rtc-token: disconnected callback handler:, ${s.clients.user.connectionState}`,
        {
          requestId: t
        }
      ), s.disconnectedCallbacks[t](
        e.channel
      )) : i(
        n.Internals,
        "produceStart",
        "rtc-token: disconnected callback handler does not exists for this reqID",
        {
          requestId: t
        }
      );
    } catch (h) {
      o(
        n.Internals,
        "subscribe",
        "error occured while processing S2C rtcTokenResponse event (local)",
        h,
        { requestId: t }
      );
    }
  })() : q(
    async () => {
      i(n.NetworkRealTime, "S2C", "token_response received (remote)", {
        requestId: t,
        message: e
      });
      try {
        if (c[l])
          if (i(
            n.Internals,
            "subscribe",
            "rtc-token: user found in the infomap",
            {
              requestId: t
            }
          ), c[l]._video.client) {
            if (i(
              n.Internals,
              "subscribe",
              "rtc-token: current client connection status is",
              {
                requestId: t,
                connectionStatus: c[l]._video.client.connectionState
              }
            ), c[l]._video.client.connectionState === "CONNECTED")
              await f.remoteRenewToken(
                e.channel.rtc,
                c[e.dstid],
                s,
                t
              ), i(
                n.Internals,
                "subscribe",
                "rtc-token: token renewed successfully",
                { requestId: t }
              );
            else if (c[l]._video.client.connectionState === "DISCONNECTED")
              try {
                await c[l]._video.client.setClientRole(
                  "audience"
                ), i(n.AgoraSDK, "API", "join attempt", {
                  requestId: t,
                  playerId: l,
                  appId: s.appId,
                  sessionId: s.sessionId,
                  localPlayerId: s.gId,
                  localUid: s.uid,
                  channel: e
                }), Pe(c[l], s, {
                  requestId: t,
                  playerId: l,
                  channel: e,
                  appId: s.appId,
                  sessionId: s.sessionId,
                  localPlayerId: s.gId,
                  localUid: s.uid,
                  event: {
                    name: "on S2C rtc_token_response",
                    data: e
                  }
                }), Ne(
                  t,
                  l,
                  c[l]._video.client,
                  c[l],
                  s
                ), await c[l]._video.client.join(
                  s.appId,
                  e.channel.channel_name,
                  e.channel.rtc,
                  s.uid
                ), ce(c[l]), se(c[l], s, {
                  requestId: t,
                  playerId: l,
                  channel: e,
                  appId: s.appId,
                  sessionId: s.sessionId,
                  localPlayerId: s.gId,
                  localUid: s.uid,
                  event: {
                    name: "on S2C rtc_token_response",
                    data: e
                  }
                }), i(
                  n.Internals,
                  "subscribe",
                  "rtc-token: joined channel again successfully",
                  { requestId: t }
                );
              } catch (h) {
                throw ce(c[l]), se(c[l], s, {
                  error: h,
                  requestId: t,
                  playerId: l,
                  channel: e,
                  appId: s.appId,
                  sessionId: s.sessionId,
                  localPlayerId: s.gId,
                  localUid: s.uid,
                  event: {
                    name: "on S2C rtc_token_response",
                    data: e
                  }
                }), h;
              }
          } else
            throw Error(
              "Error: _video client not found to renew or rejoin on rtc_token response"
            );
        else
          i(
            n.Internals,
            "subscribe",
            "rtc-token: user NOT found in the infomap",
            {
              requestId: t
            }
          );
      } catch (h) {
        throw o(
          n.Internals,
          "subscribe",
          "error occured while processing S2C rtcTokenResponse event (remote)",
          h,
          { requestId: t }
        ), h;
      }
    },
    {
      name: "S2C-rtcTokenResponse",
      requestId: t,
      playerId: l
    },
    c,
    s
  );
}, mt = (e, t, c, s, i) => {
  const { log: o, error: l, warn: d } = i == null ? void 0 : i.logger, r = e == null ? void 0 : e.dstid;
  q(
    async () => {
      var h, b, A, w, a, k, v, C;
      o(
        n.NetworkRealTime,
        "S2C",
        `subscribe_response received for ${e.dstid}`,
        {
          requestId: t,
          receivedTimestamp: c,
          data: e
        }
      );
      try {
        if (e.channel && s[r]) {
          if (o(n.Internals, "subscribe", "user found in the infomap", {
            requestId: t,
            playerId: r
          }), o(
            n.Internals,
            "subscribe",
            "current client connection status is",
            {
              requestId: t,
              connectionStatus: (b = (h = s[r]._video) == null ? void 0 : h.client) == null ? void 0 : b.connectionState
            }
          ), ((w = (A = s[r]._video) == null ? void 0 : A.client) == null ? void 0 : w.connectionState) === "CONNECTED" || ((k = (a = s[r]._video) == null ? void 0 : a.client) == null ? void 0 : k.connectionState) === "CONNECTING" || ((C = (v = s[r]._video) == null ? void 0 : v.client) == null ? void 0 : C.connectionState) === "RECONNECTING") {
            o(
              n.Internals,
              "subscribe",
              "Duplicate Event: do nothing as channel is already joined or currently joining is in progress",
              { requestId: t, playerId: r }
            );
            return;
          }
          s[r]._timeStamp.disallowEventTimestamp > c && o(
            n.Internals,
            "subscribe",
            "Out of order event received - SUBSCRIBE",
            {
              requestId: t,
              received_timestamp_subscribe: c,
              present_timestamp_disallow: s[r]._timeStamp.disallowEventTimestamp,
              present_timestamp_subscribe: s[r]._timeStamp.subscribeEventTimestamp
            }
          ), s[r].state = U.ConnectingToSFU, $(s[r], i, {
            requestId: t,
            playerId: r,
            sessionId: i.sessionId,
            localPlayerId: i.gId,
            localUid: i.uid,
            event: {
              name: "on S2C subscribe_response",
              data: e
            }
          }), o(
            n.InfoMap,
            "subscribe",
            "set ConsumerState to ConnectingToSFU",
            { requestId: t, playerId: r }
          ), s[r]._timeStamp.subscribeEventTimestamp = c, s[r]._video._remoteUid = e.channel.muid, s[r]._screenVideo._remoteUid = e.channel.suid, o(n.InfoMap, "subscribe", "store remote user agora uids", {
            requestId: t,
            _userUid: s[r]._video._remoteUid,
            _screenUid: s[r]._screenVideo._remoteUid
          });
          const N = {
            onRemoteChannelJoin: (p) => {
              s[r].state = U.Connected, $(
                s[r],
                i,
                {
                  requestId: t,
                  playerId: r,
                  sessionId: i.sessionId,
                  localPlayerId: i.gId,
                  localUid: i.uid,
                  event: {
                    name: "rtc connection-state-change currState is CONNECTED",
                    data: e
                  }
                }
              ), o(
                n.InfoMap,
                "subscribe",
                "set ConsumerState to Connected (channel join success)",
                { requestId: t, playerId: r }
              ), s[r]._video.client = p, o(
                n.InfoMap,
                "subscribe",
                "added client to infomap (channel join success)",
                { requestId: t, playerId: r }
              ), y(s, l);
            },
            onUserJoined: (p, S) => {
              s[r].state = U.Connected, $(
                s[r],
                i,
                {
                  requestId: t,
                  playerId: r,
                  sessionId: i.sessionId,
                  localPlayerId: i.gId,
                  localUid: i.uid,
                  event: {
                    name: "rtc user-joined",
                    source: S,
                    data: e
                  }
                }
              ), S === "screenshare" ? s[r]._screenVideo.agUser = p : s[r]._video.agUser = p, o(
                n.InfoMap,
                "subscribe",
                "set ConsumerState to Connected (remote user join)",
                { requestId: t, playerId: r }
              ), y(s, l);
            },
            onNetworkQualityChanged: (p) => {
              var S, m, R, L, j, Q;
              for (const x in p)
                parseInt(x) === e.channel.suid ? (s[r].consumerScores.sound = W((S = p[x]) == null ? void 0 : S.uplinkNetworkQuality), s[r].producerScores.sound = W((m = p[x]) == null ? void 0 : m.downlinkNetworkQuality), s[r].consumerScores.screen = W((R = p[x]) == null ? void 0 : R.uplinkNetworkQuality), s[r].producerScores.screen = W((L = p[x]) == null ? void 0 : L.downlinkNetworkQuality)) : parseInt(x) === e.channel.muid && (s[r].consumerScores.audio = W((j = p[x]) == null ? void 0 : j.uplinkNetworkQuality), s[r].producerScores.audio = W((Q = p[x]) == null ? void 0 : Q.downlinkNetworkQuality), s[r].consumerScores.video = W(p[x].uplinkNetworkQuality), s[r].producerScores.video = W(p[x].downlinkNetworkQuality));
            },
            onPublish: (p, S, m, R) => {
              o(
                n.InfoMap,
                "subscribe",
                "remote User stored in InfoMap (remote user publish)",
                { requestId: t, playerId: r, source: m, remoteUserRtc: p }
              ), S === "video" && (s[r].videoEnabled = !0), S === "audio" && (s[r]._audioEnabled = !0, s[r].volume = 1), S === "screen" && (s[r]._screenVideoEnabled = !0), S === "sound" && (s[r]._screenAudioEnabled = !0, s[r].screenVolume = 1), o(
                n.InfoMap,
                "subscribe",
                "updated videoEnabled,_audioEnabled,_screenVideoEnabled, _screenAudioEnabled (remote user publish)",
                { requestId: t, info: s[r] }
              ), y(s, l);
            },
            onPublishOfSubscribedTrack: () => {
              s[r].state = U.Consuming, $(
                s[r],
                i,
                {
                  requestId: t,
                  playerId: r,
                  sessionId: i.sessionId,
                  localPlayerId: i.gId,
                  localUid: i.uid,
                  event: {
                    name: "rtc on user-published of subscribed track",
                    data: e
                  }
                }
              ), o(
                n.InfoMap,
                "subscribe",
                "set ConsumerState to Consuming (onPublishOfSubscribedTrack)",
                { requestId: t, playerId: r }
              );
            },
            onUnpublish: (p, S, m) => {
              S === "video" && (s[r].videoEnabled = !1), S === "audio" && (s[r]._audioEnabled = !1), S === "screen" && (s[r]._screenVideoEnabled = !1), S === "sound" && (s[r]._screenAudioEnabled = !1), o(
                n.InfoMap,
                "subscribe",
                "updated videoEnabled,_audioEnabled,_screenVideoEnabled, _screenAudioEnabled (remote user unpublish)",
                { requestId: t, info: s[r] }
              ), y(s, l);
            },
            onSubscribe: (p, S) => {
              if (s[r].state = U.Connected, $(
                s[r],
                i,
                {
                  requestId: t,
                  playerId: r,
                  sessionId: i.sessionId,
                  localPlayerId: i.gId,
                  localUid: i.uid,
                  event: {
                    name: "rtc user-published + rtc subscribe",
                    data: e
                  }
                }
              ), o(
                n.InfoMap,
                "subscribe",
                "set ConsumerState to Connected (subscribe success)",
                { requestId: t, playerId: r, trackInfo: S }
              ), S.source === "screenshare" ? s[r]._screenVideo.streams[S.kind] = p : s[r]._video.streams[S.kind] = p, o(
                n.InfoMap,
                "subscribe",
                "remote User video tracks stored in InfoMap (remote user publish)",
                { requestId: t, playerId: r, trackInfo: S }
              ), S.kind === "audio") {
                const m = p;
                m == null || m.setVolume(100), o(
                  n.AgoraSDK,
                  "API",
                  `${E(
                    s[r]
                  )}] setVolume for ${S.kind} (remote user publish)`,
                  { requestId: t, playerId: r, trackInfo: S, volume: 100 }
                );
              }
              if (S.kind === "sound") {
                const m = p;
                m == null || m.setVolume(100), o(
                  n.AgoraSDK,
                  "API",
                  `${E(
                    s[r]
                  )}] setVolume for ${S.kind} (remote user publish)`,
                  { requestId: t, playerId: r, trackInfo: S, volume: 100 }
                );
              }
              o(
                n.InfoMap,
                "subscribe",
                "remote User audio tracks stored in InfoMap (remote user publish)",
                { requestId: t, playerId: r, trackInfo: S }
              ), s[r].enabled[S.kind] = !0, o(
                n.InfoMap,
                "subscribe",
                `enabled[${S.kind}] set to true for the player (remote user publish)`,
                { requestId: t, playerId: r, trackInfo: S }
              ), y(s, l);
            },
            onUnpublishOfSubscribedTrack: (p, S) => {
              var m, R, L, j;
              S.source === "screenshare" ? ((m = s[r]._screenVideo.streams[S.kind]) == null || m.stop(), (R = s[r]._screenVideo.streams[S.kind]) == null || R.off(), s[r]._screenVideo.streams[S.kind] = null) : ((L = s[r]._video.streams[S.kind]) == null || L.stop(), (j = s[r]._video.streams[S.kind]) == null || j.off(), s[r]._video.streams[S.kind] = null), o(
                n.AgoraSDK,
                "API",
                `${E(
                  s[r]
                )}] Close streams and reset (onUnpublishOfSubscribedTrack)`,
                { requestId: t, playerId: r, trackInfo: S }
              ), s[r].enabled[S.kind] = !1, o(
                n.InfoMap,
                "subscribe",
                `set enabled[${S.kind}] to false (onUnpublishOfSubscribedTrack)`,
                { requestId: t, playerId: r, trackInfo: S }
              ), Object.keys(s[r].enabled).find(
                (Q) => s[r].enabled[Q]
              ) || (s[r].state = U.Connected, $(
                s[r],
                i,
                {
                  requestId: t,
                  playerId: r,
                  sessionId: i.sessionId,
                  localPlayerId: i.gId,
                  localUid: i.uid,
                  event: {
                    name: "rtc on user-unpublished of all subscribed tracks",
                    channel: e
                  }
                }
              ), o(
                n.InfoMap,
                "subscribe",
                "set ConsumerState to Connected (onUnpublishOfSubscribedTrack)",
                { requestId: t, playerId: r }
              )), y(s, l);
            },
            onUserLeft: (p, S, m) => {
              s[r] && (o(
                n.InfoMap,
                "subscribe",
                "onUserLeft callback triggered (onUserLeft)",
                { requestId: t, playerId: r, user: p, source: m, reason: S }
              ), m === "user" && (s[r].state = U.ProducerDisconnected, $(
                s[r],
                i,
                {
                  requestId: t,
                  playerId: r,
                  sessionId: i.sessionId,
                  localPlayerId: i.gId,
                  localUid: i.uid,
                  event: {
                    name: "rtc on user-left",
                    reason: S,
                    source: m,
                    data: e
                  }
                }
              ), o(
                n.InfoMap,
                "subscribe",
                "set ConsumerState to ProducerDisconnected (onUserLeft)",
                { requestId: t, playerId: r, user: p, source: m, reason: S }
              ), y(s, l)));
            },
            onTokenWillExpire: () => {
              const p = P(5);
              try {
                o(
                  n.Internals,
                  "subscribe",
                  `[${p}] (onTokenWillExpire) event happened`,
                  { requestId: t, playerId: r }
                ), G(i, p, "RTC_TOKEN", "REQUEST", {
                  channel: {
                    channel_name: e.channel.channel_name
                  },
                  dstid: r
                }), o(
                  n.Internals,
                  "subscribe",
                  `[${p}]  c2s event rtc_token_request to player queued`,
                  {
                    requestId: t,
                    playerId: r
                  }
                );
              } catch (S) {
                l(
                  n.Internals,
                  "subscribe",
                  `[${p}] (onTokenWillExpire) callback execution failed`,
                  S,
                  {
                    requestId: t
                  }
                );
              }
            },
            onTokenDidExpire: async () => {
              const p = P(5);
              try {
                o(
                  n.Internals,
                  "subscribe",
                  `[${p}] (onTokenDidExpire) event happened`,
                  { requestId: t, playerId: r }
                ), await f.clearRemoteTracks(
                  r,
                  i.logger,
                  s[r],
                  t
                ), o(
                  n.Internals,
                  "subscribe",
                  `[${p}] clearing RemoteTracks (onTokenDidExpire)`,
                  { requestId: t, playerId: r }
                ), s[r].state = U.WaitingForSFUAddress, $(
                  s[r],
                  i,
                  {
                    requestId: t,
                    playerId: r,
                    sessionId: i.sessionId,
                    localPlayerId: i.gId,
                    localUid: i.uid,
                    event: {
                      name: "rtc on token-privilege-did-expire",
                      data: e
                    }
                  }
                ), y(s, l), o(
                  n.InfoMap,
                  "subscribe",
                  `[${p}]  ConsumerStateset to WaitingForSFUAddress (onTokenDidExpire)`,
                  { requestId: t, playerId: r }
                ), G(i, p, "RTC_TOKEN", "REQUEST", {
                  channel: {
                    channel_name: e.channel.channel_name
                  },
                  dstid: r
                }), o(
                  n.Internals,
                  "subscribe",
                  `[${p}]  c2s event rtc_token_request to player queued`,
                  {
                    requestId: t,
                    playerId: r
                  }
                );
              } catch (S) {
                l(
                  n.Internals,
                  "subscribe",
                  "(onTokenDidExpire) callback execution failed",
                  S,
                  {
                    requestId: t
                  }
                );
              }
            }
          };
          try {
            await f.createRemoteClientJoinAndSubscribe(
              e.channel,
              N,
              r,
              s[r],
              i,
              t
            ), y(s, l);
          } catch (p) {
            throw l(
              n.Internals,
              "subscribe",
              "create remote client join and subsribed failed",
              p,
              { requestId: t }
            ), p;
          }
        } else
          o(n.Internals, "subscribe", "user NOT found in the infomap", {
            requestId: t,
            playerId: r
          });
      } catch (N) {
        throw l(
          n.Internals,
          "subscribe",
          "error occured while processing S2C subscribeResponse event",
          N,
          { requestId: t }
        ), N;
      }
    },
    {
      name: "S2C-subscribeResponse",
      requestId: t,
      playerId: r
    },
    s,
    i
  );
}, At = (e, t, c, s) => {
  const { log: i, error: o } = s == null ? void 0 : s.logger;
  i(n.NetworkRealTime, "S2C", "video:quality_response received", {
    requestId: t,
    message: e
  }), z(
    async () => {
      var d;
      try {
        if (!(e.quality && (e.quality === "HIGH" || e.quality === "MID" || e.quality === "LOW"))) {
          i(
            n.Internals,
            "setQuality",
            "Received incorrect quality from server",
            {
              requestId: t,
              receivedQuality: e.quality
            }
          );
          return;
        }
        if (s.targetQuality === e.quality) {
          i(
            n.Internals,
            "setQuality",
            "Duplicate Event: Requested quality is the same as the current quality",
            {
              requestId: t,
              data: e,
              currentQuality: s.targetQuality
            }
          );
          return;
        }
        const r = (d = s.streams.video) == null ? void 0 : d.getMediaStreamTrack();
        if (i(
          n.Internals,
          "setQuality",
          "Requested quality is different from the current quality",
          { requestId: t, data: e, currentQuality: s.targetQuality }
        ), !r) {
          i(
            n.Internals,
            "setQuality",
            "Cannot set quality, as no video track is present",
            { requestId: t }
          ), s.targetQuality = e.quality, y(s, o);
          return;
        }
        switch (i(
          n.Internals,
          "setQuality",
          `Setting quality for ${e.quality}`,
          { requestId: t }
        ), e.quality) {
          case "LOW": {
            try {
              const { height: u, bitrate: h, frameRate: b } = X.LOW;
              await ne(
                u,
                h,
                b,
                s,
                t
              );
            } catch (u) {
              o(
                n.Internals,
                "setQuality",
                "failed to set low quality",
                u,
                { requestId: t }
              );
            }
            break;
          }
          case "MID": {
            try {
              const { height: u, bitrate: h, frameRate: b } = X.MID;
              await ne(
                u,
                h,
                b,
                s,
                t
              );
            } catch (u) {
              o(
                n.Internals,
                "setQuality",
                "failed to set mid quality",
                u,
                { requestId: t }
              );
            }
            break;
          }
          case "HIGH": {
            try {
              const { height: u, bitrate: h, frameRate: b } = X.HIGH;
              await ne(
                u,
                h,
                b,
                s,
                t
              );
            } catch (u) {
              o(
                n.Internals,
                "setQuality",
                "failed to set high quality",
                u,
                { requestId: t }
              );
            }
            break;
          }
          default:
            o(
              n.Internals,
              "setQuality",
              "Invalid quality request from server",
              new Error("Invalid quality request from server"),
              {
                requestId: t,
                currentQuality: s.targetQuality,
                requestedQuality: e.quality
              }
            );
            break;
        }
        s.targetQuality = e.quality, y(s, o);
      } catch (r) {
        throw o(n.Internals, "setQuality", "failed to set low quality", r, {
          requestId: t
        }), r;
      }
    },
    {
      name: "S2C-videoQuality",
      requestId: t
    },
    s
  );
}, Tt = (e, t) => {
  const { log: c } = t.logger;
  c(
    n.NetworkRealTime,
    "connection",
    "Presence Event: onPresenceMemberLeave: event happened...no actions performed",
    { playerId: e }
  );
}, yt = (e, t, c, s) => {
  e.srcid && s.logger.log(
    n.NetworkRealTime,
    "S2C",
    "unsubscribe_sync event received...no actions performed",
    { requestId: t, data: e }
  );
};
function Et(e, t, c, s, i, o) {
  const { log: l, error: d } = i.logger;
  return new Promise((r, u) => {
    var A;
    const h = Boolean(c);
    l(n.Internals, "start", "socket: check if socket exist or not", {
      requestId: o,
      enablePresenceSubscription: h ? "presence enabled" : "presence disabled"
    });
    const b = (w) => {
    };
    if ((A = i._network) != null && A.ably ? l(n.Internals, "start", "socket exists - already connected", {
      requestId: o
    }) : (i._network = new Fe(
      e,
      b,
      h,
      i.logger
    ), l(
      n.Internals,
      "start",
      "created a socket instance and attached to agora internals",
      { requestId: o }
    )), i._network.privateChannel.subscribe(
      "event",
      (w) => {
        t(w.data);
      },
      (w) => {
        w ? l(
          n.NetworkRealTime,
          "connection",
          "S2C channel subscribe fail",
          w,
          { requestId: o }
        ) : l(
          n.NetworkRealTime,
          "connection",
          "S2C channel subscribe success",
          { requestId: o }
        );
      }
    ), i._network.presenceChannel.presence.enter(
      {},
      (w) => {
        w ? d(
          n.NetworkRealTime,
          "connection",
          "presence channel enter fail",
          w,
          { requestId: o }
        ) : l(
          n.NetworkRealTime,
          "connection",
          "presence channel enter success",
          { requestId: o }
        );
      }
    ), h) {
      const w = (a, k) => {
        c && c(a, k), kt(a, k, i);
      };
      i._network.presenceChannel.presence.get(function(a, k) {
        k.forEach((v) => {
          v.clientId !== s && w(v.clientId, !0);
        });
      }), i._network.presenceChannel.presence.subscribe(
        (a) => {
          a.clientId !== s && (a.action === "enter" || a.action === "present" || a.action === "update" ? w(a.clientId, !0) : w(a.clientId, !1));
        }
      );
    }
    r();
  });
}
function G(e, t, c, s, i) {
  try {
    if (!e._network)
      throw Error("socket not initialized");
    if (!(e != null && e.initialized))
      throw Error("Agora internals not initialized");
    const o = {
      etyp: s,
      ets: Date.now(),
      snid: e.sessionId,
      rqid: t,
      data: {
        act: c,
        ...i,
        sid: e.spaceId,
        pid: e.projectId,
        srcId: e.gId
      }
    };
    e._network.queueEvent(o), e.logger.log(
      n.NetworkRealTime,
      "C2S",
      "Queued Event",
      { requestId: t, payload: o }
    );
  } catch (o) {
    throw Error(o);
  }
}
function Ct(e) {
  var t;
  try {
    if (!(e != null && e._network))
      throw Error("socket not initialized");
    (t = e == null ? void 0 : e._network) == null || t.destroy(), e._network = null;
  } catch (c) {
    e.logger.error(
      n.NetworkRealTime,
      "connection",
      "socket: error while disconnecting",
      c
    );
  }
}
function ft(e, t, c, s, i, o) {
  try {
    if (c != null && c.initialized)
      throw new Error("Start method already called");
    if (!e)
      throw new Error("Token not provided");
    const l = P(5);
    const d = P(), r = Oe(d, i, o);
    addEventListener("beforeunload", () => {
      r.log(
        n.Internals,
        "start",
        "onbeforeunload called (agorawrapper)"
      );
    });
    let u = console.log;
    console.log = (...a) => {
      String(a[0]).includes("Agora-SDK") ? r.log(n.AgoraSDK, "Log", a.slice(2).join(" "), {
        timestamp: Date.now()
      }) : u(...a);
    };
    const { log: h, debug: b, error: A } = r;
    h(n.ApiMethod, "start", "start called", {
      requestId: l,
      token: e,
      isUsingCustomLogger: Boolean(i),
      debugLogsEnabled: o,
      BACKEND_URL: ie
    }), c.initialized = !0, c._queue = lt(), c.logger = r, Object.defineProperty(t, "_queue", {
      value: ut({ autoStart: !1 }),
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t, "_playerQueues", {
      value: {},
      enumerable: !1,
      configurable: !0
    }), z(
      async () => {
        var F;
        const { project_id: a, app_id: k, space_id: v, user_id: C } = me(e);
        h(n.Internals, "start", `trace-${C}`, {
          requestId: l,
          space_id: v,
          app_id: k
        }), h(n.Internals, "start", "token decoded", {
          requestId: l,
          project_id: a,
          app_id: k,
          space_id: v,
          user_id: C
        });
        const N = ze(
          e,
          d,
          v,
          a,
          r,
          l
        ).then((V) => (h(n.Internals, "start", "received user details.....", {
          requestId: l,
          userInfo: V
        }), V)), p = Date.now(), S = import("agora-rtc-sdk-ng");
        S.then((V) => {
          const J = Date.now();
          return h(n.Internals, "start", "Imported Agora SDK.....", {
            requestId: l,
            version: V.default.VERSION,
            start: p,
            end: J,
            latency: J - p
          }), V;
        });
        const [m, R] = await Promise.all([
          N,
          S
        ]);
        He(
          m,
          d,
          v,
          k,
          c
        ), h(n.Internals, "start", "created agora internals", {
          requestId: l
        }), c._healthTimer = Ge(t, b), h(n.Internals, "start", "health timer is set up", {
          requestId: l
        });
        const L = (V) => {
          St(V, t, c);
        };
        h(
          n.Internals,
          "start",
          "registered private and presence channel event listeners",
          { requestId: l }
        );
        const j = {
          key: m.pusher ? m.pusher.key : "",
          cluster: m.pusher ? m.pusher.cluster : null,
          private: `private-${m.project_id}.${m.user_id}.${v}`,
          presence: `presence-${m.project_id}.${v}`,
          authToken: e,
          ablyToken: (F = m == null ? void 0 : m.ably) == null ? void 0 : F.token
        };
        h(n.NetworkRealTime, "connection", "connecting to socket", {
          requestId: l,
          ...j
        });
        const Q = R.default;
        c._agoraRTC = Q, c._agoraVersion = Q.VERSION, Q.setParameter("JOIN_WITH_FALLBACK_PROXY_PENDING_DURATION", 4500), b(n.Internals, "start", "agora SDK version", {
          version: Q.VERSION
        }), !o && Q.setLogLevel(4);
        try {
          await Et(
            j,
            L,
            s,
            m.user_id,
            c,
            l
          ), h(
            n.NetworkRealTime,
            "connection",
            "sdk initialized: connected to socket successfully",
            { requestId: l }
          );
        } catch (V) {
          throw A(
            n.NetworkRealTime,
            "connection",
            "sdk initialized: errow while connecting to socket:",
            V
          ), Error(V);
        }
        const x = await f.createLocalClientAndJoin(
          c,
          l,
          !0
        );
        c.clients = x, window.__SuperSDK__ = {
          ...window.__SuperSDK__,
          [c.gId]: {
            __AgConsumerInfoMap__: t,
            __AgAgoraInternals__: c
          }
        }, h(
          n.Internals,
          "start",
          "start intitialized and setup completed sucessfully",
          { requestId: l }
        ), y(c, A), bt(t);
      },
      {
        name: "start",
        requestId: l
      },
      c
    );
  } catch (l) {
    throw new Error("start API failed ".concat(l));
  }
}
function pe(e) {
  return typeof e != "boolean";
}
function Rt(e, t, c, s) {
  const i = pe(c) ? c : s, o = pe(c) ? !1 : c, { log: l, debug: d, error: r } = D(i), u = P(5);
  try {
    if (l(
      n.ApiMethod,
      "produceStart",
      "produceStart called with params",
      {
        requestId: u,
        mediaType: e,
        mediaTrack: t,
        pause: o
      }
    ), !(i != null && i.initialized))
      throw Error("Super sdk not initialized");
    if (d(n.Internals, "produceStart", "SDK is initialized", {
      requestId: u
    }), !e)
      throw Error("param mediaType is missing for method produceStart");
    if (!t)
      throw Error("param mediaTrack is missing produceStart");
    d(n.Internals, "produceStart", "params validated", {
      requestId: u
    }), z(
      async () => {
        try {
          l(
            n.Internals,
            "produceStart",
            "produceStart user channel info",
            {
              requestId: u,
              playerId: i.gId,
              uid: i.uid,
              screenUid: i.screenUid,
              clients: i.clients,
              channelInfo: i.channelInfo,
              mediaType: e,
              mediaTrack: t,
              propsTrackId: t.id
            }
          );
          const b = async (a, k) => {
            var N, p;
            const v = k.id;
            if (i.streams[a]) {
              const S = (N = i.streams[a].getMediaStreamTrack()) == null ? void 0 : N.id;
              if (v === S) {
                l(
                  n.Internals,
                  "produceStart",
                  "track already published..",
                  {
                    requestId: u,
                    propsTrackId: v,
                    internalTrackId: S
                  }
                );
                return;
              }
            }
            const C = await f.createLocalMediaTrack(
              a,
              k,
              i,
              u
            );
            if (l(
              n.Internals,
              "produceStart",
              "created local media tracks",
              {
                requestId: u,
                propsTrackId: v,
                agoraTrack: C,
                internalTrackId: C.getMediaStreamTrack().id
              }
            ), o || (i.streams[a] ? (l(
              n.Internals,
              "produceStart",
              "calling unpublish-publish",
              {
                requestId: u,
                mediaType: a,
                propsTrackId: v,
                internalTrackId: i.streams[a].getMediaStreamTrack().id
              }
            ), await f.unpublishLocalTrack(
              a,
              i,
              u
            ), l(
              n.Internals,
              "produceStart",
              "unpublished tracks successfully",
              {
                requestId: u,
                mediaType: a
              }
            ), await f.publishLocalTrack(
              a,
              C,
              i,
              u
            ), l(
              n.Internals,
              "produceStart",
              "published track successfully",
              { requestId: u, mediaType: a }
            )) : (await f.publishLocalTrack(
              a,
              C,
              i,
              u
            ), l(
              n.Internals,
              "produceStart",
              `published track for ${a} successfully`,
              {
                requestId: u,
                propsTrackId: v,
                internalTrackId: C.getMediaStreamTrack().id
              }
            ))), i.streams[a] = C, a === "video") {
              l(
                n.Internals,
                "setQuality",
                `Setting quality for ${i.targetQuality}`,
                {
                  requestId: u,
                  mediaType: a
                }
              );
              const { height: S, bitrate: m, frameRate: R } = We(
                i.targetQuality
              );
              await ne(
                S,
                m,
                R,
                i,
                u
              );
            }
            if (a == "screen") {
              const S = async (m) => {
                var R, L;
                try {
                  l(
                    n.Internals,
                    "produceStart",
                    "upublishing and closing screen and sound tracks",
                    {
                      requestId: m
                    }
                  ), await ((R = i.clients) == null ? void 0 : R.screen.unpublish(
                    i.streams.screen
                  )), i.streams.screen = null, await ((L = i.clients) == null ? void 0 : L.screen.unpublish(
                    i.streams.sound
                  )), i.streams.sound = null, l(
                    n.Internals,
                    "produceStart",
                    "upublished and closed screen and sound tracks",
                    {
                      requestId: m
                    }
                  ), y(i, r);
                } catch (j) {
                  r(
                    n.Internals,
                    "produceStart",
                    "error occurred on screen-track end",
                    j,
                    {
                      requestId: u
                    }
                  );
                }
              };
              (p = i.streams[a]) == null || p.on("track-ended", () => {
                l(
                  n.Internals,
                  "produceStart",
                  "local screen track ended",
                  { requestId: u }
                ), S(u);
              });
            }
            y(i, r);
          }, A = async () => {
            try {
              const a = await f.createLocalClientAndJoin(
                i,
                u,
                !1
              );
              l(n.Internals, "start", "created local clients", {
                requestId: u
              }), i.clients = a, l(
                n.Internals,
                "start",
                "start intitialized and setup completed sucessfully",
                { requestId: u }
              );
            } catch (a) {
              throw r(
                n.Internals,
                "produceStart",
                "error in client creation and join",
                a,
                { requestId: u }
              ), Error(a);
            }
          };
          let w;
          if (Y(e) && (w = "user"), M(e) && (w = "screen"), i.clients[w] === null && await A(), i.clients[w].connectionState === "CONNECTED")
            await b(e, t);
          else if (i.clients[w].connectionState === "DISCONNECTED") {
            l(
              n.Internals,
              "produceStart",
              "local client is in disconnected state",
              {
                requestId: u,
                connectionState: i.clients[w].connectionState
              }
            ), l(n.Internals, "produceStart", "requesting for token", {
              requestId: u,
              connectionState: i.clients[w].connectionState
            });
            const { rtcToken: a, screenToken: k } = await Be(i);
            l(n.Internals, "produceStart", "rtc token received", {
              requestId: u,
              connectionState: i.clients[w].connectionState,
              rtcToken: a,
              screenToken: k
            }), l(n.Internals, "produceStart", "ready to join channel", {
              requestId: u,
              connectionState: i.clients[w].connectionState,
              rtcToken: a,
              screenToken: k
            }), Y(e) && (l(
              n.Internals,
              "produceStart",
              "(Awaiting) Joining channel for user client(video, audio)",
              {
                requestId: u,
                connectionState: i.clients[w].connectionState,
                rtcToken: a,
                screenToken: k,
                appId: i.appId,
                channelName: i.channelInfo.name,
                agoraUid: i.channelInfo.auid
              }
            ), l(n.AgoraSDK, "API", "join attempt", {
              requestId: u,
              channel: i.channelInfo,
              sessionId: i.sessionId,
              localPlayerId: i.gId,
              localUid: i.uid,
              client: "user",
              event: "produceStart in disconnected state"
            }), Z(i, {
              requestId: u,
              channel: i.channelInfo,
              appId: i.appId,
              sessionId: i.sessionId,
              localPlayerId: i.gId,
              localUid: i.uid,
              client: "user",
              event: "produceStart in disconnected state"
            }), g(
              i.clients.user,
              "user",
              i
            ), await i.clients.user.join(
              i.appId,
              i.channelInfo.name,
              a,
              i.channelInfo.auid
            ), K("user", i), B(i, {
              requestId: u,
              channel: i.channelInfo,
              appId: i.appId,
              sessionId: i.sessionId,
              localPlayerId: i.gId,
              localUid: i.uid,
              client: "user",
              event: "produceStart in disconnected state"
            }), l(
              n.Internals,
              "produceStart",
              "Channel join complete. client(video, audio)",
              {
                requestId: u,
                connectionState: i.clients[w].connectionState,
                rtcToken: a,
                screenToken: k,
                appId: i.appId,
                channelName: i.channelInfo.name,
                agoraUid: i.channelInfo.auid
              }
            )), M(e) && (l(
              n.Internals,
              "produceStart",
              "(Awaiting) Joining channel for user client(screen, sound)",
              {
                requestId: u,
                connectionState: i.clients[w].connectionState,
                rtcToken: a,
                screenToken: k,
                appId: i.appId,
                channelName: i.channelInfo.name,
                agoraUid: i.channelInfo.auid
              }
            ), l(n.AgoraSDK, "API", "join attempt", {
              requestId: u,
              channel: i.channelInfo,
              sessionId: i.sessionId,
              localPlayerId: i.gId,
              localUid: i.uid,
              client: "screen",
              event: "produceStart in disconnected state"
            }), Z(i, {
              requestId: u,
              channel: i.channelInfo,
              appId: i.appId,
              sessionId: i.sessionId,
              localPlayerId: i.gId,
              localUid: i.uid,
              client: "screen",
              event: "produceStart in disconnected state"
            }), g(
              i.clients.screen,
              "screen",
              i
            ), await i.clients.screen.join(
              i.appId,
              i.channelInfo.name,
              k,
              i.channelInfo.screenauid
            ), K("screen", i), B(i, {
              requestId: u,
              channel: i.channelInfo,
              appId: i.appId,
              sessionId: i.sessionId,
              localPlayerId: i.gId,
              localUid: i.uid,
              client: "screen",
              event: "produceStart in disconnected state"
            }), l(
              n.Internals,
              "produceStart",
              "Channel join complete. client(screen, sound)",
              {
                requestId: u,
                connectionState: i.clients[w].connectionState,
                rtcToken: a,
                screenToken: k,
                appId: i.appId,
                channelName: i.channelInfo.name,
                agoraUid: i.channelInfo.auid
              }
            )), l(
              n.Internals,
              "produceStart",
              "Start -> create and publish track",
              {
                requestId: u,
                connectionState: i.clients[w].connectionState,
                rtcToken: a,
                screenToken: k,
                appId: i.appId,
                channelName: i.channelInfo.name,
                agoraUid: i.channelInfo.auid
              }
            ), await b(e, t);
          }
        } catch (b) {
          throw K("user", i), K("screen", i), B(i, {
            requestId: u,
            channel: i == null ? void 0 : i.channelInfo,
            appId: i == null ? void 0 : i.appId,
            sessionId: i == null ? void 0 : i.sessionId,
            localPlayerId: i == null ? void 0 : i.gId,
            localUid: i == null ? void 0 : i.uid
          }), r(
            n.Internals,
            "produceStart",
            "Error: while executing produce start: ",
            b,
            { requestId: u }
          ), b;
        }
      },
      {
        name: "produceStart",
        requestId: u
      },
      i
    );
  } catch (h) {
    throw r(
      n.Internals,
      "produceStart",
      "produceStart-error",
      h,
      {
        requestId: u
      }
    ), new Error("produceStart API failed ".concat(h));
  }
}
function Pt(e, t) {
  const { log: c, debug: s, error: i } = D(t), o = P(5);
  try {
    if (c(
      n.ApiMethod,
      "producePause",
      "producePause called with params",
      {
        requestId: o,
        mediaType: e
      }
    ), !(t != null && t.initialized))
      throw Error("Super sdk not initialized");
    if (s(n.Internals, "producePause", "SDK is initialized", {
      requestId: o
    }), !e)
      throw Error("param mediaType is missing for method producePause ");
    s(n.Internals, "producePause", "params validated", {
      requestId: o
    }), z(
      async () => {
        try {
          if (!(t != null && t.streams[e]))
            throw Error(`Track for ${e} not found. Cannot pause.`);
          Y(e) ? await (t == null ? void 0 : t.clients.user.unpublish(
            t == null ? void 0 : t.streams[e]
          )) : await (t == null ? void 0 : t.clients.screen.unpublish(
            t == null ? void 0 : t.streams[e]
          )), c(
            n.AgoraSDK,
            "API",
            `[${O(e, t)}] unpublish`,
            {
              requestId: o,
              mediaType: e,
              internalTrackId: t.streams[e].getMediaStreamTrack().id
            }
          ), y(t, i), c(
            n.Internals,
            "producePause",
            "unpublished local track to pause track",
            {
              requestId: o,
              mediaType: e,
              internalTrackId: t.streams[e].getMediaStreamTrack().id
            }
          );
        } catch (d) {
          throw d;
        }
      },
      {
        name: "producePause",
        requestId: o
      },
      t
    );
  } catch (l) {
    throw i(n.Internals, "producePause", "producepause-error", l, {
      requestId: o
    }), new Error("producepause API failed ".concat(l));
  }
}
function Nt(e, t) {
  const { log: c, debug: s, error: i } = D(t), o = P(5);
  try {
    if (c(
      n.ApiMethod,
      "produceResume",
      "produceResume called with params ",
      {
        requestId: o,
        mediaType: e
      }
    ), !(t != null && t.initialized))
      throw Error("Super sdk not initialized");
    if (s(n.Internals, "produceResume", "SDK is initialized", {
      requestId: o
    }), !e)
      throw Error("param mediaType is missing for method produceResume ");
    s(n.Internals, "produceResume", "params validated", {
      requestId: o
    }), z(
      async () => {
        try {
          if (!(t != null && t.streams[e]))
            throw Error(`Track for ${e} not found. Cannot resume`);
          Y(e) ? await (t == null ? void 0 : t.clients.user.publish(
            t == null ? void 0 : t.streams[e]
          )) : await (t == null ? void 0 : t.clients.screen.publish(
            t == null ? void 0 : t.streams[e]
          )), c(
            n.AgoraSDK,
            "API",
            `[${O(e, t)}] publish`,
            {
              requestId: o,
              mediaType: e,
              internalTrackId: t.streams[e].getMediaStreamTrack().id
            }
          ), y(t, i), c(
            n.Internals,
            "produceResume",
            "published local track to resume",
            {
              requestId: o,
              mediaType: e,
              internalTrackId: t.streams[e].getMediaStreamTrack().id
            }
          );
        } catch (d) {
          throw i(
            n.AgoraSDK,
            "API",
            `[${O(e, t)}] error in setMute`,
            d,
            { requestId: o }
          ), d;
        }
      },
      {
        name: "produceResume",
        requestId: o
      },
      t
    );
  } catch (l) {
    throw i(
      n.Internals,
      "produceResume",
      "produceResume-error",
      l,
      {
        requestId: o
      }
    ), new Error("produceResume API failed ".concat(l));
  }
}
function Dt(e, t) {
  const { log: c, debug: s, error: i } = D(t), o = P(5);
  try {
    if (c(n.ApiMethod, "produceEnd", "produceEnd called with params", {
      requestId: o,
      mediaType: e
    }), !(t != null && t.initialized))
      throw Error("Super sdk not initialized");
    if (s(n.Internals, "produceEnd", "SDK is initialized", {
      requestId: o
    }), !e)
      throw Error("param mediaType is missing for method produceEnd ");
    s(n.Internals, "produceEnd", "params validated", { requestId: o }), z(
      async () => {
        try {
          if (!t.streams[e])
            throw Error(`Track for ${e} not found`);
          c(
            n.Internals,
            "produceEnd",
            `[${O(
              e,
              t
            )}] unpublish local track`,
            {
              requestId: o,
              mediaType: e,
              internalTrackId: t.streams[e].getMediaStreamTrack().id
            }
          ), await f.unpublishLocalTrack(
            e,
            t,
            o
          ), t.streams[e] = null, t.streams.video === null && t.streams.audio === null && (c(n.Internals, "produceEnd", "leaving user client", {
            requestId: o
          }), await t.clients.user.leave()), t.streams.screen === null && t.streams.sound === null && (c(n.Internals, "produceEnd", "leaving screen client", {
            requestId: o
          }), await t.clients.screen.leave()), c(n.Internals, "produceEnd", "unpublished tracks", {
            requestId: o,
            mediaType: e
          }), y(t, i);
        } catch (d) {
          throw i(n.Internals, "produceEnd", "executeProduceEnd-error", d, {
            requestId: o
          }), d;
        }
      },
      {
        name: "produceEnd",
        requestId: o
      },
      t
    );
  } catch (l) {
    throw i(n.Internals, "produceEnd", "produceEnd-error", l, {
      requestId: o
    }), new Error("produceEnd API failed ".concat(l));
  }
}
function xt(e, t, c) {
  const { log: s, debug: i, error: o } = D(t), l = P(5);
  try {
    if (s(n.ApiMethod, "allow", "allow called with params", {
      requestId: l,
      playerId: e
    }), !(t != null && t.initialized))
      throw Error("Super sdk not initialized");
    if (i(n.Internals, "allow", "SDK is initialized", { requestId: l }), !e)
      throw Error("param playerId is missing for method allow ");
    i(n.Internals, "allow", "params validated", { requestId: l });
    const d = async () => {
      try {
        s(n.Internals, "allow", "inside executeAllow", {
          requestId: l
        }), t._allowed.has(e) ? s(
          n.Internals,
          "allow",
          " player has been allowed already. Ignoring this request",
          {
            requestId: l,
            playerId: e
          }
        ) : (G(t, l, "ALLOW", "CONSENT", {
          dstid: e
        }), t._allowed.add(e), y(t, o), s(n.Internals, "allow", "allow_consent request queued", {
          requestId: l
        }));
      } catch (r) {
        throw r;
      }
    };
    c ? I(
      d,
      {
        name: "allow",
        requestId: l
      },
      c,
      t
    ) : z(
      d,
      {
        name: "allow",
        requestId: l
      },
      t
    );
  } catch (d) {
    throw o(n.Internals, "allow", "allow request failed", d, {
      requestId: l
    }), new Error("allow API failed ".concat(d));
  }
}
function Ut(e, t, c) {
  const { log: s, debug: i, error: o } = D(t), l = P(5);
  try {
    if (s(n.ApiMethod, "disallow", "disallow called with params", {
      requestId: l,
      playerId: e
    }), !(t != null && t.initialized))
      throw Error("Super sdk not initialized");
    if (i(n.Internals, "disallow", "SDK is initialized", { requestId: l }), !e)
      throw Error("param playerId is missing for method disallow ");
    i(n.Internals, "disallow", "params validated", { requestId: l });
    const d = async () => {
      s(n.Internals, "disallow", "inside executeDisallow", {
        requestId: l
      });
      try {
        t._allowed.has(e) ? (G(
          t,
          l,
          "DISALLOW",
          "CONSENT",
          {
            dstid: e
          }
        ), s(
          n.Internals,
          "disallow",
          "disallow_consent request queued",
          {
            requestId: l
          }
        ), t._allowed.delete(e), y(t, o)) : s(
          n.Internals,
          "disallow",
          "player  has been disallowed already. Ignoring this request",
          {
            requestId: l,
            playerId: e
          }
        );
      } catch (r) {
        throw r;
      }
    };
    c ? I(
      d,
      {
        name: "disallow",
        requestId: l
      },
      c,
      t
    ) : z(
      d,
      {
        name: "disallow",
        requestId: l
      },
      t
    );
  } catch (d) {
    throw o(
      n.Internals,
      "disallow",
      "disallow request failed",
      d,
      {
        requestId: l
      }
    ), new Error("disallow: API failed ".concat(d));
  }
}
function Lt(e, t, c, s) {
  const { log: i, debug: o, error: l, warn: d } = D(s), r = P(5);
  try {
    if (i(n.ApiMethod, "subscribe", "subscribe called with params", {
      requestId: r,
      playerId: e,
      mediaType: t
    }), !(s != null && s.initialized))
      throw Error("Super sdk not initialized");
    if (o(n.Internals, "subscribe", "SDK is initialized", {
      requestId: r
    }), !e)
      throw Error("param playerId is missing for method subscribe ");
    if (!t || !t.length)
      throw Error("param mediaType is empty or missing for method subscribe ");
    o(n.Internals, "subscribe", "params validated", {
      requestId: r
    });
    const u = async () => {
      var h;
      if (!c.hasOwnProperty(e))
        i(
          n.Internals,
          "subscribe",
          "new consumer - consumerinfomap does not exit for player",
          {
            requestId: r,
            playerId: e
          }
        ), c[e] = Ce(
          U.WaitingForSFUAddress
        ), $(c[e], s, {
          requestId: r,
          mediaType: t,
          playerId: e,
          sessionId: s.sessionId,
          localPlayerId: s.gId,
          localUid: s.uid
        }), i(
          n.InfoMap,
          "subscribe",
          "created consumerinfomap for player",
          {
            requestId: r,
            playerId: e
          }
        ), be(c[e], t), i(n.InfoMap, "subscribe", "_subscribe added for player", {
          requestId: r,
          playerId: e,
          subscribes: Object.assign({}, c[e]._subscribe)
        }), G(s, r, "SUBSCRIBE", "REQUEST", {
          dstid: e
        }), i(
          n.Internals,
          "subscribe",
          "c2s event subscribe_request to player queued",
          { requestId: r, playerId: e }
        ), y(c, l);
      else {
        i(
          n.Internals,
          "subscribe",
          "existing-consumer consumerinfomap exists for player",
          {
            requestId: r,
            playerId: e
          }
        );
        const b = [
          ...Object.keys(c[e]._subscribe).filter(
            (w) => c[e]._subscribe[w]
          )
        ];
        i(
          n.InfoMap,
          "subscribe",
          "existing _subscribe state for player",
          {
            requestId: r,
            playerId: e,
            subscribes: Object.assign(
              {},
              c[e]._subscribe
            )
          }
        ), be(c[e], t), i(n.InfoMap, "subscribe", "_subscribe updated for player", {
          requestId: r,
          playerId: e,
          subscribes: Object.assign({}, c[e]._subscribe)
        });
        const A = b.filter(
          (w) => !t.includes(w)
        );
        if (i(n.Internals, "subscribe", "subscriptions to be removed", {
          requestId: r,
          subsriptionsToBeRemoved: A
        }), !((h = c[e]._video) != null && h.client)) {
          i(n.Internals, "subscribe", "client not initialized yet", {
            requestId: r
          });
          return;
        }
        if (c[e]._video.client && c[e]._video.client.connectionState === "CONNECTED") {
          if (i(
            n.Internals,
            "subscribe",
            "client is intitialized for player and is connected",
            {
              requestId: r,
              playerId: e,
              connectionStatus: c[e]._video.client.connectionState
            }
          ), c[e].state === U.ProducerDisconnected) {
            i(
              n.Internals,
              "subscribe",
              "producer is in disconnected state i.e not publishing anything into the channel",
              {
                requestId: r,
                playerId: e,
                producerState: c[e].state,
                usersInChannel: c[e]._video.client.remoteUsers
              }
            );
            return;
          }
          const w = async () => {
            await Promise.all(
              A.map(async (v) => {
                await f.unsubscribeRemoteMedia(
                  v,
                  s.logger,
                  e,
                  c[e],
                  r
                );
              })
            ).catch((v) => {
              l(
                n.Internals,
                "subscribe",
                "Error occured while unsubscrbing from remote media",
                v,
                {
                  requestId: r
                }
              );
            }), y(c, l), i(
              n.Internals,
              "subscribe",
              A.length > 0 ? " Removed unnecessary subscriptions" : "No unnecessary subscriptions are present",
              {
                requestId: r
              }
            );
          }, a = async () => {
            await Promise.all(
              t.map(async (v) => {
                i(n.Internals, "subscribe", "adding subscription", {
                  requestId: r,
                  playerId: e,
                  mediaKind: v
                }), await f.subscribeRemoteMedia(
                  v,
                  s.logger,
                  e,
                  c[e],
                  r
                );
              })
            ).catch((v) => {
              l(
                n.Internals,
                "subscribe",
                "Error occured while subscribing to remote media",
                v,
                { requestId: r }
              );
            }), y(c, l);
          }, k = () => {
            Object.keys(c[e].enabled).find(
              (v) => c[e].enabled[v]
            ) || (i(
              n.Internals,
              "subscribe",
              "player is not producing any media kinds",
              {
                requestId: r,
                playerId: e
              }
            ), c[e].state = U.Connected, y(c, l));
          };
          try {
            await w(), await a(), k();
          } catch (v) {
            throw v;
          }
        }
      }
    };
    i(n.Internals, "subscribe", "Adding API to Queue", {
      requestId: r
    }), c._playerQueues.hasOwnProperty(e) || (i(n.Internals, "subscribe", "creating player queue", {
      requestId: r,
      playerId: e
    }), c._playerQueues[e] = at({
      autoStart: !c._queue.isPaused
    })), q(
      u,
      {
        name: "subscribe",
        requestId: r,
        playerId: e
      },
      c,
      s
    );
  } catch (u) {
    throw l(n.Internals, "subscribe", "subscribe-error", u, {
      requestId: r
    }), new Error("subscibe API failed ".concat(u));
  }
}
function jt(e, t, c) {
  const { log: s, debug: i, error: o } = D(c), l = P(5);
  try {
    if (s(n.ApiMethod, "unsubscribe", "unsubscribe called with params", {
      requestId: l,
      playerId: e
    }), !(c != null && c.initialized))
      throw Error("Super sdk not initialized");
    if (i(n.Internals, "unsubscribe", "SDK is initialized", {
      requestId: l
    }), !e)
      throw Error("Player Id not provided");
    i(n.Internals, "unsubscribe", "params validated", {
      requestId: l
    }), q(
      async () => {
        var r, u;
        try {
          if (!(t != null && t[e]))
            throw Error("Consumer info for playerId, does not exist");
          (u = (r = t == null ? void 0 : t[e]) == null ? void 0 : r._video) != null && u.client ? (s(n.Internals, "unsubscribe", "video client exist", {
            requestId: l
          }), await f.leaveRemoteClient(
            e,
            t[e],
            c,
            l
          ), s(
            n.Internals,
            "unsubscribe",
            "left remote rtc channel and cleaned tracks successfully",
            {
              requestId: l
            }
          ), De(t[e], c), delete t[e], s(
            n.InfoMap,
            "unsubscribe",
            "deleted from _consumerInfo successfully",
            {
              requestId: l,
              playerId: e
            }
          )) : t[e] && (s(
            n.Internals,
            "unsubscribe",
            "video client does not exsit but player id exist in consumerInfo",
            { requestId: l }
          ), re(t[e]), delete t[e], s(
            n.InfoMap,
            "unsubscribe",
            "deleted from _consumerInfo successfully",
            {
              requestId: l,
              playerId: e
            }
          )), G(
            c,
            l,
            "UNSUBSCRIBE",
            "REQUEST",
            {
              dstid: e
            }
          ), s(
            n.Internals,
            "unsubscribe",
            "c2s event unsubsribe_request to player queued",
            {
              requestId: l,
              playerId: e
            }
          ), y(t, o);
        } catch (h) {
          throw h;
        }
      },
      {
        name: "unsubscribe",
        requestId: l,
        playerId: e
      },
      t,
      c
    );
  } catch (d) {
    throw o(
      n.Internals,
      "unsubscribe",
      "unsubscribe API failed",
      d,
      {
        requestId: l
      }
    ), new Error("unsubscribe API failed ".concat(d));
  }
}
function Qt(e, t, c, s, i) {
  const { log: o, debug: l, error: d } = D(i), r = P(5);
  try {
    if (o(n.ApiMethod, "setMute", "setMute called with params", {
      requestId: r,
      playerId: e,
      mediaType: t,
      mute: c
    }), !(i != null && i.initialized))
      throw Error("Super sdk not initialized");
    if (l(n.Internals, "setMute", "SDK is initialized", { requestId: r }), !e)
      throw Error("param playerId is missing for method allow");
    if (!t)
      throw Error("param mediaType is missing for method setMute ");
    if (typeof c != "boolean")
      throw Error("param mute is not of type boolean");
    l(n.Internals, "setMute", "params validated", { requestId: r }), q(
      async () => {
        if (c)
          try {
            await f.unsubscribeRemoteMedia(
              t,
              i.logger,
              e,
              s[e],
              r
            ), s[e].muted[t] = !0, y(s, d);
          } catch (h) {
            throw d(
              n.Internals,
              "setMute",
              "error occured while unsubscribing  player during setMute(true)",
              h,
              { requestId: r, playerId: e }
            ), h;
          }
        else
          try {
            await f.subscribeRemoteMedia(
              t,
              i.logger,
              e,
              s[e],
              r
            ) && (s[e].muted[t] = !1, y(s, d));
          } catch (h) {
            throw d(
              n.Internals,
              "setMute",
              "error occured while subscribing to player during setMute(false)",
              h,
              { requestId: r, playerId: e }
            ), h;
          }
      },
      {
        name: "setMute",
        requestId: r,
        playerId: e
      },
      s,
      i
    );
  } catch (u) {
    throw d(n.Internals, "setMute", "setmute-errored", u, {
      requestId: r
    }), new Error("setmute API failed ".concat(u));
  }
}
function Vt(e, t) {
  const { log: c, debug: s, error: i } = D(t), o = P(5);
  try {
    if (c(n.ApiMethod, "muteAllAudio", "muteAllAudio called", {
      requestId: o
    }), !(t != null && t.initialized))
      throw Error("Super sdk not initialized");
    s(n.Internals, "muteAllAudio", "SDK is initialized", {
      requestId: o
    }), I(
      async () => {
        await Promise.all(
          Object.keys(e).map(async (d) => {
            if (e[d].enabled.audio)
              try {
                await f.unsubscribeRemoteMedia(
                  "audio",
                  t.logger,
                  d,
                  e[d],
                  o
                ), e[d].muted.audio = !0;
              } catch (r) {
                i(
                  n.Internals,
                  "muteAllAudio",
                  "error occured while unsubscribing from audio for player during muteAllAudio",
                  r,
                  { requestId: o, playerId: d }
                );
              }
            if (e[d].enabled.sound)
              try {
                await f.unsubscribeRemoteMedia(
                  "sound",
                  t.logger,
                  d,
                  e[d],
                  o
                ), e[d].muted.sound = !0;
              } catch (r) {
                i(
                  n.Internals,
                  "muteAllAudio",
                  "error occured while unsubscribing from sound for player during muteAllAudio",
                  r,
                  { requestId: o, playerId: d }
                );
              }
          })
        ).catch((d) => {
          throw d;
        }), y(e, i);
      },
      {
        name: "muteAllAudio",
        requestId: o
      },
      e,
      t
    );
  } catch (l) {
    throw i(
      n.Internals,
      "muteAllAudio",
      "muteAllAudio API failed",
      l,
      {
        requestId: o
      }
    ), new Error("muteAllAudio API failed ".concat(l));
  }
}
function Ot(e, t) {
  const { log: c, debug: s, error: i } = D(t), o = P(5);
  try {
    if (c(n.ApiMethod, "unmuteAllAudio", "unmuteAllAudio called", {
      requestId: o
    }), !(t != null && t.initialized))
      throw Error("Super sdk not initialized");
    s(n.Internals, "unmuteAllAudio", "SDK is initialized", {
      requestId: o
    }), I(
      async () => {
        await Promise.all(
          Object.keys(e).map(async (d) => {
            if (!e[d].enabled.audio)
              try {
                await f.subscribeRemoteMedia(
                  "audio",
                  t.logger,
                  d,
                  e[d],
                  o
                ) && (e[d].muted.audio = !1);
              } catch (r) {
                i(
                  n.Internals,
                  "unmuteAllAudio",
                  "error occured while subscribing to audio for player during unmuteAllAudio",
                  r,
                  { requestId: o, playerId: d }
                );
              }
            if (!e[d].enabled.sound)
              try {
                await f.subscribeRemoteMedia(
                  "sound",
                  t.logger,
                  d,
                  e[d],
                  o
                ) && (e[d].muted.sound = !1);
              } catch (r) {
                i(
                  n.Internals,
                  "unmuteAllAudio",
                  "error occured while subscribing to sound for player during unmuteAllAudio",
                  r,
                  { requestId: o, playerId: d }
                );
              }
          })
        ).catch((d) => {
          throw d;
        }), y(e, i);
      },
      {
        name: "unmuteAllAudio",
        requestId: o
      },
      e,
      t
    );
  } catch (l) {
    throw i(
      n.Internals,
      "unmuteAllAudio",
      "unmuteAllAudio API failed",
      l,
      {
        requestId: o
      }
    ), new Error("unmuteAllAudio API failed ".concat(l));
  }
}
function zt(e, t, c) {
  const { log: s, debug: i, error: o } = D(t), l = P(5);
  try {
    if (s(n.ApiMethod, "muteAllVideo", "muteAllVideo called", {
      requestId: l
    }), !(t != null && t.initialized))
      throw Error("Super sdk not initialized");
    i(n.Internals, "muteAllVideo", "SDK is initialized", {
      requestId: l
    }), I(
      async () => {
        await Promise.all(
          Object.keys(e).map(async (r) => {
            if (c && c === r)
              return !1;
            if (e[r].enabled.video)
              try {
                await f.unsubscribeRemoteMedia(
                  "video",
                  t.logger,
                  r,
                  e[r],
                  l
                ), e[r].muted.video = !0;
              } catch (u) {
                o(
                  n.Internals,
                  "muteAllVideo",
                  "error occured while unsubscribing from video for player during muteAllVideo",
                  u,
                  { requestId: l, playerId: r }
                );
              }
            if (e[r].enabled.screen)
              try {
                await f.unsubscribeRemoteMedia(
                  "screen",
                  t.logger,
                  r,
                  e[r],
                  l
                ), e[r].muted.screen = !0;
              } catch (u) {
                o(
                  n.Internals,
                  "muteAllVideo",
                  "error occured while unsubscribing from screen for player during muteAllVideo",
                  u,
                  { requestId: l, playerId: r }
                );
              }
          })
        ).catch((r) => {
          throw r;
        }), y(e, o);
      },
      {
        name: "muteAllVideo",
        requestId: l
      },
      e,
      t
    );
  } catch (d) {
    throw o(
      n.Internals,
      "muteAllVideo",
      "muteAllVideo-error",
      d,
      {
        requestId: l
      }
    ), new Error("muteAllVideo API failed ".concat(d));
  }
}
function $t(e, t) {
  const { log: c, debug: s, error: i } = D(t), o = P(5);
  try {
    if (c(n.ApiMethod, "unmuteAllVideo", "unmuteAllVideo called", {
      requestId: o
    }), !(t != null && t.initialized))
      throw Error("Super sdk not initialized");
    s(n.Internals, "unmuteAllVideo", "SDK is initialized", {
      requestId: o
    }), I(
      async () => {
        await Promise.all(
          Object.keys(e).map(async (d) => {
            if (!e[d].enabled.video)
              try {
                await f.subscribeRemoteMedia(
                  "video",
                  t.logger,
                  d,
                  e[d],
                  o
                ) && (e[d].muted.video = !1);
              } catch (r) {
                i(
                  n.Internals,
                  "unmuteAllVideo",
                  "error occured while subscribing to video for player during unmuteAllAudio",
                  r,
                  { requestId: o, playerId: d }
                );
              }
            if (!e[d].enabled.screen)
              try {
                await f.subscribeRemoteMedia(
                  "screen",
                  t.logger,
                  d,
                  e[d],
                  o
                ) && (e[d].muted.screen = !1);
              } catch (r) {
                i(
                  n.Internals,
                  "unmuteAllVideo",
                  "error occured while subscribing to screen for player during unmuteAllVideo",
                  r,
                  { requestId: o, playerId: d }
                );
              }
          })
        ).catch((d) => {
          throw d;
        }), y(e, i);
      },
      {
        name: "unmuteAllVideo",
        requestId: o
      },
      e,
      t
    );
  } catch (l) {
    throw i(
      n.Internals,
      "unmuteAllVideo",
      "unmuteAllVideo error",
      l,
      {
        requestId: o
      }
    ), new Error("unmuteAllVideo API failed ".concat(l));
  }
}
function Kt(e, t, c, s) {
  const { log: i, debug: o, error: l } = D(s), d = P(5);
  try {
    if (i(
      n.ApiMethod,
      "setScreenVolume",
      "setScreenVolume called with params",
      {
        requestId: d,
        playerId: e,
        volume: t
      }
    ), !(s != null && s.initialized))
      throw Error("Super sdk not initialized");
    if (o(n.Internals, "setScreenVolume", "SDK is initialized", {
      requestId: d
    }), !e)
      throw Error("Player Id not provided");
    if (typeof t != "number" || t < 0 || t > 1)
      throw Error(
        `Volume can only be a number accepted within range 0 - 1 - received ${t}`
      );
    i(n.Internals, "setScreenVolume", "params validated", {
      requestId: d
    }), q(
      async () => {
        var u, h;
        try {
          if (c[e] && ((u = c[e]._screenVideo.streams) == null ? void 0 : u.sound) && c[e].enabled.sound && !c[e].muted.sound) {
            i(
              n.Internals,
              "setScreenVolume",
              "entry is found in infomap",
              {
                requestId: d
              }
            );
            const b = Ee(t);
            (h = c[e]._screenVideo.streams.sound) == null || h.setVolume(
              b
            ), i(
              n.AgoraSDK,
              "API",
              `[${O(
                "sound",
                s
              )}] setVolume method called`,
              {
                requestId: d,
                agoraVolRange: b
              }
            ), c[e].screenVolume = t, y(c, l), i(n.InfoMap, "setScreenVolume", "screenVolume updated", {
              requestId: d,
              playerId: e,
              volume: t
            });
          } else
            i(
              n.Internals,
              "setScreenVolume",
              "entry is NOT found in infomap",
              {
                requestId: d,
                playerId: e
              }
            );
        } catch (b) {
          throw b;
        }
      },
      {
        name: "setScreenVolume",
        requestId: d,
        playerId: e
      },
      c,
      s
    );
  } catch (r) {
    throw l(
      n.Internals,
      "setScreenVolume",
      "setScreenVolume-error",
      r,
      {
        requestId: d
      }
    ), new Error("setScreenVolume API failed ".concat(r));
  }
}
function qt(e, t, c, s) {
  const { log: i, debug: o, error: l } = D(s), d = P(5);
  try {
    if (i(
      n.ApiMethod,
      "setVolume",
      "setScreenVolume called with params",
      {
        requestId: d,
        playerId: e,
        volume: t
      }
    ), !(s != null && s.initialized))
      throw Error("Super sdk not initialized");
    if (o(n.Internals, "setVolume", "SDK is initialized", {
      requestId: d
    }), !e)
      throw Error("Player Id not provided");
    if (typeof t != "number" || t < 0 || t > 1)
      throw Error(
        `Volume can only be a number and accepted within range 0 - 1 - received ${t}`
      );
    o(n.Internals, "setVolume", "params validated", { requestId: d }), q(
      async () => {
        var u, h;
        try {
          if (c[e] && ((u = c[e]._video.streams) == null ? void 0 : u.audio) && c[e].enabled.audio && !c[e].muted.audio) {
            i(n.Internals, "setVolume", "entry is found in infomap", {
              requestId: d
            });
            const b = Ee(t);
            (h = c[e]._video.streams.audio) == null || h.setVolume(
              b
            ), i(
              n.AgoraSDK,
              "API",
              `[${O(
                "audio",
                s
              )}] setVolume method called setVolume method called`,
              {
                requestId: d,
                agoraVolRange: b
              }
            ), c[e].volume = t, y(c, l), i(n.InfoMap, "setVolume", `[${e}].volume updated`, {
              requestId: d,
              playerId: e,
              volume: t
            });
          } else
            i(
              n.Internals,
              "setVolume",
              "entry is NOT found in infomap",
              {
                requestId: d
              }
            );
        } catch (b) {
          throw b;
        }
      },
      {
        name: "setVolume",
        requestId: d,
        playerId: e
      },
      c,
      s
    );
  } catch (r) {
    throw l(n.Internals, "setVolume", "setVolume-error", r, {
      requestId: d
    }), new Error("setVolume API failed ".concat(r));
  }
}
function Ft(e, t, c, s, i) {
  const { log: o, debug: l, error: d } = D(i), r = P(5);
  try {
    if (o(
      n.ApiMethod,
      "setMaxConsumerSpatialLayer",
      "setMaxConsumerSpatialLayer called with params",
      { requestId: r, playerId: e, mediaType: t, videoSpatialLayer: c }
    ), !(i != null && i.initialized))
      throw Error("Super sdk not initialized");
    if (l(
      n.Internals,
      "setMaxConsumerSpatialLayer",
      "SDK is initialized",
      {
        requestId: r
      }
    ), !e)
      throw Error(
        "param playerId is missing for method setMaxConsumerSpatialLayer "
      );
    if (!t)
      throw Error(
        "param mediaType is missing for method setMaxConsumerSpatialLayer "
      );
    if (t !== "video")
      throw Error("Default spatial layer can only be set for video");
    if (c && !Object.values(te).includes(c))
      throw Error("Pass a valid spatial layer value ");
    l(
      n.Internals,
      "setMaxConsumerSpatialLayer",
      "params validated",
      {
        requestId: r
      }
    );
    const u = async () => {
      var b;
      if (!s[e])
        throw Error("Consumer not found in infomap");
      const h = Xe(c);
      if (!h) {
        o(
          n.Internals,
          "setMaxConsumerSpatialLayer",
          "Trying to request UNSET quality...Cannot send..aborting..",
          {
            requestId: r,
            gatherQuality: c,
            agoraQuality: h
          }
        );
        return;
      }
      if (s[e].streamQuality === h) {
        l(
          n.Internals,
          "setMaxConsumerSpatialLayer",
          "requested stream quality is same as current stream quality",
          {
            requestId: r
          }
        );
        return;
      }
      if (o(
        n.Internals,
        "setMaxConsumerSpatialLayer",
        `Computed quality is ${h}`,
        {
          requestId: r
        }
      ), G(i, r, "QUALITY", "VIDEO", {
        dstid: e,
        quality: h
      }), o(
        n.Internals,
        "setMaxConsumerSpatialLayer",
        "c2s event quality_video_request to player with quality queued",
        {
          requestId: r,
          playerId: e,
          quality: h
        }
      ), s[e].streamQuality = h, y(s, d), !((b = s[e]._video) != null && b._remoteUid)) {
        o(
          n.Internals,
          "setMaxConsumerSpatialLayer",
          "cannot switch stream layer as remote uid is still not available",
          {
            requestId: r
          }
        );
        return;
      }
      h === "LOW" ? (o(
        n.Internals,
        "setMaxConsumerSpatialLayer",
        "switch to lowStream(1)",
        {
          requestId: r
        }
      ), await f.switchRemoteVideoStreamType(
        e,
        s[e],
        1,
        i,
        r
      )) : (o(
        n.Internals,
        "setMaxConsumerSpatialLayer",
        "switch to highStream(0)",
        {
          requestId: r
        }
      ), await f.switchRemoteVideoStreamType(
        e,
        s[e],
        0,
        i,
        r
      ));
    };
    o(
      n.Internals,
      "setMaxConsumerSpatialLayer",
      "Adding API to Queue",
      {
        requestId: r
      }
    ), q(
      u,
      {
        name: "setMaxConsumerSpatialLayer",
        requestId: r,
        playerId: e
      },
      s,
      i
    );
  } catch (u) {
    throw d(
      n.Internals,
      "setMaxConsumerSpatialLayer",
      "setMaxConsumerSpatialLayer-error",
      u,
      {
        requestId: r
      }
    ), new Error("setMaxConsumerSpatialLayer API failed ".concat(u));
  }
}
function Jt(e, t) {
  const { log: c, debug: s, error: i } = D(t), o = P(5);
  try {
    if (c(
      n.ApiMethod,
      "addSFUReloadListener",
      "addSFUReloadListener called with params - a function",
      {
        requestId: o
      }
    ), !(t != null && t.initialized))
      throw Error("Super sdk not initialized");
    if (s(n.Internals, "addSFUReloadListener", "SDK is initialized", {
      requestId: o
    }), !e)
      throw Error("param _listener is missing for method addSFUReloadListener");
    if (typeof e != "function")
      throw Error("param _listener is not of type function");
    s(n.Internals, "addSFUReloadListener", "params validated", {
      requestId: o
    }), z(
      async () => {
        var d;
        try {
          if (!((d = t == null ? void 0 : t.clients) != null && d.user))
            throw Error("Client not initialized");
          t.clients.user.on(
            "connection-state-change",
            (r) => {
              c(
                n.AgoraSDK,
                "Event",
                `[${O(
                  "video",
                  t
                )}] connection-state-change triggered inside addSFUReloadListener`,
                { requestId: o, connectionState: r }
              ), e(r === "CONNECTING" || r === "RECONNECTING");
            }
          ), c(
            n.AgoraSDK,
            "API",
            `[${O(
              "video",
              t
            )}] Added connection-state-change event handler inside addSFUReloadListener`,
            {
              requestId: o
            }
          );
        } catch (r) {
          throw i(
            n.Internals,
            "addSFUReloadListener",
            "error adding listener",
            r,
            {
              requestId: o
            }
          ), r;
        }
      },
      {
        name: "addSFUReloadListener",
        requestId: o
      },
      t
    );
  } catch (l) {
    throw i(
      n.Internals,
      "addSFUReloadListener",
      "addSFUReloadListener API failed",
      l,
      {
        requestId: o
      }
    ), new Error("addSFUReloadListener API failed ".concat(l));
  }
}
function Ht(e, t) {
  const { log: c, debug: s, error: i } = D(t), o = P(5);
  try {
    if (c(
      n.ApiMethod,
      "clearSFUReloadListener",
      "clearSFUReloadListener called with params - a function",
      {
        requestId: o
      }
    ), !(t != null && t.initialized))
      throw Error("Super sdk not initialized");
    if (s(n.Internals, "clearSFUReloadListener", "SDK is initialized", {
      requestId: o
    }), !e)
      throw Error("param _listener is missing for method addSFUReloadListener");
    if (typeof e != "function")
      throw Error("param _listener is not of type function");
    s(n.Internals, "clearSFUReloadListener", "params validated", {
      requestId: o
    }), z(
      async () => {
        var d;
        try {
          if (!((d = t == null ? void 0 : t.clients) != null && d.user))
            throw Error("Client not initialized");
          t.clients.user.off("connection-state-change", e), c(
            n.AgoraSDK,
            "API",
            `[${O(
              "video",
              t
            )}] Removed connection-state-change event handler inside clearSFUReloadListener`,
            {
              requestId: o
            }
          );
        } catch (r) {
          throw r;
        }
      },
      {
        name: "clearSFUReloadListener",
        requestId: o
      },
      t
    );
  } catch (l) {
    throw i(
      n.Internals,
      "clearSFUReloadListener",
      "clearSFUReloadListener-error",
      l,
      {
        requestId: o
      }
    ), new Error("clearSFUReloadListener API failed ".concat(l));
  }
}
function Wt(e, t) {
  const { log: c, debug: s, error: i } = D(t), o = P(5);
  try {
    if (c(n.ApiMethod, "destroy", "destroy called", { requestId: o }), !(t != null && t.initialized))
      throw Error("Super sdk not initialized");
    s(n.Internals, "destroy", "SDK is initialized", { requestId: o }), z(
      async () => {
        try {
          await f.destroy(e, t, o), c(n.Internals, "destroy", "cleaned up RTC", { requestId: o }), Ct(t), c(n.Internals, "destroy", "cleaned up socket", { requestId: o }), e == null || e._queue.clear(), t == null || t._queue.clear(), c(n.InfoMap, "destroy", "cleaned up internal queues", {
            requestId: o
          }), fe(t), c(n.InfoMap, "destroy", "cleaned up consumer queues", {
            requestId: o
          }), wt(e), c(n.Internals, "destroy", "resetAgoraInternals", {
            requestId: o
          }), y(e, i), y(t, i);
        } catch (d) {
          i(n.Internals, "destroy", "executeDestroy-error: ", d, {
            requestId: o
          });
        }
      },
      {
        name: "destroy",
        requestId: o
      },
      t
    );
  } catch (l) {
    throw i(n.Internals, "destroy", "destroy-error", l, {
      requestId: o
    }), new Error("destroy API failed ".concat(l));
  }
}
function ve() {
  const e = [];
  return function(t, c) {
    if (typeof c != "object" || c === null)
      return c;
    for (; e.length > 0 && e[e.length - 1] !== this; )
      e.pop();
    return e.includes(c) ? "[Circular]" : (e.push(c), c);
  };
}
const Bt = (e, t) => ({
  consumerInfoMap: JSON.parse(
    JSON.stringify(e, ve())
  ),
  agoraInternals: JSON.parse(
    JSON.stringify(t, ve())
  )
}), Gt = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  start: ft,
  produceStart: Rt,
  producePause: Pt,
  produceResume: Nt,
  produceEnd: Dt,
  allow: xt,
  disallow: Ut,
  subscribe: Lt,
  unsubscribe: jt,
  setMute: Qt,
  muteAllAudio: Vt,
  unmuteAllAudio: Ot,
  muteAllVideo: zt,
  unmuteAllVideo: $t,
  setScreenVolume: Kt,
  setVolume: qt,
  setMaxConsumerSpatialLayer: Ft,
  addSFUReloadListener: Jt,
  clearSFUReloadListener: Ht,
  destroy: Wt,
  createReport: Bt
}, Symbol.toStringTag, { value: "Module" }));
window.SuperSDK = Gt;
export {
  U as ConsumerState,
  te as Consumer_VideoSpatialLayer,
  Gt as default
};
