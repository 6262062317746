import React from "react";
import {
  type ConsumerInfo,
  Consumer_VideoSpatialLayer,
} from "app-builder-super-sdk";

const qualityOptions = [
  {
    id: "na",
    value: -1,
    label: "NA",
  },
  {
    id: "low",
    value: Consumer_VideoSpatialLayer.LowQuality,
    label: "LOW",
  },
  {
    id: "mid",
    value: Consumer_VideoSpatialLayer.MidQuality,
    label: "MID",
  },
  {
    id: "high",
    value: Consumer_VideoSpatialLayer.HighQuality,
    label: "HIGH",
  },
];

export default function ConsumerRequestQuality({
  consumerInfo,
  handleQualityChange,
}: {
  consumerInfo: ConsumerInfo;
  handleQualityChange: (value: string) => void;
}) {
  return (
    <div>
      <div>
        <button onClick={() => handleQualityChange("0")}>LOW</button>
        <button onClick={() => handleQualityChange("1")}>MID</button>
        <button onClick={() => handleQualityChange("2")}>HIGH</button>
      </div>
      <span>Request quality &nbsp;</span>
      <select
        disabled={!consumerInfo?.enabled["video"]}
        onChange={(event) => handleQualityChange(event.target.value)}
      >
        {qualityOptions.map((option) => {
          return (
            <option key={option.id} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}
